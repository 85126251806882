import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { LuSearch } from "react-icons/lu";
import { FaLightbulb, FaClock, FaCheckCircle } from "react-icons/fa";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import "./styles.css";

const categories = [
  "Technical Interviews",
  "Behavioral Questions",
  "Coding Tests",
  "System Design",
  "HR Round",
  "Salary Negotiation",
  "Remote Interviews",
  "Interview Prep",
];

const interviewTips = [
  {
    title: "Technical Interview Success Guide",
    category: "Technical Interviews",
    timeToRead: "10 min read",
    image:
      "https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixlib=rb-4.0.3",
    tips: [
      "Review the company's tech stack thoroughly",
      "Practice coding on a whiteboard",
      "Explain your thought process clearly",
      "Prepare for system design questions",
      "Know your resume projects in detail",
    ],
  },
  {
    title: "Mastering Behavioral Questions",
    category: "Behavioral Questions",
    timeToRead: "8 min read",
    image:
      "https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3",
    tips: [
      "Use the STAR method for responses",
      "Prepare specific examples",
      "Focus on positive outcomes",
      "Show leadership and teamwork",
      "Highlight problem-solving skills",
    ],
  },
  {
    title: "Ace Your Coding Interview",
    category: "Coding Tests",
    timeToRead: "12 min read",
    image:
      "https://images.unsplash.com/photo-1555099962-4199c345e5dd?ixlib=rb-4.0.3",
    tips: [
      "Practice algorithmic problems daily",
      "Focus on time complexity",
      "Test edge cases",
      "Write clean, readable code",
      "Explain your approach before coding",
    ],
  },
  {
    title: "System Design Interview Guide",
    category: "System Design",
    timeToRead: "15 min read",
    image:
      "https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3",
    tips: [
      "Start with requirements gathering",
      "Consider scalability from start",
      "Discuss trade-offs clearly",
      "Know about load balancing",
      "Understand CAP theorem",
    ],
  },
  {
    title: "HR Interview Strategies",
    category: "HR Round",
    timeToRead: "7 min read",
    image:
      "https://images.unsplash.com/photo-1560250097-0b93528c311a?ixlib=rb-4.0.3",
    tips: [
      "Research company culture",
      "Prepare salary expectations",
      "Ask meaningful questions",
      "Show enthusiasm and interest",
      "Follow up professionally",
    ],
  },
  {
    title: "Salary Negotiation Tips",
    category: "Salary Negotiation",
    timeToRead: "9 min read",
    image:
      "https://images.unsplash.com/photo-1434626881859-194d67b2b86f?ixlib=rb-4.0.3",
    tips: [
      "Research market rates",
      "Consider total compensation",
      "Wait for the right moment",
      "Be confident but professional",
      "Get offers in writing",
    ],
  },
];

const InterviewTipsPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");

  const filteredTips = interviewTips.filter((tip) => {
    const matchesSearch =
      tip.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      tip.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
      tip.tips.some((t) => t.toLowerCase().includes(searchTerm.toLowerCase()));

    const matchesCategory =
      selectedCategory === "All" || tip.category === selectedCategory;

    return matchesSearch && matchesCategory;
  });

  return (
    <div className="interview-tips-page">
      <Helmet>
        <title>Interview Tips | AppointTalent - Ace Your Next Interview</title>
        <meta name="description" content="Discover essential interview tips and strategies on AppointTalent. Prepare effectively and ace your next interview." />
        <meta name="keywords" content="interview tips, job interview, preparation, appointtalent, career advice" />
      </Helmet>
      <div className="interview-hero">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} className="text-center">
              <h1>Interview Tips & Preparation</h1>
              <p>Expert guidance to help you succeed in your job interviews</p>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="mt-5">
        {/* <Row>
          <Col md={12} className="mb-4">
            <div className="popular-searches">
              <button
                className={`search-tag ${selectedCategory === "All" ? "active" : ""}`}
                onClick={() => setSelectedCategory("All")}
              >
                All Categories
              </button>
              {categories.map((category, index) => (
                <button
                  key={index}
                  className={`search-tag ${selectedCategory === category ? "active" : ""}`}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category}
                </button>
              ))}
            </div>
          </Col>
        </Row> */}
        {/* 
        <Row>
          {filteredTips.map((tip, index) => (
            <Col md={6} lg={4} key={index} className="mb-4">
              <Card className="tip-card">
                <div className="tip-image">
                  <img src={tip.image} alt={tip.title} />
                  <span className="category-badge">{tip.category}</span>
                </div>
                <Card.Body>
                  <div className="time-to-read">
                    <FaClock /> {tip.timeToRead}
                  </div>
                  <h5 className="tip-title">{tip.title}</h5>
                  <div className="tips-list">
                    {tip.tips.map((item, idx) => (
                      <div key={idx} className="tip-item">
                        <FaCheckCircle className="check-icon" />
                        <span>{item}</span>
                      </div>
                    ))}
                  </div>
                  <button className="read-more-btn">View Detailed Guide</button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row> */}

        <Row className="mt-4">
          <Col md={12}>
            <div className="quick-tips">
              <h3>Quick Interview Tips</h3>
              <Row>
                <Col md={4} className="mb-4">
                  <div className="quick-tip-card">
                    <FaLightbulb className="tip-icon" />
                    <h5>Before the Interview</h5>
                    <ul>
                      <li>Research the company thoroughly</li>
                      <li>Practice common questions</li>
                      <li>Prepare relevant examples</li>
                      <li>Plan your outfit in advance</li>
                    </ul>
                  </div>
                </Col>
                <Col md={4} className="mb-4">
                  <div className="quick-tip-card">
                    <FaLightbulb className="tip-icon" />
                    <h5>During the Interview</h5>
                    <ul>
                      <li>Maintain good eye contact</li>
                      <li>Listen carefully to questions</li>
                      <li>Show enthusiasm and interest</li>
                      <li>Ask thoughtful questions</li>
                    </ul>
                  </div>
                </Col>
                <Col md={4} className="mb-4">
                  <div className="quick-tip-card">
                    <FaLightbulb className="tip-icon" />
                    <h5>After the Interview</h5>
                    <ul>
                      <li>Send a thank-you note</li>
                      <li>Follow up appropriately</li>
                      <li>Reflect on your performance</li>
                      <li>Keep your options open</li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default InterviewTipsPage;

import React from "react";
import Spinner from "react-bootstrap/Spinner";
import loading from "./loading.svg";

const Loading = () => {
  return (
    <div className="text-center" style={{ marginTop: 140 }}>
      <Spinner animation="border" size="xl" style={{position:"relative"}} />
      {/* <img
        className="my-3"
        src={loading}
        alt="loading"
        style={{ width: "80px" }}
      /> */}
    </div>
  );
};

export default Loading;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import JobItem from "../../components/JobItem";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../styles/home.css";
import { addData } from "../../apis";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import { LuSearch } from "react-icons/lu";
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";
import { MdWork } from "react-icons/md";
import * as EmailValidator from "email-validator";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdsComponent from "../../components/AdsComponent";
import { useData } from "../../context/DataContext";
import { trackEvent } from "../../utils/firebase";

const Home = (props) => {
  const navigate = useNavigate();
  const { jobs } = useData();
  const [email, setEmail] = useState("");
  const [search, setSearch] = useState("");
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 2;

  useEffect(() => {
    // Track page view when component mounts
    trackEvent('page_view', { page_name: 'home' });
  }, []);

  useEffect(() => {
    if (jobs?.length > 0) {
      setFilteredJobs(jobs);
      setLoading(false);
    }
  }, [jobs]);

  useEffect(() => {
    if (jobs) {
      setLoading(false);
      filterJobs();
      // Reset to first page when filters change
      setCurrentPage(1);
    }
  }, [jobs, search, selectedFilter]);

  const handleSearch = (searchTerm) => {
    trackEvent('job_search', { 
      search_term: searchTerm,
      filter: selectedFilter 
    });
    setSearch(searchTerm);
    filterJobs(searchTerm, selectedFilter);
  };

  const handlePopularSearch = (searchTerm) => {
    trackEvent('job_search', { 
      search_term: searchTerm,
      filter: selectedFilter 
    });
    setSearch(searchTerm);
    filterJobs(searchTerm, selectedFilter);
  };

  const filterJobs = (searchTerm = search, filter = selectedFilter) => {
    let filtered = [...jobs];

    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      filtered = filtered.filter(
        (job) =>
          (job?.title || "").toLowerCase().includes(searchLower) ||
          (job?.company || "").toLowerCase().includes(searchLower) ||
          (job?.location || "").toLowerCase().includes(searchLower) ||
          (job?.description || "").toLowerCase().includes(searchLower) ||
          (job?.employmentType || "")
            .toLowerCase()
            .replace("_", " ")
            .includes(searchLower)
      );
    }

    if (filter !== "All") {
      filtered = filtered.filter(
        (job) =>
          job?.employmentType &&
          job.employmentType.toLowerCase() === filter.toLowerCase()
      );
    }

    setFilteredJobs(filtered);
  };

  const handleSearchInput = (e) => {
    handleSearch(e.target.value);
  };

  const handleFilterChange = (filter) => {
    trackEvent('job_filter_change', { filter });
    setSelectedFilter(filter);
    filterJobs(search, filter);
  };

  const popularSearches = [
    "Software Developer Jobs",
    "Remote Work Opportunities",
    "IT Jobs",
    "Marketing Jobs",
    "Entry Level Positions",
    "Senior Management",
    "Data Science Jobs",
    "Web Developer",
    "Sales Executive",
    "Product Manager",
    "Digital Marketing",
    "HR Jobs",
    "Business Analyst",
    "Full Stack Developer",
    "Project Manager",
  ];

  const subscribeHandle = async () => {
    if (email === "") {
      toast.error("Email is required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } else if (!EmailValidator.validate(email)) {
      toast.error("Email is invalid", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } else {
      try {
        await addData("email", { email });
        trackEvent('newsletter_subscription', { status: 'success' });
        toast.success("Subscribed successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setEmail("");
      } catch (err) {
        trackEvent('newsletter_subscription', { 
          status: 'error',
          error_message: err.message 
        });
        toast.error(err.message | "something went wrong", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    }
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>
          AppointTalent - Home | Discover Jobs & Career Opportunities
        </title>
        <meta
          name="description"
          content="Explore the latest job listings and career opportunities on AppointTalent. Connect with top employers and find your dream job today."
        />
        <meta
          name="keywords"
          content="jobs, career opportunities, employment, job search, appointtalent, hiring, employers"
        />
      </Helmet>

      <section className="hero-section">
        <Container>
          <br />
          <br />
          <br />
          <br />
          <Row className="align-items-center">
            <Col md={8} className="mx-auto text-center">
              <h1 className="hero-title" style={{ color: "white" }}>
                Find Your Dream Job Today
                <br />
                <span className="highlight" style={{ color: "white" }}>
                  Discover Thousands of Career Opportunities
                </span>
              </h1>
              <div className="search-box">
                <LuSearch className="search-icon" />
                <input
                  type="text"
                  placeholder="Search..."
                  value={search}
                  onChange={handleSearchInput}
                  className="search-input"
                />
              </div>
              <div className="filter-buttons">
                {["All", "Full_time", "Part_time", "Remote", "Contract"].map(
                  (filter) => (
                    <button
                      key={filter}
                      className={`filter-btn ${
                        selectedFilter === filter ? "active" : ""
                      }`}
                      onClick={() => handleFilterChange(filter)}
                    >
                      {filter.split("_").join(" ")}
                    </button>
                  )
                )}
              </div>
              <h6 className="_search_options_title">Popular Searches:</h6>
              <section>
                <div className="_tags">
                  {popularSearches.map((tag, i) => {
                    return (
                      <label
                        key={i}
                        className="_kewords"
                        onClick={() => handlePopularSearch(tag)}
                      >
                        <LuSearch /> {tag}
                      </label>
                    );
                  })}
                </div>
              </section>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="jobs-section">
        <Container>
          <Row>
            <Col md={9}>
              <div className="jobs-header">
                <h2 className="section-title">Latest Job Opportunities</h2>
                <div className="jobs-filter">
                  <select
                    className="filter-select"
                    value={selectedFilter}
                    onChange={(e) => handleFilterChange(e.target.value)}
                  >
                    <option value="All">
                      All Jobs ({filteredJobs.length}){" "}
                    </option>
                    <option value="Full_time">Full Time </option>
                    <option value="Part_time">Part Time</option>
                    <option value="Remote">Remote</option>
                    <option value="Contract">Contract</option>
                  </select>
                </div>
              </div>

              {loading ? (
                <div className="loading-state">
                  <div className="spinner"></div>
                  <p>Loading jobs...</p>
                </div>
              ) : filteredJobs.length === 0 ? (
                <div className="no-jobs">
                  <p>No jobs found matching your criteria</p>
                </div>
              ) : (
                <>
                  <div className="jobs-container">
                    {filteredJobs
                      .slice(
                        (currentPage - 1) * jobsPerPage,
                        currentPage * jobsPerPage
                      )
                      .map((job, index) => (
                        <React.Fragment key={job.id}>
                          <JobItem data={job} />
                          {(index + 1) % 5 === 0 && (
                            <div className="ad-space-inline">
                              <AdsComponent
                                client="ca-pub-3768976581935456"
                                slot="3401941690"
                                format="auto"
                                style={{
                                  display: "block",
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                }}
                              />
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                  </div>

                  {filteredJobs.length > jobsPerPage && (
                    <div className="pagination-container">
                      <button
                        className="pagination-btn"
                        onClick={() =>
                          setCurrentPage((prev) => Math.max(prev - 1, 1))
                        }
                        disabled={currentPage === 1}
                      >
                        <FaArrowLeftLong />
                      </button>
                      <span className="page-info">
                        Page {currentPage} of{" "}
                        {Math.ceil(filteredJobs.length / jobsPerPage)}
                      </span>
                      <button
                        className="pagination-btn"
                        onClick={() =>
                          setCurrentPage((prev) =>
                            Math.min(
                              prev + 1,
                              Math.ceil(filteredJobs.length / jobsPerPage)
                            )
                          )
                        }
                        disabled={
                          currentPage ===
                          Math.ceil(filteredJobs.length / jobsPerPage)
                        }
                      >
                        <FaArrowRightLong />
                      </button>
                    </div>
                  )}
                </>
              )}
            </Col>

            <Col md={3}>
              <div className="sidebar">
                <div className="sidebar-section">
                  <h3 className="sidebar-title">Quick Stats</h3>
                  <div className="stats-list">
                    <div className="stat-item">
                      <div className="stat-value">{jobs?.length || 0}</div>
                      <div className="stat-label">Total Jobs</div>
                    </div>
                    <div className="stat-item">
                      <div className="stat-value">
                        {jobs?.filter(
                          (job) =>
                            job?.employmentType.toLowerCase() === "full_time"
                        ).length || 0}
                      </div>
                      <div className="stat-label">Full Time</div>
                    </div>
                    <div className="stat-item">
                      <div className="stat-value">
                        {jobs?.filter(
                          (job) =>
                            job?.employmentType.toLowerCase() === "remote"
                        ).length || 0}
                      </div>
                      <div className="stat-label">Remote</div>
                    </div>
                  </div>
                </div>

                <div className="sidebar-section">
                  <div className="ad-container">
                    <AdsComponent
                      client="ca-pub-3768976581935456"
                      slot="3401941690"
                      format="auto"
                      responsive="true"
                    />
                  </div>
                </div>

                <div className="sidebar-section">
                  <h3 className="sidebar-title">Popular Categories</h3>
                  <div className="category-list">
                    {popularSearches.slice(0, 8).map((category, index) => (
                      <div
                        key={index}
                        className="category-item"
                        onClick={() => handlePopularSearch(category)}
                      >
                        <MdWork size={18} />
                        <span>{category}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="sidebar-section">
                  <h3 className="sidebar-title">Newsletter</h3>
                  <p className="newsletter-text">
                    Get the latest job updates and career tips delivered to your
                    inbox.
                  </p>
                  <div className="newsletter-form">
                    <input
                      type="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="newsletter-input"
                    />
                    <button
                      onClick={subscribeHandle}
                      className="newsletter-btn"
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <style jsx>{`
        .pagination-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 2rem;
          margin-bottom: 2rem;
          gap: 1.5rem;
        }

        .pagination-btn {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          background: #1c2445;
          color: white;
          border-radius: 50%;
          cursor: pointer;
          transition: all 0.3s ease;
          box-shadow: 0 2px 4px rgba(28, 36, 69, 0.2);
        }

        .pagination-btn:hover:not(:disabled) {
          background: #2a3356;
          transform: translateY(-2px);
          box-shadow: 0 4px 8px rgba(28, 36, 69, 0.3);
        }

        .pagination-btn:disabled {
          cursor: not-allowed;
          opacity: 0.5;
          background: #1c2445;
        }

        .pagination-btn svg {
          width: 20px;
          height: 20px;
        }

        .page-info {
          font-size: 0.95rem;
          color: #1c2445;
          font-weight: 500;
          background: rgba(28, 36, 69, 0.1);
          padding: 0.5rem 1rem;
          border-radius: 20px;
        }

        .hero-title {
          font-size: 3.2rem;
          font-weight: 700;
          margin-bottom: 2rem;
          color: #1c2445;
          line-height: 1.2;
        }

        .hero-title .highlight {
          font-size: 2rem;
          color: #2a3356;
          display: block;
          margin-top: 0.8rem;
          font-weight: 500;
          opacity: 0.9;
        }
      `}</style>

      <ToastContainer />
      <Footer />
    </div>
  );
};

export default Home;

Home.defaultProps = {
  country: "in",
  pageSize: 16,
  category: "general",
};

Home.propTypes = {
  country: PropTypes.string,
  pageSize: PropTypes.number,
  category: PropTypes.string,
};

import React, { useEffect, useState, useContext } from "react";
import { FaSortDown } from "react-icons/fa";
import { Popover } from "react-tiny-popover";
import { IoMdClose } from "react-icons/io";
import { RxCheck } from "react-icons/rx";
import "../styles/home.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { IoSearch } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";
import { getjobs } from "../apis";
import { useData } from "../context/DataContext";
import { FiChevronDown } from "react-icons/fi";
import ListGroup from "react-bootstrap/ListGroup";
import { IoIosClose } from "react-icons/io";
import FilterIcon from "./../images/filter.png";
import moment from "moment";

const PopoverFooter = ({ onCancel, onSbmit }) => {
  return (
    <div className="filterFooter">
      <button className="canelBtn" onClick={() => onCancel()}>
        Cancel
      </button>
      {/* <button className="showResultsBtn" onClick={() => onSbmit()}>
        Show results
      </button> */}
    </div>
  );
};

const Filter = ({ search }) => {
  const [isPopoverOpen1, setIsPopoverOpen1] = useState(false);
  const [isPopoverOpen2, setIsPopoverOpen2] = useState(false);
  const [isPopoverOpen3, setIsPopoverOpen3] = useState(false);
  const [isPopoverOpen4, setIsPopoverOpen4] = useState(false);
  const [datePosted, setDatePosted] = useState("All");
  const [selectedExperienceLevel, setSelectedExperienceLevel] = useState("");
  const [selectedRemotes, setSelectedRemotes] = useState("");
  const [dbJobs, setDbJobs] = useState([]);
  const { jobs, setJobs } = useData();
  const [title, setTitle] = useState(search ? search : "");
  const [location, setLocation] = useState("");
  const [company, setCompany] = useState("");
  const [show, setshow] = useState(false);
  const [experienceLevel, setExperienceLevel] = useState([
    "Internship",
    "Entry l evel",
    "Associate",
    "Mid-Senior level",
    "Director",
    "Executive",
  ]);
  const [companies, setCompanies] = useState([]);
  const [remote, setRemote] = useState(["Onsite", "Remote", "Hybrid"]);
  const datePostedTypes = ["All", "Today", "3 Days", "Week", "Month"];

  useEffect(() => {
    (async () => {
      const data = await getjobs();
      const companiesTitle = [
        ...new Set(data.map((val, i) => val.employer_name)),
      ];

      setDbJobs([...data]);
      setCompanies([...companiesTitle]);
    })();
  }, [datePosted]);

  useEffect(() => {
    let filteredJobs = dbJobs;

    if (datePosted !== "All") {
      const today = moment(); // Using moment() to get today's date
      let cutoffDate = moment(); // Initialize cutoffDate with today's date

      if (datePosted === "Today") {
        // No change needed, cutoffDate is already today
      } else if (datePosted === "3 Days") {
        cutoffDate = today.subtract(3, "days"); // Subtract 3 days from today
      } else if (datePosted === "Week") {
        cutoffDate = today.subtract(7, "days"); // Subtract 7 days from today
      } else if (datePosted === "Month") {
        cutoffDate = today.subtract(1, "month"); // Subtract 1 month from today
      }
      filteredJobs = filteredJobs.filter((job) =>
        moment(job.createdAt.toDate()).isSameOrAfter(cutoffDate, "day")
      );
    }

    // Apply experience level filter
    if (selectedExperienceLevel !== "") {
      filteredJobs = filteredJobs.filter(
        (job) => job.job_required_experience === selectedExperienceLevel
      );
    }

    // Apply experience level filter
    if (company !== "") {
      filteredJobs = filteredJobs.filter(
        (job) => job.employer_name === company
      );
    }

    // Apply job type filter
    if (selectedRemotes !== "") {
      filteredJobs = filteredJobs.filter((job) =>
        selectedRemotes === "Hybrid" ? job : job.job_type === selectedRemotes
      );
    }

    if (title) {
      filteredJobs = filteredJobs.filter(
        (job) =>
          job.job_title.toLowerCase().includes(title.toLowerCase()) ||
          job.job_employment_type.toLowerCase().includes(title.toLowerCase())
      );
    }
    setIsPopoverOpen1(false);
    setIsPopoverOpen2(false);
    setIsPopoverOpen3(false);
    setIsPopoverOpen4(false);
    setJobs(filteredJobs);
  }, [datePosted, selectedRemotes, dbJobs, company, selectedExperienceLevel]);

  const jobSearchByTitleAndLocation = () => {
    let filteredJobs = dbJobs;
    filteredJobs = filteredJobs.filter(
      (job) =>
        job.job_title.toLowerCase().includes(title.toLowerCase()) ||
        job.job_employment_type.toLowerCase().includes(title.toLowerCase())
    );
    filteredJobs = filteredJobs.filter(
      (job) =>
        job.job_city.toLowerCase().includes(location.toLowerCase()) ||
        job.job_country.toLowerCase().includes(location.toLowerCase())
    );
    setJobs(filteredJobs);
  };

  return (
    <Container style={{ marginTop: 95 }}>
      <Row className="justify-content-center mt-10">
        <Col
          lg="8"
          md="10"
          sm="12"
          xs="12"
          style={{ backgroundColor: "white", borderRadius: 5, padding: 15 }}
        >
          <Row className="justify-content-center align-items-center ">
            <Col lg="5" md="5" sm="12" xs="12">
              <div className="input_container">
                <CiSearch color="#1c2445" size={20} />
                <input
                  placeholder="Job title, keywords, or comapny"
                  className="_search_input"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                {title !== "" && (
                  <IoIosClose
                    style={{ cursor: "pointer" }}
                    color="#1c2445"
                    size={30}
                    onClick={() => {
                      setTitle("");
                      jobSearchByTitleAndLocation();
                    }}
                  />
                )}
              </div>
            </Col>
            <Col lg="4" md="4" sm="12" xs="12">
              <div className="input_container">
                <CiLocationOn color="#1c2445" size={20} />
                <input
                  placeholder="Location"
                  className="_search_input"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
                {location !== "" && (
                  <IoIosClose
                    style={{ cursor: "pointer" }}
                    color="#1c2445"
                    size={30}
                    onClick={() => {
                      setLocation("");
                      jobSearchByTitleAndLocation();
                    }}
                  />
                )}
              </div>
            </Col>
            <Col lg="2" md="2" sm="10" xs="10">
              <button
                className="_search_btn"
                onClick={jobSearchByTitleAndLocation}
              >
                Search
              </button>
            </Col>
            <Col lg="1" md="1" sm="2" xs="2">
              <div className="_filter_icon" onClick={() => setshow(!show)}>
                <img src={FilterIcon} />
              </div>
            </Col>
          </Row>

          {show && (
            <div className="filterSec">
              <Popover
                isOpen={isPopoverOpen1}
                positions={["bottom", "right"]}
                content={
                  <div className="popover">
                    <div>
                      {datePostedTypes.map((title, i) => {
                        return (
                          <div
                            key={i}
                            className="list"
                            onClick={() => {
                              setDatePosted(title);
                              setIsPopoverOpen1(false);
                            }}
                          >
                            <div className="label">{title}</div>
                          </div>
                        );
                      })}
                    </div>
                    <PopoverFooter
                      onCancel={() => setIsPopoverOpen1(false)}
                      onSbmit={() => alert()}
                    />
                  </div>
                }
              >
                <div
                  onClick={() => {
                    setIsPopoverOpen1(!isPopoverOpen1);
                    setIsPopoverOpen2(false);
                    setIsPopoverOpen3(false);
                    setIsPopoverOpen4(false);
                  }}
                  className="dropdownBtn"
                  style={{
                    backgroundColor: datePosted === "All" ? "" : "#1d2445",
                  }}
                >
                  <span
                    style={{
                      color: datePosted === "All" ? "#404040" : "#fff",
                    }}
                  >
                    {datePosted === "All" ? "Date posted" : datePosted}
                  </span>
                  {datePosted === "All" ? (
                    <FiChevronDown color="#404040" />
                  ) : (
                    <IoMdClose
                      color="#fff"
                      size={15}
                      style={{ cursor: "pointer" }}
                      onClick={() => setDatePosted("All")}
                    />
                  )}
                </div>
              </Popover>
              <Popover
                isOpen={isPopoverOpen2}
                positions={["bottom", "center"]}
                content={
                  <div className="popover">
                    <div>
                      {experienceLevel.map((title, i) => {
                        return (
                          <div
                            key={i}
                            className="list"
                            onClick={() => setSelectedExperienceLevel(title)}
                          >
                            <div className="label">{title}</div>
                          </div>
                        );
                      })}
                    </div>
                    <PopoverFooter
                      onCancel={() => setIsPopoverOpen2(false)}
                      onSbmit={() => alert()}
                    />
                  </div>
                }
              >
                <div
                  onClick={() => {
                    setIsPopoverOpen2(!isPopoverOpen2);
                    setIsPopoverOpen1(false);
                    setIsPopoverOpen4(false);
                    setIsPopoverOpen3(false);
                  }}
                  className="dropdownBtn"
                  style={{
                    backgroundColor:
                      selectedExperienceLevel === "" ? "" : "#1d2445",
                  }}
                >
                  <span
                    style={{
                      color:
                        selectedExperienceLevel === "" ? "#404040" : "#fff",
                    }}
                  >
                    {selectedExperienceLevel === ""
                      ? "Experience level"
                      : selectedExperienceLevel}
                  </span>
                  {selectedExperienceLevel === "" ? (
                    <FiChevronDown color="#404040" />
                  ) : (
                    <IoMdClose
                      color="#fff"
                      size={15}
                      style={{ cursor: "pointer" }}
                      onClick={() => setSelectedExperienceLevel("")}
                    />
                  )}
                </div>
              </Popover>
              <Popover
                isOpen={isPopoverOpen3}
                positions={["bottom", "center"]}
                content={
                  <div className="popover">
                    <div
                      style={{
                        maxHeight: 300,
                        overflowY: "scroll",
                      }}
                    >
                      {companies.map((val, i) => {
                        return (
                          <div
                            key={i}
                            className="list"
                            onClick={() => {
                              setCompany(val);
                              setIsPopoverOpen3(false);
                            }}
                          >
                            <div className="label">{val}</div>
                          </div>
                        );
                      })}
                    </div>
                    <PopoverFooter
                      onCancel={() => setIsPopoverOpen3(false)}
                      onSbmit={() => alert()}
                    />
                  </div>
                }
              >
                <div
                  onClick={() => {
                    setIsPopoverOpen3(!isPopoverOpen3);
                    setIsPopoverOpen1(false);
                    setIsPopoverOpen2(false);
                    setIsPopoverOpen4(false);
                  }}
                  className="dropdownBtn"
                  style={{
                    backgroundColor: company === "" ? "" : "#1d2445",
                  }}
                >
                  <span
                    style={{
                      color: company === "" ? "#404040" : "#fff",
                    }}
                  >
                    {company === "" ? "Company" : company}
                  </span>
                  {company === "" ? (
                    <FiChevronDown color="#404040" />
                  ) : (
                    <IoMdClose
                      color="#fff"
                      size={15}
                      style={{ cursor: "pointer" }}
                      onClick={() => setCompany("")}
                    />
                  )}
                </div>
              </Popover>
              <Popover
                isOpen={isPopoverOpen4}
                positions={["bottom", "center"]}
                content={
                  <div className="popover">
                    <div>
                      {remote.map((title, i) => {
                        return (
                          <div
                            key={i}
                            className="list"
                            onClick={() => {
                              setSelectedRemotes(title);
                              // filterJobs();
                              setIsPopoverOpen4(false);
                            }}
                          >
                            <div className="label">{title}</div>
                          </div>
                        );
                      })}
                    </div>
                    <PopoverFooter
                      onCancel={() => setIsPopoverOpen4(false)}
                      onSbmit={() => alert()}
                    />
                  </div>
                }
              >
                <div
                  onClick={() => {
                    setIsPopoverOpen4(!isPopoverOpen4);
                    setIsPopoverOpen1(false);
                    setIsPopoverOpen2(false);
                    setIsPopoverOpen3(false);
                  }}
                  className="dropdownBtn"
                  style={{
                    backgroundColor: selectedRemotes === "" ? "" : "#1d2445",
                  }}
                >
                  <span
                    style={{
                      color: selectedRemotes === "" ? "#404040" : "#fff",
                    }}
                  >
                    {selectedRemotes === "" ? "Remote" : selectedRemotes}
                  </span>
                  {selectedRemotes === "" ? (
                    <FiChevronDown color="#404040" />
                  ) : (
                    <IoMdClose
                      color="#fff"
                      size={15}
                      style={{ cursor: "pointer" }}
                      onClick={() => setSelectedRemotes("")}
                    />
                  )}
                </div>
              </Popover>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Filter;

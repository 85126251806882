import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyAw2Yody5jJvr3X1I9-9QFqA12akdrbmTk",
    authDomain: "applyjobtoday-6b75e.firebaseapp.com",
    projectId: "applyjobtoday-6b75e",
    storageBucket: "applyjobtoday-6b75e.appspot.com",
    messagingSenderId: "825142100638",
    appId: "1:825142100638:web:b67ec38c97edf9c406718f",
    measurementId: "G-7QJQN0WY7X"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };
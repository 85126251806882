import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../../context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import {
  MdLocationOn,
  MdWorkOutline,
  MdAttachMoney,
  MdCalendarToday,
  MdOutlineBookmark,
  MdOutlineBookmarkBorder,
  MdCheckCircle,
} from "react-icons/md";
import { BsBuilding, BsListCheck, BsBookmark } from "react-icons/bs";
import { BsGlobe } from "react-icons/bs";
import Footer from "../../components/Footer";
import { useData } from "../../context/DataContext";
import { trackEvent } from "../../utils/firebase";

const JobDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [applying, setApplying] = useState(false);
  const [alreadyApplied, setAlreadyApplied] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { user } = useData();
  console.log("user", user);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const docRef = doc(db, "jobs", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setJob({ id: docSnap.id, ...docSnap.data() });
          // Track job view
          trackEvent('job_view', {
            job_id: docSnap.id,
            job_title: docSnap.data().title,
            company: docSnap.data().hiringOrganization?.name
          });
        }
      } catch (error) {
        console.error("Error fetching job details:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchJobDetails();
  }, [id]);

  useEffect(() => {
    if (user && id) {
      checkIfAlreadyApplied();
      checkIfJobSaved();
    }
  }, [user, id]);

  const checkIfAlreadyApplied = async () => {
    try {
      const applicationRef = doc(db, "applications", `${user.uid}_${id}`);
      const applicationDoc = await getDoc(applicationRef);
      setAlreadyApplied(applicationDoc.exists());
    } catch (error) {
      console.error("Error checking application status:", error);
    }
  };

  const checkIfJobSaved = async () => {
    try {
      if (!user) return;

      const userDoc = await getDoc(doc(db, "users", user.uid));
      if (userDoc.exists()) {
        const savedJobs = userDoc.data()?.savedJobs || [];
        setIsSaved(savedJobs.includes(id));
      }
    } catch (error) {
      console.error("Error checking saved status:", error);
    }
  };

  const formatDate = (date) => {
    if (!date) return "";
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleApply = async () => {
    if (!user) {
      sessionStorage.setItem("redirectAfterLogin", `/job/${id}`);
      toast.info("Please login to apply for this job");
      navigate("/login");
      return;
    }

    try {
      // Track application start
      trackEvent('job_application_start', {
        job_id: job.id,
        job_title: job.title,
        company: job.hiringOrganization?.name
      });

      setApplying(true);

      // Get user profile
      const userDoc = await getDoc(doc(db, "users", user.uid));
      const userProfile = userDoc.data();

      // Check if already applied
      const applicationRef = doc(db, "applications", `${user.uid}_${id}`);
      const applicationDoc = await getDoc(applicationRef);

      if (applicationDoc.exists()) {
        toast.info("You have already applied for this job");
        return;
      }

      // Create application
      await setDoc(applicationRef, {
        userId: user.uid,
        jobId: id,
        status: "pending",
        appliedAt: new Date(),
        updatedAt: new Date(),
        jobTitle: job.title,
        company: job.hiringOrganization?.name,
        userProfile: {
          name: `${userProfile.firstName} ${userProfile.lastName}`,
          email: userProfile.email,
          phone: userProfile.phone,
          resume: userProfile.resume,
        },
      });

      // Update job applications count
      const jobRef = doc(db, "jobs", id);
      await setDoc(
        jobRef,
        {
          ...job,
          applicationsCount: (job.applicationsCount || 0) + 1,
          updatedAt: new Date(),
        },
        { merge: true }
      );

      // Track successful application
      trackEvent('job_application_success', {
        job_id: job.id,
        job_title: job.title,
        company: job.hiringOrganization?.name
      });

      setShowSuccessModal(true);
    } catch (error) {
      // Track failed application
      trackEvent('job_application_error', {
        job_id: job.id,
        job_title: job.title,
        company: job.hiringOrganization?.name,
        error: error.message
      });
      toast.error("Failed to submit application");
    } finally {
      setApplying(false);
    }
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    navigate("/applications");
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner" style={{ position: "relative" }}></div>
      </div>
    );
  }

  if (!job) {
    return null;
  }

  return (
    <>
      <Container fluid className="job-details-container py-4">
        <Container>
          <br />
          <br />
          <br />
          <br />

          <Row>
            <Col lg={8} className="mb-4">
              <Card className="main-content">
                <Card.Body>
                  <div className="job-header">
                    <div>
                      <h1 className="job-title" style={{ color: "white" }}>
                        {job.title}
                      </h1>
                      <h2 className="company-name">
                        {job.hiringOrganization?.name}
                      </h2>
                    </div>
                    {user?.uid === job.employerId && (
                      <Button
                        variant="outline-light"
                        onClick={() => navigate(`/edit-job/${job.id}`)}
                      >
                        Edit Job
                      </Button>
                    )}
                  </div>

                  <div className="job-meta">
                    <div className="meta-item">
                      <MdLocationOn className="meta-icon" />
                      <div>
                        <strong>Location</strong>
                        <div>
                          {job.applicantLocationRequirements?.city},{" "}
                          {job.applicantLocationRequirements?.region}
                        </div>
                      </div>
                    </div>
                    <div className="meta-item">
                      <MdWorkOutline className="meta-icon" />
                      <div>
                        <strong>Job Type</strong>
                        <div>{job.employmentType.replace(/_/g, " ")}</div>
                      </div>
                    </div>
                    <div className="meta-item">
                      <MdAttachMoney className="meta-icon" />
                      <div>
                        <strong>Salary</strong>
                        <div>
                          {job.baseSalary?.minValue} -{" "}
                          {job.baseSalary?.maxValue} {job.baseSalary?.currency}/
                          {job.baseSalary?.unitText.toLowerCase()}
                        </div>
                      </div>
                    </div>
                    <div className="meta-item">
                      <MdCalendarToday className="meta-icon" />
                      <div>
                        <strong>Posted</strong>
                        <div>{formatDate(job.createdAt)}</div>
                      </div>
                    </div>
                  </div>

                  <section className="job-section">
                    <h3>Job Description</h3>
                    <p className="whitespace-pre-line">{job.description}</p>
                  </section>

                  <section className="job-section">
                    <h3>Key Responsibilities</h3>
                    <div className="whitespace-pre-line">
                      {job.responsibilities}
                    </div>
                  </section>

                  <section className="job-section">
                    <h3>Required Skills</h3>
                    <div className="whitespace-pre-line">{job.skills}</div>
                  </section>

                  <section className="job-section">
                    <h3>Qualifications</h3>
                    <div className="whitespace-pre-line">
                      {job.qualifications}
                    </div>
                  </section>

                  {job.jobBenefits && (
                    <section className="job-section">
                      <h3>Benefits</h3>
                      <div className="whitespace-pre-line">
                        {job.jobBenefits}
                      </div>
                    </section>
                  )}
                </Card.Body>
              </Card>
            </Col>

            <Col lg={4}>
              <Card className="sidebar-card company-info">
                <Card.Body>
                  <h3>
                    <BsBuilding className="section-icon" /> Company Overview
                  </h3>
                  <p className="company-name text-black" >{job.hiringOrganization?.name}</p>
                  {job.hiringOrganization?.sameAs && (
                    <a
                      href={job.hiringOrganization.sameAs}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="company-website"
                    >
                      <BsGlobe size={18} /> Visit Website
                    </a>
                  )}
                </Card.Body>
              </Card>

              <Card className="sidebar-card requirements-info">
                <Card.Body>
                  <h3>
                    <BsListCheck className="section-icon" /> Requirements
                  </h3>
                  <div className="requirement-item">
                    <strong>Experience</strong>
                    <div className="whitespace-pre-line">
                      {job.experienceRequirements}
                    </div>
                  </div>
                  <div className="requirement-item">
                    <strong>Education</strong>
                    <div className="whitespace-pre-line">
                      {job.educationRequirements}
                    </div>
                  </div>
                </Card.Body>
              </Card>

              {user?.uid !== job.employerId && (
                <div className="action-buttons-container">
                  <div className="action-buttons-wrapper">
                    <Button
                      variant="primary"
                      // size="lg"
                      className="apply-button"
                      onClick={handleApply}
                      disabled={applying || alreadyApplied}
                    >
                      <span className="button-icon">
                        {applying ? (
                          <span className="button-spinner"></span>
                        ) : (
                          <MdWorkOutline size={15} style={{ marginRight: "5px" }} />
                        )}
                      </span>
                      {applying 
                        ? "Applying..." 
                        : alreadyApplied 
                          ? "Already Applied" 
                          : "Apply Now"}
                    </Button>
                  </div>
                  <small className="application-note">
                    {!user 
                      ? "Login required to apply" 
                      : alreadyApplied 
                        ? "You have already applied for this position"
                        : "Easy apply • Quick application process"}
                  </small>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </Container>
      <Footer />

      {/* Success Modal */}
      <Modal
        show={showSuccessModal}
        onHide={handleSuccessModalClose}
        centered
        className="application-success-modal"
      >
        <Modal.Body className="text-center p-4">
          <div className="success-icon mb-3">
            <MdCheckCircle size={60} color="#28a745" className="text-success" />
          </div>
          <h4 className="mb-3">Application Submitted Successfully!</h4>
          <p className="text-muted mb-4">
            Thank you for applying to {job?.title} at {job?.hiringOrganization?.name}.
            We will review your application and get back to you soon.
          </p>
          <Button
            variant="primary"
            onClick={handleSuccessModalClose}
            className="px-4 py-2"
          >
            View My Applications
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default JobDetails;

import React, { useEffect, useState, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ToastContainer, toast, Bounce } from "react-toastify";
import * as EmailValidator from "email-validator";
import Footer from "../../components/Footer";
import emailjs from "@emailjs/browser";
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaCheck } from "react-icons/fa";
import { BsLinkedin, BsTwitter, BsFacebook, BsArrowRight } from "react-icons/bs";
import { BiSupport } from "react-icons/bi";
import { MdOutlineBusinessCenter, MdSecurity } from "react-icons/md";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useAuth } from "../../context/AuthContext";
import { Helmet } from "react-helmet";

import "../../styles/contact.css";
import { db } from "../../firebase";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  // const { user } = useContext(AuthContext);
  const { user } = useAuth();

  console.log("user", user);
  const contactReasons = [
    { icon: <BiSupport color="#1c2445" />, title: "Technical Support", description: "Get help with technical issues" },
    { icon: <MdOutlineBusinessCenter color="#1c2445" />, title: "Business Inquiry", description: "Partner with us" },
    { icon: <MdSecurity color="#1c2445" />, title: "Account Security", description: "Report security concerns" }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateForm = () => {
    if (!name.trim()) {
      toast.error("Please enter your name");
      return false;
    }
    if (!email.trim() || !EmailValidator.validate(email)) {
      toast.error("Please enter a valid email address");
      return false;
    }
    if (!subject.trim()) {
      toast.error("Please enter a subject");
      return false;
    }
    if (!message.trim()) {
      toast.error("Please enter your message");
      return false;
    }
    return true;
  };

  const saveToFirestore = async (messageData) => {
    try {
      const messagesRef = collection(db, "contact_messages");
      await addDoc(messagesRef, {
        ...messageData,
        userId: user?.uid || "anonymous",
        createdAt: serverTimestamp(),
        status: "pending" // pending, responded, resolved
      });
    } catch (error) {
      console.error("Error saving message to Firestore:", error);
      throw error;
    }
  };

  const SendEmail = async () => {
    if (!validateForm()) return;

    try {
      setLoading(true);

      // Prepare message data
      const messageData = {
        name,
        email,
        subject,
        message,
        contactReason: selectedReason,
        userEmail: user?.email,
      };

      // Send email via EmailJS
     

      // Save to Firestore
      await saveToFirestore(messageData);

      // Clear form
      setEmail("");
      setName("");
      setSubject("");
      setMessage("");
      setSelectedReason("");

      toast.success("Message sent successfully! We'll get back to you soon.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        // progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } catch (error) {
      console.error(error);
      toast.error("Failed to send message. Please try again later.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        // progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact-page">
      <Helmet>
        <title>Contact Us | AppointTalent - Get in Touch</title>
        <meta name="description" content="Contact AppointTalent for inquiries, support, or partnership opportunities. We're here to assist you." />
        <meta name="keywords" content="contact, support, inquiries, partnership, appointtalent" />
      </Helmet>
      <div className="contact-hero">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} className="text-center">
              <h1>Get in Touch</h1>
              <p>Have questions? We'd love to hear from you. Send us a message and we'll respond as soon as possible.</p>
              <div className="hero-stats">
                <div className="stat-item">
                  <span className="stat-number">24/7</span>
                  <span className="stat-label" style={{ color: "#ffffff" }}>Support</span>
                </div>
                <div className="stat-item">
                  <span className="stat-number">1M+</span>
                  <span className="stat-label" style={{ color: "#ffffff" }}>Users</span>
                </div>
                <div className="stat-item">
                  <span className="stat-number">99%</span>
                  <span className="stat-label" style={{ color: "#ffffff" }}>Satisfaction</span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="contact-content">
        <Row className="justify-content-center">
          <Col lg={12} className="mb-4">
            <div className="contact-reasons">
              {contactReasons.map((reason, index) => (
                <div
                  key={index}
                  className={`reason-card ${selectedReason === reason.title ? 'selected' : ''}`}
                  onClick={() => setSelectedReason(reason.title)}
                >
                  <div className="reason-icon">{reason.icon}</div>
                  <h4>{reason.title}</h4>
                  <p>{reason.description}</p>
                  {selectedReason === reason.title && <FaCheck className="check-icon" />}
                </div>
              ))}
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center main-content">
          <Col lg={4} md={5}>
            <div className="contact-info">
              <h3>Contact Information</h3>
              <p>Choose your preferred way to get in touch with us:</p>
              
              <div className="info-item">
                <FaEnvelope color="#1c2445" />
                <div>
                  <h4>Email Us</h4>
                  <p>contact@appointtalent.com</p>
                  <small>We'll respond within 24 hours</small>
                </div>
              </div>
              
              <div className="info-item">
                <FaPhone color="#1c2445"  />
                <div>
                  <h4>Call Us</h4>
                  <p>+1 (555) 123-4567</p>
                  <small>Mon-Fri from 8am to 6pm</small>
                </div>
              </div>
              
              <div className="info-item">
                <FaMapMarkerAlt color="#1c2445"  />
                <div>
                  <h4>Visit Us</h4>
                  <p>123 Business Avenue<br />Silicon Valley, CA 94025</p>
                  <a href="#" className="directions-link">
                    Get directions <BsArrowRight color="#1c2445" />
                  </a>
                </div>
              </div>

              <div className="social-links">
                <h4>Connect With Us</h4>
                <p>Follow us on social media for updates and job opportunities</p>
                <div className="social-icons">
                  <a href="#" target="_blank" rel="noopener noreferrer" className="linkedin">
                    <BsLinkedin />
                  </a>
                  <a href="#" target="_blank" rel="noopener noreferrer" className="twitter">
                    <BsTwitter />
                  </a>
                  <a href="#" target="_blank" rel="noopener noreferrer" className="facebook">
                    <BsFacebook />
                  </a>
                </div>
              </div>
            </div>
          </Col>

          <Col lg={6} md={7}>
            <div className="contact-form">
              <h3>Send us a Message</h3>
              <p>Fill out the form below and we'll get back to you shortly.</p>
              
              <div className="form-grid">
                <div className="form-group">
                  <label>Your Name</label>
                  <input
                    type="text"
                    placeholder="John Doe"
                    className="form-input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Your Email</label>
                  <input
                    type="email"
                    placeholder="john@example.com"
                    className="form-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Subject</label>
                <input
                  type="text"
                  placeholder="How can we help you?"
                  className="form-input"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>

              <div className="form-group">
                <label>Message</label>
                <textarea
                  placeholder="Tell us more about your inquiry..."
                  className="form-input"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>

              <button 
                onClick={SendEmail} 
                className={`submit-button ${loading ? 'loading' : ''}`}
                disabled={loading}
              >
                {loading ? 'Sending...' : 'Send Message'}
              </button>

              <div className="form-footer">
                <p>
                  <FaCheck className="check-icon" />
                  Your information is protected by our privacy policy
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Contact;

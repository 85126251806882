import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../../firebase";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import showToast from "../../utils/messageToast";
import { FcGoogle } from "react-icons/fc";
import LoginImage from "../../images/loginImage.png";
import "../../styles/login.css";
import { Helmet } from "react-helmet";
import { trackEvent } from "../../utils/firebase";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    trackEvent("page_view", { page_name: "login" });
  }, []);

  const handleEmailPasswordLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      showToast("Please fill in all fields", "error");
      return;
    }

    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      trackEvent("login_success", { method: "email" });
      showToast("Welcome back! You've successfully logged in.", "success");
      const redirectUrl = sessionStorage.getItem("redirectUrl");
      if (redirectUrl) {
        sessionStorage.removeItem("redirectUrl");
        navigate(redirectUrl);
      } else {
        navigate("/");
      }
    } catch (error) {
      trackEvent("login_error", {
        method: "email",
        error: error.message,
      });
      console.error("Login error:", error);
      let errorMessage;
      switch (error.code) {
        case "auth/invalid-email":
          errorMessage = "Please enter a valid email address";
          break;
        case "auth/user-not-found":
          errorMessage =
            "No account found with this email. Please check your email or sign up for a new account";
          break;
        case "auth/wrong-password":
          errorMessage = "Incorrect password. Please try again";
          break;
        case "auth/too-many-requests":
          errorMessage =
            "Too many failed login attempts. Please try again later or reset your password";
          break;
        case "auth/network-request-failed":
          errorMessage =
            "Network error. Please check your internet connection and try again";
          break;
        default:
          errorMessage = "Unable to log in. Please try again";
      }
      showToast(errorMessage, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    setLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      const { user } = await signInWithPopup(auth, provider);

      // Check if user exists in Firestore
      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        // User doesn't exist, create new user document
        const newUser = {
          email: user.email,
          createdAt: new Date(),
          updatedAt: new Date(),
          firstName: user?.displayName.split(" ")[0],
          lastName: user?.displayName.split(" ")[1],
          phone: "",
          location: "",
          title: "",
          about: "",
          skills: [],
          experience: [],
          education: [],
          resume: "",
          profilePicture: user?.photoURL,
          savedJobs: [],
          applications: [],
          profileCompleted: false,
        };

        await setDoc(userRef, newUser);
        trackEvent("user_registration", {
          method: "google",
          user_type: "job-seeker",
        });
      } else {
        // Update last login for existing user
        await setDoc(
          userRef,
          {
            lastLogin: new Date(),
            updatedAt: new Date(),
          },
          { merge: true }
        );
      }

      trackEvent("login_success", { method: "google" });
      showToast(
        "Welcome! You've successfully logged in with Google.",
        "success"
      );

      const redirectUrl = sessionStorage.getItem("redirectUrl");
      if (redirectUrl) {
        sessionStorage.removeItem("redirectUrl");
        navigate(redirectUrl);
      } else {
        navigate("/");
      }
    } catch (error) {
      trackEvent("login_error", {
        method: "google",
        error: error.message,
      });
      console.error("Google login error:", error);
      let errorMessage;
      switch (error.code) {
        case "auth/popup-blocked":
          errorMessage =
            "Pop-up was blocked by your browser. Please allow pop-ups for this site";
          break;
        case "auth/popup-closed-by-user":
          errorMessage = "Google sign-in was cancelled. Please try again";
          break;
        case "auth/network-request-failed":
          errorMessage =
            "Network error. Please check your internet connection and try again";
          break;
        default:
          errorMessage = "Unable to sign in with Google. Please try again";
      }
      showToast(errorMessage, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="loginMain">
      <Helmet>
        <title>Login | AppointTalent - Access Your Account</title>
        <meta
          name="description"
          content="Login to your AppointTalent account to explore job opportunities and connect with employers."
        />
        <meta
          name="keywords"
          content="login, sign in, account access, appointtalent, job opportunities"
        />
      </Helmet>
      <div className="loginRow">
        <section className="loginFormSec">
          <h2>Login to your Account!</h2>
          <button
            className="googleBtn"
            onClick={handleGoogleLogin}
            disabled={loading}
            type="button"
          >
            Continue with Google
            <FcGoogle className="google-icon" size={20} />
          </button>

          <div className="OrRow">
            <div />
            <span>Or</span>
            <div />
          </div>

          <form
            onSubmit={handleEmailPasswordLogin}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <input
              type="email"
              placeholder="Email"
              className="loginInput"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
            />

            <input
              type="password"
              placeholder="Password"
              className="loginInput"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
            />

            <button type="submit" className="loginBtn" disabled={loading}>
              {loading ? "Logging in..." : "Login"}
            </button>
          </form>

          <div className="signupRow">
            <span>Don't have an account?</span>
            <Link to="/register" className="signup-link">
              Sign Up
            </Link>
          </div>
        </section>

        <section className="loginSec2">
          <img src={LoginImage} alt="Login" />
        </section>
      </div>
    </div>
  );
};

export default Login;

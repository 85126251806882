import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Card } from "react-bootstrap";
import { GrLocation } from "react-icons/gr";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import "../../styles/privacypolicy.css";
// import privacyPolicyImage from "../../assets/privacy-policy.jpg";

const sections = [
  {
    title: "Information We Collect",
    content: [
      "Personal Information: We collect personal information you provide to us, including but not limited to your name, email address, phone number, resume/CV, employment history, and any other information you choose to provide when using our Website.",
      "Usage Information: We may collect information about how you use our Website, including your browsing actions, pages visited, links clicked, and other interactions with the Website.",
      "Cookies and Similar Technologies: We may use cookies, web beacons, and similar technologies to collect information about your interactions with our Website.",
      "Usage Information: We may collect information about how you use our Website, including your browsing actions, pages visited, links clicked, and other interactions with the Website.",
      "Cookies and Similar Technologies: We may use cookies, web beacons, and similar technologies to collect information about your use of the Website and to improve your experience. You can manage your cookie preferences through your browser settings.",
    ],
  },
  {
    title: "How We Use Your Information",
    content: [
      "Provide and Improve Services: We use the information we collect to provide and improve our services, personalize your experience, and optimize the functionality of the Website.",
      "Communication: We may use your contact information to communicate with you about your account, respond to inquiries, send updates, and provide information about our services.",
      "Analytics: We may use aggregated and anonymized data for analytics purposes to understand trends, track user interactions, and improve the Website.",
      "Legal Compliance: We may use your information to comply with applicable laws, regulations, and legal processes, or to protect our rights, property, or safety and that of others.",
    ],
  },
  {
    title: "Information Sharing",
    content: [
      "Service Providers: We may share your information with third-party service providers who assist us in operating the Website, conducting our business, or servicing you, subject to confidentiality obligations.",
      "Legal Requirements: We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).",
      "Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction.",
    ],
  },
  {
    title: "Data Security",
    content: [
      "We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.",
    ],
  },
  {
    title: "Your Choices",
    content: [
      "You may choose not to provide certain personal information, but this may limit your ability to access certain features of the Website. You can also opt-out of receiving promotional communications from us by following the instructions provided in such communications.",
    ],
  },
  {
    title: "Children's Privacy",
    content: [
      "Our Website is not intended for children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you believe that we have inadvertently collected information from a child under 13, please contact us immediately.",
    ],
  },
  {
    title: "Changes to this Privacy Policy",
    content: [
      "We may update this Privacy Policy from time to time by posting the revised version on the Website. Your continued use of the Website after the effective date of the revised Privacy Policy constitutes acceptance of the terms.",
    ],
  },
  {
    title: "Contact Us",
    content: [
      "If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at [contact email].",
      "By using our Website, you consent to the collection, use, and disclosure of your information as described in this Privacy Policy.",
    ],
  },
];

const PrivacyPolicy = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Privacy Policy | AppointTalent - Your Data Matters</title>
        <meta name="description" content="Read AppointTalent's privacy policy to understand how we handle your data and protect your privacy." />
        <meta name="keywords" content="privacy policy, data protection, appointtalent, user privacy" />
      </Helmet>
      <div className="interview-hero">
      <br />
            <br />
            <br />
        <Container>
          <Row className="justify-content-center ">
          
            <Col md={8} className="text-center">
              <h1>Privacy Policy</h1>
              <p>Last Updated: March 1, 2024</p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="_main_section">
       
        <p className="thanksMessageTxt">
          Thank you for using our Job Finder website ("Website"). This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use our services.
        </p>
        {sections.map((section, index) => (
          <Card key={index} className="mb-4">
            <Card.Body>
              <Card.Title>{section.title}</Card.Title>
              {section.content.map((text, idx) => (
                <Card.Text key={idx}>{text}</Card.Text>
              ))}
            </Card.Body>
          </Card>
        ))}
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;

PrivacyPolicy.defaultProps = {
  country: "in",
  pageSize: 16,
  category: "general",
};

PrivacyPolicy.propTypes = {
  country: PropTypes.string,
  pageSize: PropTypes.number,
  category: PropTypes.string,
};

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { toast } from "react-toastify";
import { FcGoogle } from "react-icons/fc";
import LoginImage from "../../images/loginImage.png";
import "../../styles/login.css";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { Helmet } from "react-helmet";
import { trackEvent } from "../../utils/firebase";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    trackEvent('page_view', { page_name: 'register' });
  }, []);

  const handleEmailPasswordRegister = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error("Please fill in all fields");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      trackEvent('registration_error', { 
        method: 'email',
        error: 'Passwords do not match'
      });
      return;
    }

    if (password.length < 6) {
      toast.error("Password must be at least 6 characters long");
      trackEvent('registration_error', { 
        method: 'email',
        error: 'Password must be at least 6 characters long'
      });
      return;
    }

    setLoading(true);
    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        createdAt: new Date(),
        updatedAt: new Date(),
        firstName: "",
        lastName: "",
        phone: "",
        location: "",
        title: "",
        about: "",
        skills: [],
        experience: [],
        education: [],
        resume: "",
        profilePicture: "",
        savedJobs: [],
        applications: [],
        profileCompleted: false,
        userType: "job-seeker",
      });

      toast.success("Welcome! Your account has been successfully created.");
      trackEvent('registration_success', { method: 'email' });
      navigate("/");
    } catch (error) {
      trackEvent('registration_error', { 
        method: 'email',
        error: error.message
      });
      let errorMessage;
      switch (error.code) {
        case "auth/email-already-in-use":
          errorMessage =
            "An account with this email already exists. Please log in or use a different email";
          break;
        case "auth/invalid-email":
          errorMessage = "Please enter a valid email address";
          break;
        case "auth/operation-not-allowed":
          errorMessage =
            "Registration is temporarily disabled. Please try again later";
          break;
        case "auth/weak-password":
          errorMessage =
            "Please choose a stronger password. Use a mix of letters, numbers, and symbols";
          break;
        case "auth/network-request-failed":
          errorMessage =
            "Network error. Please check your internet connection and try again";
          break;
        default:
          errorMessage = "Unable to create account. Please try again";
      }
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleRegister = async () => {
    setLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      const { user } = await signInWithPopup(auth, provider);
      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        createdAt: new Date(),
        updatedAt: new Date(),
        firstName: user?.displayName.split(" ")[0],
        lastName: user?.displayName.split(" ")[1],
        phone: "",
        location: "",
        title: "",
        about: "",
        skills: [],
        experience: [],
        education: [],
        resume: "",
        profilePicture: user?.photoURL,
        savedJobs: [],
        applications: [],
        profileCompleted: false,
      });

      toast.success(
        "Welcome! Your account has been successfully created with Google."
      );
      trackEvent('registration_success', { method: 'google' });
      navigate("/");
    } catch (error) {
      trackEvent('registration_error', { 
        method: 'google',
        error: error.message
      });
      let errorMessage;
      switch (error.code) {
        case "auth/popup-blocked":
          errorMessage =
            "Pop-up was blocked by your browser. Please allow pop-ups for this site";
          break;
        case "auth/popup-closed-by-user":
          errorMessage = "Google sign-up was cancelled. Please try again";
          break;
        case "auth/network-request-failed":
          errorMessage =
            "Network error. Please check your internet connection and try again";
          break;
        default:
          errorMessage = "Unable to sign up with Google. Please try again";
      }
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="loginMain">
      <Helmet>
        <title>Register | AppointTalent - Join Our Community</title>
        <meta
          name="description"
          content="Register on AppointTalent to access job listings and connect with top employers. Join our community today."
        />
        <meta
          name="keywords"
          content="register, sign up, join, appointtalent, job listings, employers"
        />
      </Helmet>
      <div className="loginRow">
        <section className="loginFormSec">
          <h2>Register your Account!</h2>
          <button
            className="googleBtn"
            onClick={handleGoogleRegister}
            disabled={loading}
            type="button"
          >
            Continue with Google
            <FcGoogle className="google-icon" size={20} />
          </button>

          <div className="OrRow">
            <div />
            <span>Or</span>
            <div />
          </div>

          <form
            onSubmit={handleEmailPasswordRegister}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <input
              type="email"
              placeholder="Email"
              className="loginInput"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
              required
            />

            <input
              type="password"
              placeholder="Password"
              className="loginInput"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
              required
              minLength={6}
            />

            <input
              type="password"
              placeholder="Confirm Password"
              className="loginInput"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              disabled={loading}
              required
            />

            <button type="submit" className="loginBtn" disabled={loading}>
              {loading ? "Creating Account..." : "Sign Up"}
            </button>
          </form>

          <div className="signupRow">
            <span>Already have an account?</span>
            <Link to="/login" className="signup-link">
              Log In
            </Link>
          </div>
        </section>

        <section className="loginSec2">
          <img src={LoginImage} alt="Register" />
        </section>
      </div>
    </div>
  );
};

export default Register;

import React, { useState, useRef, useEffect } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../firebase";
import { useAuth } from "../../context/AuthContext";
import { toast } from "react-toastify";
import {
  FiUpload,
  FiUser,
  FiBriefcase,
  FiBook,
  FiAward,
  FiCode,
  FiTarget,
} from "react-icons/fi";
import "../../styles/edit-profile.css";
import { useData } from "../../context/DataContext";
import { trackEvent } from "../../utils/firebase";

const EditProfile = () => {
  const navigate = useNavigate();
  const { currentUser } = useData();
  const { user } = useAuth();
  const fileInputRef = useRef(null);
  const [activeTab, setActiveTab] = useState("basic");
  const [isUploading, setIsUploading] = useState(false);
  const [formData, setFormData] = useState({
    displayName: "",
    email: "",
    phone: "",
    location: "",
    bio: "",
    photoURL: "",
    linkedIn: "",
    portfolio: "",
    resume: "",
    experience: [],
    education: [],
    skills: {
      technical: [],
      soft: [],
      certifications: []
    },
    projects: [],
    careerPreferences: {
      desiredRole: "",
      desiredSalary: "",
      jobType: "",
      workLocation: "",
      willingToRelocate: false,
      preferredIndustries: []
    }
  });

  useEffect(() => {
    trackEvent('page_view', { page_name: 'edit_profile' });
    const fetchUserData = async () => {
      if (user?.uid) {
        try {
          const userRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setFormData(prev => ({
              ...prev,
              displayName: userData.displayName || "",
              email: userData.email || "",
              phone: userData.phone || "",
              location: userData.location || "",
              bio: userData.bio || "",
              photoURL: userData.photoURL || "",
              linkedIn: userData.linkedIn || "",
              portfolio: userData.portfolio || "",
              resume: userData.resume || "",
              experience: userData.experience || [],
              education: userData.education || [],
              skills: {
                technical: (userData.skills?.technical || []),
                soft: (userData.skills?.soft || []),
                certifications: (userData.skills?.certifications || [])
              },
              projects: userData.projects || [],
              careerPreferences: userData.careerPreferences || {
                desiredRole: "",
                desiredSalary: "",
                jobType: "",
                workLocation: "",
                willingToRelocate: false,
                preferredIndustries: []
              }
            }));
            trackEvent('profile_data_loaded');
          }
        } catch (error) {
          trackEvent('profile_data_load_error', { 
            error: error.message 
          });
          console.error("Error fetching user data:", error);
          toast.error("Failed to load user data");
        }
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    console.log("Form Data Updated:", formData);
  }, [formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      trackEvent('profile_update_start');
      
      const updatedFormData = {
        ...formData,
        education: formData.education.filter(edu => 
          edu.degree || edu.school || edu.fieldOfStudy || edu.location
        ),
        experience: formData.experience.filter(exp => 
          exp.title || exp.company || exp.description
        ),
        projects: formData.projects.filter(proj => 
          proj.name || proj.description
        ),
        lastUpdated: new Date().toISOString()
      };

      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, updatedFormData);
      trackEvent('profile_update_success', {
        updated_fields: Object.keys(formData).filter(key => formData[key] !== "")
      });
      toast.success("Profile updated successfully!");
      navigate("/profile");
    } catch (error) {
      trackEvent('profile_update_error', { 
        error: error.message 
      });
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile: " + error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Track when user updates important profile fields
    if (["displayName", "skills", "experience", "education"].includes(name) && value.length > 0) {
      trackEvent('profile_field_update', { 
        field: name 
      });
    }
  };

  const handleExperienceChange = (index, field, value) => {
    const newExperience = [...formData.experience];
    if (field === "current") {
      newExperience[index][field] = value;
      if (value) {
        newExperience[index].endDate = "";
      }
    } else {
      newExperience[index][field] = value;
    }
    setFormData((prev) => ({
      ...prev,
      experience: newExperience,
    }));
  };

  const addExperience = () => {
    setFormData((prev) => ({
      ...prev,
      experience: [
        ...prev.experience,
        {
          title: "",
          company: "",
          location: "",
          employmentType: "",
          startDate: "",
          endDate: "",
          current: false,
          description: "",
          keyAchievements: [],
          skills: [],
        },
      ],
    }));
  };

  const removeExperience = (index) => {
    setFormData((prev) => ({
      ...prev,
      experience: prev.experience.filter((_, i) => i !== index),
    }));
  };

  const handleEducationChange = (index, field, value) => {
    const newEducation = [...formData.education];
    if (field === "achievements") {
      newEducation[index][field] = value.split(",").map(a => a.trim());
    } else {
      newEducation[index][field] = value;
    }
    setFormData((prev) => ({
      ...prev,
      education: newEducation,
    }));
  };

  const addEducation = () => {
    setFormData((prev) => ({
      ...prev,
      education: [
        ...prev.education,
        {
          degree: "",
          fieldOfStudy: "",
          school: "",
          location: "",
          startDate: "",
          endDate: "",
          grade: "",
          achievements: []
        },
      ],
    }));
  };

  const removeEducation = (index) => {
    setFormData((prev) => ({
      ...prev,
      education: prev.education.filter((_, i) => i !== index),
    }));
  };

  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!allowedTypes.includes(file.type)) {
      toast.error("Please upload an image file (JPEG, PNG, or GIF)");
      return;
    }

    const maxSize = 5 * 1024 * 1024;
    if (file.size > maxSize) {
      toast.error("File size should be less than 5MB");
      return;
    }

    setIsUploading(true);
    try {
      trackEvent('profile_picture_upload_start', { 
        file_type: file.type,
        file_size: file.size 
      });

      const storageRef = ref(
        storage,
        `profile-images/${user.id}/${file.name}`
      );
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      setFormData((prev) => ({
        ...prev,
        photoURL: downloadURL,
      }));
      trackEvent('profile_picture_upload_success', { 
        file_type: file.type 
      });
      toast.success("Profile picture uploaded successfully!");
    } catch (error) {
      trackEvent('profile_picture_upload_error', { 
        file_type: file.type,
        error: error.message 
      });
      console.error("Error uploading file:", error);
      toast.error("Failed to upload profile picture");
    } finally {
      setIsUploading(false);
    }
  };

  const handleSkillChange = (type, index, value) => {
    setFormData((prev) => ({
      ...prev,
      skills: {
        ...prev.skills,
        [type]: prev.skills?.[type]?.map((skill, i) => 
          i === index ? value : skill
        ) || []
      }
    }));
  };

  const handleAddSkill = (type) => {
    setFormData((prev) => ({
      ...prev,
      skills: {
        ...prev.skills,
        [type]: [...(prev.skills?.[type] || []), ""]
      }
    }));
  };

  const handleRemoveSkill = (type, index) => {
    setFormData((prev) => ({
      ...prev,
      skills: {
        ...prev.skills,
        [type]: prev.skills?.[type]?.filter((_, i) => i !== index) || []
      }
    }));
  };

  const handleCertificationChange = (index, field, value) => {
    setFormData((prev) => ({
      ...prev,
      skills: {
        ...prev.skills,
        certifications: (prev.skills?.certifications || []).map((cert, i) => 
          i === index ? { ...cert, [field]: value } : cert
        )
      }
    }));
  };

  const handleAddCertification = () => {
    setFormData((prev) => ({
      ...prev,
      skills: {
        ...prev.skills,
        certifications: [
          ...(prev.skills?.certifications || []),
          {
            name: "",
            issuer: "",
            issueDate: "",
            expiryDate: "",
            credentialURL: ""
          }
        ]
      }
    }));
  };

  const handleRemoveCertification = (index) => {
    setFormData((prev) => ({
      ...prev,
      skills: {
        ...prev.skills,
        certifications: (prev.skills?.certifications || []).filter((_, i) => i !== index)
      }
    }));
  };

  const handleAddProject = () => {
    setFormData((prev) => ({
      ...prev,
      projects: [
        ...prev.projects,
        {
          name: "",
          description: "",
          technologies: [],
          url: "",
          startDate: "",
          endDate: "",
          highlights: [],
          type: "", // Personal, Professional, Academic
        },
      ],
    }));
  };

  const handleRemoveProject = (index) => {
    setFormData((prev) => ({
      ...prev,
      projects: prev.projects.filter((_, i) => i !== index),
    }));
  };

  const handleProjectChange = (index, field, value) => {
    const newProjects = [...formData.projects];
    if (field === "technologies" || field === "highlights") {
      newProjects[index][field] = value.split(",").map(item => item.trim());
    } else {
      newProjects[index][field] = value;
    }
    setFormData(prev => ({
      ...prev,
      projects: newProjects
    }));
  };

  return (
    <>
      <Helmet>
        <title>Edit Profile | AppointTalent - Customize Your Professional Profile</title>
        <meta name="description" content="Edit your professional profile on AppointTalent. Update your information to attract potential employers." />
        <meta name="keywords" content="edit profile, professional profile, appointtalent, update information" />
      </Helmet>
      <div className="edit-profile-container">
        <Container>
          <div className="edit-profile-form">
            <h2 className="edit-profile-title">Edit Profile</h2>
            <Form onSubmit={handleSubmit}>
              {/* Profile Picture Section */}
              <div className="profile-picture-section">
                <div className="profile-picture-container">
                  {formData.photoURL ? (
                    <img
                      src={formData.photoURL}
                      alt="Profile"
                      className="profile-picture"
                    />
                  ) : (
                    <div className="profile-picture-placeholder">
                      {formData.displayName
                        ? formData.displayName[0].toUpperCase()
                        : "U"}
                    </div>
                  )}
                  <div
                    className="upload-overlay"
                    onClick={() => fileInputRef.current.click()}
                  >
                    <FiUpload />
                    <span>Upload</span>
                  </div>
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handlePhotoUpload}
                  style={{ display: "none" }}
                  accept="image/*"
                />
              </div>

              <div className="tabs-container">
                {/* Vertical Tabs */}
                <div className="vertical-tabs">
                  <button
                    type="button"
                    className={`tab-button ${
                      activeTab === "basic" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("basic")}
                  >
                    <FiUser />
                    Basic Information
                  </button>
                  <button
                    type="button"
                    className={`tab-button ${
                      activeTab === "experience" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("experience")}
                  >
                    <FiBriefcase />
                    Experience
                  </button>
                  <button
                    type="button"
                    className={`tab-button ${
                      activeTab === "education" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("education")}
                  >
                    <FiBook />
                    Education
                  </button>
                  <button
                    type="button"
                    className={`tab-button ${
                      activeTab === "skills" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("skills")}
                  >
                    <FiAward />
                    Skills & Certifications
                  </button>
                  <button
                    type="button"
                    className={`tab-button ${
                      activeTab === "projects" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("projects")}
                  >
                    <FiCode />
                    Projects
                  </button>
                  <button
                    type="button"
                    className={`tab-button ${
                      activeTab === "preferences" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("preferences")}
                  >
                    <FiTarget />
                    Career Preferences
                  </button>
                </div>

                {/* Tab Content */}
                <div className="tab-content">
                  {activeTab === "basic" && (
                    <>
                      <Form.Group className="mb-3">
                        <Form.Label>Full Name*</Form.Label>
                        <Form.Control
                          type="text"
                          value={formData.displayName}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              displayName: e.target.value,
                            })
                          }
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Bio</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          value={formData.bio}
                          onChange={(e) =>
                            setFormData({ ...formData, bio: e.target.value })
                          }
                          placeholder="Tell us about yourself..."
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Email*</Form.Label>
                        <Form.Control
                          type="email"
                          value={formData.email}
                          disabled
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="tel"
                          value={formData.phone}
                          onChange={(e) =>
                            setFormData({ ...formData, phone: e.target.value })
                          }
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                          type="text"
                          value={formData.location}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              location: e.target.value,
                            })
                          }
                          placeholder="City, Country"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>LinkedIn Profile</Form.Label>
                        <Form.Control
                          type="url"
                          value={formData.linkedIn}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              linkedIn: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Portfolio</Form.Label>
                        <Form.Control
                          type="url"
                          value={formData.portfolio}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              portfolio: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Resume</Form.Label>
                        <Form.Control
                          type="url"
                          value={formData.resume}
                          onChange={(e) =>
                            setFormData({ ...formData, resume: e.target.value })
                          }
                        />
                      </Form.Group>
                    </>
                  )}

                  {activeTab === "experience" && (
                    <>
                      {formData.experience.map((exp, index) => (
                        <div key={index} className="experience-item mb-4">
                          <div className="item-header">
                            <h6>Experience #{index + 1}</h6>
                            {index > 0 && (
                              <button
                                type="button"
                                className="remove-button"
                                onClick={() => removeExperience(index)}
                              >
                                Remove
                              </button>
                            )}
                          </div>
                          <Form.Group className="mb-3">
                            <Form.Label>Job Title</Form.Label>
                            <Form.Control
                              type="text"
                              value={exp.title}
                              onChange={(e) =>
                                handleExperienceChange(index, "title", e.target.value)
                              }
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Company</Form.Label>
                            <Form.Control
                              type="text"
                              value={exp.company}
                              onChange={(e) =>
                                handleExperienceChange(index, "company", e.target.value)
                              }
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Location</Form.Label>
                            <Form.Control
                              type="text"
                              value={exp.location}
                              onChange={(e) =>
                                handleExperienceChange(index, "location", e.target.value)
                              }
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={exp.description}
                              onChange={(e) =>
                                handleExperienceChange(index, "description", e.target.value)
                              }
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Key Achievements (comma-separated)</Form.Label>
                            <Form.Control
                              type="text"
                              value={exp.keyAchievements.join(", ")}
                              onChange={(e) =>
                                handleExperienceChange(
                                  index,
                                  "keyAchievements",
                                  e.target.value.split(",").map((a) => a.trim())
                                )
                              }
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Skills Used (comma-separated)</Form.Label>
                            <Form.Control
                              type="text"
                              value={exp.skills.join(", ")}
                              onChange={(e) =>
                                handleExperienceChange(
                                  index,
                                  "skills",
                                  e.target.value.split(",").map((s) => s.trim())
                                )
                              }
                            />
                          </Form.Group>
                        </div>
                      ))}
                      <Button
                        type="button"
                        variant="outline-primary"
                        onClick={addExperience}
                        className="w-100"
                      >
                        + Add Experience
                      </Button>
                    </>
                  )}

                  {activeTab === "education" && (
                    <>
                      {formData.education.map((edu, index) => (
                        <div key={index} className="education-item mb-4">
                          <div className="item-header">
                            <h6>Education #{index + 1}</h6>
                            {index > 0 && (
                              <button
                                type="button"
                                className="remove-button"
                                onClick={() => removeEducation(index)}
                              >
                                Remove
                              </button>
                            )}
                          </div>
                          <Form.Group className="mb-3">
                            <Form.Label>Degree</Form.Label>
                            <Form.Control
                              type="text"
                              value={edu.degree}
                              onChange={(e) =>
                                handleEducationChange(index, "degree", e.target.value)
                              }
                              placeholder="e.g., Bachelor of Science"
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Field of Study</Form.Label>
                            <Form.Control
                              type="text"
                              value={edu.fieldOfStudy}
                              onChange={(e) =>
                                handleEducationChange(index, "fieldOfStudy", e.target.value)
                              }
                              placeholder="e.g., Computer Science"
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>School/University</Form.Label>
                            <Form.Control
                              type="text"
                              value={edu.school}
                              onChange={(e) =>
                                handleEducationChange(index, "school", e.target.value)
                              }
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Location</Form.Label>
                            <Form.Control
                              type="text"
                              value={edu.location}
                              onChange={(e) =>
                                handleEducationChange(index, "location", e.target.value)
                              }
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                              type="date"
                              value={edu.startDate}
                              onChange={(e) =>
                                handleEducationChange(index, "startDate", e.target.value)
                              }
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control
                              type="date"
                              value={edu.endDate}
                              onChange={(e) =>
                                handleEducationChange(index, "endDate", e.target.value)
                              }
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Grade</Form.Label>
                            <Form.Control
                              type="text"
                              value={edu.grade}
                              onChange={(e) =>
                                handleEducationChange(index, "grade", e.target.value)
                              }
                              placeholder="e.g., 3.8 GPA"
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Achievements (comma-separated)</Form.Label>
                            <Form.Control
                              type="text"
                              value={edu.achievements.join(", ")}
                              onChange={(e) =>
                                handleEducationChange(
                                  index,
                                  "achievements",
                                  e.target.value.split(",").map(a => a.trim())
                                )
                              }
                            />
                          </Form.Group>
                        </div>
                      ))}
                      <Button
                        type="button"
                        variant="outline-primary"
                        onClick={addEducation}
                        className="w-100"
                      >
                        + Add Education
                      </Button>
                    </>
                  )}

                  {activeTab === "skills" && (
                    <>
                      <div className="mb-4">
                        <h5>Technical Skills</h5>
                        {(formData.skills?.technical || []).map((skill, index) => (
                          <div key={index} className="d-flex align-items-center mb-2">
                            <Form.Control
                              type="text"
                              value={skill}
                              onChange={(e) =>
                                handleSkillChange("technical", index, e.target.value)
                              }
                              placeholder="Add a technical skill"
                            />
                            <Button
                              variant="outline-danger"
                              size="sm"
                              className="ms-2"
                              onClick={() => handleRemoveSkill("technical", index)}
                            >
                              Remove
                            </Button>
                          </div>
                        ))}
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() => handleAddSkill("technical")}
                          className="mt-2"
                        >
                          + Add Technical Skill
                        </Button>
                      </div>

                      <div className="mb-4">
                        <h5>Soft Skills</h5>
                        {(formData.skills?.soft || []).map((skill, index) => (
                          <div key={index} className="d-flex align-items-center mb-2">
                            <Form.Control
                              type="text"
                              value={skill}
                              onChange={(e) =>
                                handleSkillChange("soft", index, e.target.value)
                              }
                              placeholder="Add a soft skill"
                            />
                            <Button
                              variant="outline-danger"
                              size="sm"
                              className="ms-2"
                              onClick={() => handleRemoveSkill("soft", index)}
                            >
                              Remove
                            </Button>
                          </div>
                        ))}
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() => handleAddSkill("soft")}
                          className="mt-2"
                        >
                          + Add Soft Skill
                        </Button>
                      </div>

                      <div className="mb-4">
                        <h5>Certifications</h5>
                        {(formData.skills?.certifications || []).map((cert, index) => (
                          <div key={index} className="certification-item mb-4 p-3 border rounded">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <h6>Certification #{index + 1}</h6>
                              {index > 0 && (
                                <Button
                                  variant="outline-danger"
                                  size="sm"
                                  onClick={() => handleRemoveCertification(index)}
                                >
                                  Remove
                                </Button>
                              )}
                            </div>
                            <Form.Group className="mb-3">
                              <Form.Label>Certification Name</Form.Label>
                              <Form.Control
                                type="text"
                                value={cert.name || ""}
                                onChange={(e) =>
                                  handleCertificationChange(index, "name", e.target.value)
                                }
                                placeholder="e.g., AWS Solutions Architect"
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Issuing Organization</Form.Label>
                              <Form.Control
                                type="text"
                                value={cert.issuer || ""}
                                onChange={(e) =>
                                  handleCertificationChange(index, "issuer", e.target.value)
                                }
                                placeholder="e.g., Amazon Web Services"
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Issue Date</Form.Label>
                              <Form.Control
                                type="date"
                                value={cert.issueDate || ""}
                                onChange={(e) =>
                                  handleCertificationChange(index, "issueDate", e.target.value)
                                }
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Expiry Date (Optional)</Form.Label>
                              <Form.Control
                                type="date"
                                value={cert.expiryDate || ""}
                                onChange={(e) =>
                                  handleCertificationChange(index, "expiryDate", e.target.value)
                                }
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Credential URL</Form.Label>
                              <Form.Control
                                type="url"
                                value={cert.credentialURL || ""}
                                onChange={(e) =>
                                  handleCertificationChange(index, "credentialURL", e.target.value)
                                }
                                placeholder="https://..."
                              />
                            </Form.Group>
                          </div>
                        ))}
                        <Button
                          variant="outline-primary"
                          onClick={handleAddCertification}
                          className="w-100"
                        >
                          + Add Certification
                        </Button>
                      </div>
                    </>
                  )}

                  {activeTab === "projects" && (
                    <>
                      {formData.projects.map((project, index) => (
                        <div key={index} className="project-item mb-4">
                          <div className="item-header">
                            <h6>Project #{index + 1}</h6>
                            {index > 0 && (
                              <button
                                type="button"
                                className="remove-button"
                                onClick={() => handleRemoveProject(index)}
                              >
                                Remove
                              </button>
                            )}
                          </div>
                          <Form.Group className="mb-3">
                            <Form.Label>Project Name</Form.Label>
                            <Form.Control
                              type="text"
                              value={project.name}
                              onChange={(e) =>
                                handleProjectChange(index, "name", e.target.value)
                              }
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={project.description}
                              onChange={(e) =>
                                handleProjectChange(index, "description", e.target.value)
                              }
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Technologies Used (comma-separated)</Form.Label>
                            <Form.Control
                              type="text"
                              value={project.technologies.join(", ")}
                              onChange={(e) =>
                                handleProjectChange(
                                  index,
                                  "technologies",
                                  e.target.value
                                )
                              }
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Project URL</Form.Label>
                            <Form.Control
                              type="url"
                              value={project.url}
                              onChange={(e) =>
                                handleProjectChange(index, "url", e.target.value)
                              }
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Highlights (comma-separated)</Form.Label>
                            <Form.Control
                              type="text"
                              value={project.highlights.join(", ")}
                              onChange={(e) =>
                                handleProjectChange(
                                  index,
                                  "highlights",
                                  e.target.value
                                )
                              }
                            />
                          </Form.Group>
                        </div>
                      ))}
                      <Button
                        type="button"
                        variant="outline-primary"
                        onClick={handleAddProject}
                        className="w-100"
                      >
                        + Add Project
                      </Button>
                    </>
                  )}

                  {activeTab === "preferences" && (
                    <>
                      <Form.Group className="mb-3">
                        <Form.Label>Desired Role</Form.Label>
                        <Form.Control
                          type="text"
                          value={formData.careerPreferences.desiredRole}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              careerPreferences: {
                                ...formData.careerPreferences,
                                desiredRole: e.target.value,
                              },
                            })
                          }
                          placeholder="e.g., Senior Software Engineer"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Desired Salary (Annual)</Form.Label>
                        <Form.Control
                          type="text"
                          value={formData.careerPreferences.desiredSalary}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              careerPreferences: {
                                ...formData.careerPreferences,
                                desiredSalary: e.target.value,
                              },
                            })
                          }
                          placeholder="e.g., $100,000"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Job Type</Form.Label>
                        <Form.Select
                          value={formData.careerPreferences.jobType}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              careerPreferences: {
                                ...formData.careerPreferences,
                                jobType: e.target.value,
                              },
                            })
                          }
                        >
                          <option value="">Select job type</option>
                          <option value="Full-time">Full-time</option>
                          <option value="Part-time">Part-time</option>
                          <option value="Contract">Contract</option>
                          <option value="Freelance">Freelance</option>
                          <option value="Internship">Internship</option>
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Work Location</Form.Label>
                        <Form.Select
                          value={formData.careerPreferences.workLocation}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              careerPreferences: {
                                ...formData.careerPreferences,
                                workLocation: e.target.value,
                              },
                            })
                          }
                        >
                          <option value="">Select work location</option>
                          <option value="Remote">Remote</option>
                          <option value="Hybrid">Hybrid</option>
                          <option value="On-site">On-site</option>
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Check
                          type="checkbox"
                          label="Willing to relocate"
                          checked={formData.careerPreferences.willingToRelocate}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              careerPreferences: {
                                ...formData.careerPreferences,
                                willingToRelocate: e.target.checked,
                              },
                            })
                          }
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Available From</Form.Label>
                        <Form.Control
                          type="date"
                          value={formData.careerPreferences.availableFrom}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              careerPreferences: {
                                ...formData.careerPreferences,
                                availableFrom: e.target.value,
                              },
                            })
                          }
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Preferred Industries (comma-separated)
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={formData.careerPreferences.preferredIndustries.join(
                            ", "
                          )}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              careerPreferences: {
                                ...formData.careerPreferences,
                                preferredIndustries: e.target.value
                                  .split(",")
                                  .map((i) => i.trim()),
                              },
                            })
                          }
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Years of Experience</Form.Label>
                        <Form.Control
                          type="number"
                          value={formData.careerPreferences.yearsOfExperience}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              careerPreferences: {
                                ...formData.careerPreferences,
                                yearsOfExperience: e.target.value,
                              },
                            })
                          }
                        />
                      </Form.Group>
                    </>
                  )}
                </div>
              </div>

              <div className="form-actions">
                <Button
                  variant="outline-danger"
                  // className="cancel-button"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  className="btn-primary"
                >
                  Save Changes
                </Button>
              </div>
            </Form>
          </div>
        </Container>
      </div>
    </>
  );
};

export default EditProfile;

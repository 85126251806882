import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { LuSearch } from "react-icons/lu";
import { FaCode, FaChartLine, FaUserTie } from "react-icons/fa";
import "./styles.css";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";

const popularRoles = [
  "Software Engineer",
  "Data Scientist",
  "DevOps Engineer",
  "Product Manager",
  "UI/UX Designer",
  "Full Stack Developer",
  "Cloud Architect",
  "AI Engineer"
];

const salaryData = [
  {
    role: "Software Engineer",
    salaryRange: "$80,000 - $150,000",
    experience: "2-5 years",
    demand: "High",
    skills: ["JavaScript", "Python", "React", "Node.js", "AWS"],
    description: "Design, develop and maintain software applications and systems...",
    image: "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-4.0.3"
  },
  {
    role: "Data Scientist",
    salaryRange: "$90,000 - $160,000",
    experience: "3-6 years",
    demand: "Very High",
    skills: ["Python", "R", "SQL", "Machine Learning", "Statistics"],
    description: "Analyze complex data sets to help guide business decisions...",
    image: "https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-4.0.3"
  },
  {
    role: "DevOps Engineer",
    salaryRange: "$85,000 - $155,000",
    experience: "3-5 years",
    demand: "High",
    skills: ["Docker", "Kubernetes", "CI/CD", "AWS", "Linux"],
    description: "Build and maintain infrastructure and deployment pipelines...",
    image: "https://images.unsplash.com/photo-1667372393119-3d4c48d07fc9?ixlib=rb-4.0.3"
  },
  {
    role: "Product Manager",
    salaryRange: "$95,000 - $170,000",
    experience: "4-7 years",
    demand: "High",
    skills: ["Product Strategy", "Agile", "User Research", "Analytics", "Leadership"],
    description: "Lead product development and strategy to drive business growth...",
    image: "https://images.unsplash.com/photo-1553877522-43269d4ea984?ixlib=rb-4.0.3"
  },
  {
    role: "UI/UX Designer",
    salaryRange: "$75,000 - $140,000",
    experience: "2-5 years",
    demand: "Medium",
    skills: ["Figma", "Adobe XD", "User Research", "Prototyping", "Visual Design"],
    description: "Create intuitive and engaging user experiences for digital products...",
    image: "https://images.unsplash.com/photo-1586717791821-3f44a563fa4c?ixlib=rb-4.0.3"
  },
  {
    role: "Full Stack Developer",
    salaryRange: "$85,000 - $160,000",
    experience: "3-6 years",
    demand: "Very High",
    skills: ["JavaScript", "React", "Node.js", "MongoDB", "AWS"],
    description: "Build end-to-end web applications and services...",
    image: "https://images.unsplash.com/photo-1571171637578-41bc2dd41cd2?ixlib=rb-4.0.3"
  }
];

const SalaryGuidePage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRole, setSelectedRole] = useState("All");

  const filteredRoles = salaryData.filter((role) => {
    const matchesSearch = 
      role.role.toLowerCase().includes(searchTerm.toLowerCase()) ||
      role.skills.some(skill => skill.toLowerCase().includes(searchTerm.toLowerCase()));
    
    const matchesRole = selectedRole === "All" || role.role === selectedRole;
    
    return matchesSearch && matchesRole;
  });

  return (
    <div className="salary-guide-page">
      <Helmet>
        <title>Salary Guide | AppointTalent - Know Your Worth</title>
        <meta name="description" content="Explore salary ranges for various roles in the tech industry with AppointTalent's Salary Guide. Know your worth and plan your career." />
        <meta name="keywords" content="salary guide, salary ranges, tech industry, appointtalent, career planning" />
      </Helmet>
      <div className="salary-hero">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} className="text-center">
              <h1>IT Salary Guide</h1>
              <p>Explore competitive salary ranges and in-demand skills for top IT roles</p>
              <div className="search-container">
                <LuSearch className="search-icon" />
                <input
                  type="text"
                  placeholder="Search by role or skill..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="mt-5">
        <Row>
          <Col md={12} className="mb-4">
            <div className="popular-searches">
              <button
                className={`search-tag ${selectedRole === "All" ? "active" : ""}`}
                onClick={() => setSelectedRole("All")}
              >
                All Roles
              </button>
              {popularRoles.map((role, index) => (
                <button
                  key={index}
                  className={`search-tag ${selectedRole === role ? "active" : ""}`}
                  onClick={() => setSelectedRole(role)}
                >
                  {role}
                </button>
              ))}
            </div>
          </Col>
        </Row>

        <Row>
          {filteredRoles.map((role, index) => (
            <Col md={6} lg={4} key={index} className="mb-4">
              <Card className="role-card">
                <div className="role-image">
                  <img src={role.image} alt={role.role} />
                  <span className={`demand-badge ${role.demand.toLowerCase().replace(" ", "-")}`}>
                    {role.demand} Demand
                  </span>
                </div>
                <Card.Body>
                  <h5 className="role-title">{role.role}</h5>
                  <div className="salary-range">
                    <FaChartLine />
                    <span>{role.salaryRange}</span>
                  </div>
                  <div className="experience">
                    <FaUserTie />
                    <span>{role.experience} Experience</span>
                  </div>
                  <p className="role-description">{role.description}</p>
                  <div className="skills-container">
                    <h6>Key Skills:</h6>
                    <div className="skills-list">
                      {role.skills.map((skill, idx) => (
                        <span key={idx} className="skill-tag">
                          <FaCode /> {skill}
                        </span>
                      ))}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {/* <Row className="mt-4">
          <Col md={12}>
            <div className="salary-calculator">
              <h3>Salary Calculator</h3>
              <p>Get a personalized salary estimate based on your skills and experience</p>
              <button className="calculator-btn">Calculate Your Worth</button>
            </div>
          </Col>
        </Row> */}
      </Container>
      <Footer />
    </div>
  );
};

export default SalaryGuidePage;

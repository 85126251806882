import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Badge, Alert } from "react-bootstrap";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../../context/AuthContext";
import { Helmet } from "react-helmet";
import { formatDistanceToNow } from "date-fns";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import "./styles.css";
import { trackEvent } from "../../utils/firebase";
import { useNavigate } from "react-router-dom";

const Applications = () => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user: currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    trackEvent('page_view', { page_name: 'applications' });
    if (currentUser) {
      fetchApplications();
    }
  }, [currentUser]);

  const fetchApplications = async () => {
    try {
      const applicationsRef = collection(db, "applications");
      const q = query(
        applicationsRef,
        where("userId", "==", currentUser.uid),
        orderBy("appliedAt", "desc")
      );
      const querySnapshot = await getDocs(q);

      const applicationsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        appliedAt: doc.data().appliedAt?.toDate(),
      }));

      // Sort by most recent first
      applicationsData.sort((a, b) => b.appliedAt - a.appliedAt);
      setApplications(applicationsData);
      trackEvent('applications_loaded', { 
        count: applicationsData.length 
      });
    } catch (error) {
      console.error("Error fetching applications:", error);
      trackEvent('applications_load_error', { 
        error: error.message 
      });
    } finally {
      setLoading(false);
    }
  };

  const getStatusBadgeVariant = (status) => {
    switch (status.toLowerCase()) {
      case "pending":
        return "warning";
      case "accepted":
        return "success";
      case "rejected":
        return "danger";
      case "interview":
        return "info";
      default:
        return "secondary";
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <Navbar />
      <Container className="py-5">
        <Helmet>
          <title>My Applications - JobFinder</title>
        </Helmet>
        <br />
        <br />
        <br />

        <h1 className="mb-4">My Applications</h1>

        {applications.length > 0 && (
          <Alert variant="info" className="applications-alert">
            <div className="d-flex align-items-center">
              <div className="application-status-icon">
                <i className="fas fa-clock"></i>
              </div>
              <div>
                <h5 className="alert-heading mb-1">Applications in Process</h5>
                <p className="mb-0">
                  Your applications are being reviewed. We'll notify you of any
                  updates.
                </p>
              </div>
            </div>
          </Alert>
        )}

        <Row>
          {applications.length === 0 ? (
            <Col>
              <Card className="text-center p-5">
                <Card.Body>
                  <h3>No Applications Yet</h3>
                  <p className="text-muted">
                    You haven't applied to any jobs yet. Start exploring
                    opportunities!
                  </p>
                </Card.Body>
              </Card>
            </Col>
          ) : (
            applications.map((application) => (
              <Col md={12} className="mb-4" key={application.id}>
                <Card className="application-card">
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <h4 className="job-title mb-2">
                          {application.jobTitle}
                        </h4>
                        <h5 className="company-name text-muted mb-3">
                          {application.company}
                        </h5>
                      </div>
                      <Badge
                        bg={getStatusBadgeVariant(application.status)}
                        className="status-badge"
                      >
                        {application.status.charAt(0).toUpperCase() +
                          application.status.slice(1)}
                      </Badge>
                    </div>

                    <div className="application-details">
                      <div className="detail-item">
                        <i className="far fa-calendar-alt"></i>
                        Applied {formatDistanceToNow(application.appliedAt)} ago
                      </div>
                      {application.userProfile?.resume && (
                        <div className="detail-item">
                          <i className="far fa-file-alt"></i>
                          Resume Submitted
                        </div>
                      )}
                    </div>

                    <div className="status-timeline mt-4">
                      <div
                        className={`timeline-item ${
                          application.status === "pending" ? "active" : ""
                        }`}
                      >
                        <div className="timeline-point"></div>
                        <div className="timeline-content">
                          <h6>Application Submitted</h6>
                          <small>
                            {formatDistanceToNow(application.appliedAt)} ago
                          </small>
                        </div>
                      </div>
                      <div
                        className={`timeline-item ${
                          application.status === "review" ? "active" : ""
                        }`}
                      >
                        <div className="timeline-point"></div>
                        <div className="timeline-content">
                          <h6>Under Review</h6>
                          {application.status === "review" && (
                            <small>Currently being reviewed</small>
                          )}
                        </div>
                      </div>
                      <div
                        className={`timeline-item ${
                          application.status === "interview" ? "active" : ""
                        }`}
                      >
                        <div className="timeline-point"></div>
                        <div className="timeline-content">
                          <h6>Interview Stage</h6>
                          {application.status === "interview" && (
                            <small>Scheduled for interview</small>
                          )}
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))
          )}
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Applications;

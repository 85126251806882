import {
  collection,
  getDocs,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
  getDoc,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../firebase";
// import { db } from '../config/firebase';

export const getjobs = async (props) => {
  try {
    const jobsCollection = collection(db, "jobs");
    const q = query(jobsCollection, orderBy("createdAt", "desc")); // Order by 'createdAt' field
    const querySnapshot = await getDocs(q);
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
    console.log("====datadata=======>", data);
    return data;
  } catch (error) {
    console.log("Error fetching jobs: ", error);
    throw error;
  }
};

export const jobdetails = async (id) => {
  try {
    const docRef = doc(db, "jobs", id);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      const jobData = { id: docSnapshot.id, ...docSnapshot.data() };
      console.log("Job data:", jobData);
      return jobData;
    } else {
      console.log("No such document!");
      return null; // or throw an error if necessary
    }
  } catch (error) {
    console.log("Error fetching job: ", error);
    throw error;
  }
};

export const addData = async (collectionName, data) => {
  try {
    // Add a new document with a generated ID
    const docRef = await addDoc(collection(db, collectionName), {
      ...data,
      createdAt: serverTimestamp(), // Optional: Add a timestamp field
    });
    console.log("Document written with ID: ", docRef.id);
    return docRef.id;
  } catch (error) {
    console.error("Error adding document: ", error);
    throw error;
  }
};

import React, { useEffect, useState, useContext } from "react";
// import Loading from "./Loading";
// import Newsitems from "./Newsitems";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import JobItem from "../../components/JobItem";
import Loading from "../../components/Loading";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Filter from "../../components/Filter";
import "../../styles/home.css";
import { getjobs } from "../../apis";
import { DataContext } from "../../context/DataContext";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import { useData } from '../../context/DataContext';

const Scholorships = (props) => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalResults, setTotalResults] = useState(0);
  const { jobs, setjobs } = useData();

  useEffect(() => {
    fecthJobs();
  }, []);

  const fecthJobs = async () => {
    try {
      const data = await getjobs();
      console.log("========:", data);
      setjobs(data); // Assuming data.data contains the jobs array
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error fetching jobs:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Top Scholarships | Discover Scholarship Opportunities on AppointTalent
        </title>
        <meta
          name="description"
          content="Find and apply for top scholarships to fund your education. Explore various scholarship opportunities on AppointTalent."
        />
        <meta
          name="keywords"
          content="scholarships, scholarship opportunities, apply for scholarships, education funding, scholarship search, financial aid, college scholarships, university scholarships, graduate scholarships, international scholarships, scholarship programs"
        />
      </Helmet>
      <h1>Top Scholarships</h1>

      {loading ? (
        <div className="_loading_view">
          <Loading />
        </div>
      ) : (
        <>
          <Filter />
          <Container className="_main_section">
            <Row className="justify-content-center mt-10">
              <Col lg="8" md="10" sm="12" xs="12">
                <InfiniteScroll
                  dataLength={articles?.length}
                  // next={fetchMoreData}
                  hasMore={articles?.length !== totalResults}
                  loader={<Loading />}
                >
                  {jobs ? (
                    jobs.map((element, index) => {
                      return <JobItem data={element} key={index} />;
                    })
                  ) : (
                    <div className="_job_not_found">Job Not Found</div>
                  )}
                </InfiniteScroll>
              </Col>
            </Row>
          </Container>
        </>
      )}
      <br />
      <br />
      <Footer />
    </>
  );
};

export default Scholorships;

Scholorships.defaultProps = {
  country: "in",
  pageSize: 16,
  category: "general",
};

Scholorships.propTypes = {
  country: PropTypes.string,
  pageSize: PropTypes.number,
  category: PropTypes.string,
};

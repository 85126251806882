import React, { useState, useEffect } from "react";
import "../styles/home.css";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { MdShare, MdAccessTime } from "react-icons/md";
import { HiOutlineBookmark, HiBookmark, HiArrowRight } from "react-icons/hi2";
import { BsClock } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import { toast } from "react-toastify";

import {
  doc,
  updateDoc,
  getDoc,
  arrayUnion,
  arrayRemove,
  setDoc,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import { useSavedJobs } from "../context/SavedJobsContext";
import { Button } from "react-bootstrap";

const JobItem = ({ data, onRemove }) => {
  const navigate = useNavigate();
  const [imageError, setImageError] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [profileCompletion, setProfileCompletion] = useState(0);
  const { currentUser } = auth;
  const { savedJobIds, addSavedJob, removeSavedJob } = useSavedJobs();

  const {
    id,
    title,
    description,
    employmentType,
    jobLocationType,
    applicantLocationRequirements,
    baseSalary,
    datePosted,
    hiringOrganization,
    skills,
  } = data || {};

  useEffect(() => {
    if (currentUser && id) {
      setIsBookmarked(savedJobIds.includes(id));
      fetchUserProfile();
    }
  }, [currentUser, id, savedJobIds]);

  const fetchUserProfile = async () => {
    try {
      const userDoc = await getDoc(doc(db, "users", currentUser.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserProfile(userData);
        calculateProfileCompletion(userData);
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const calculateProfileCompletion = (userData) => {
    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "phone",
      "address",
      "education",
      "experience",
      "skills",
      "resume",
    ];

    const completedFields = requiredFields.filter((field) => {
      if (Array.isArray(userData[field])) {
        return userData[field].length > 0;
      }
      return userData[field] && userData[field].trim() !== "";
    });

    const completion = (completedFields.length / requiredFields.length) * 100;
    setProfileCompletion(Math.round(completion));
  };

  const handleShare = async () => {
    try {
      const shareData = {
        title: title,
        text: `Check out this job: ${title} at ${hiringOrganization?.name}`,
        url: window.location.origin + `/jobs/${id}`,
      };

      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        await navigator.clipboard.writeText(shareData.url);
        toast.success("Link copied to clipboard!");
      }
    } catch (error) {
      console.error("Error sharing:", error);
      toast.error("Failed to share");
    }
  };

  const handleBookmark = async () => {
    if (!currentUser) {
      toast.error("Please login to save jobs");
      return;
    }

    try {
      const userRef = doc(db, "users", currentUser.uid);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        toast.error("User profile not found");
        return;
      }

      const savedJobs = userDoc.data()?.savedJobs || [];
      
      if (isBookmarked) {
        // Remove from bookmarks
        await updateDoc(userRef, {
          savedJobs: arrayRemove(id)
        });
        setIsBookmarked(false);
        removeSavedJob(id);
        if (onRemove) {
          onRemove(id);
        }
        toast.success("Job removed from saved jobs");
      } else {
        // Add to bookmarks
        await updateDoc(userRef, {
          savedJobs: arrayUnion(id)
        });
        setIsBookmarked(true);
        addSavedJob(id);
        toast.success("Job saved successfully");
      }
    } catch (error) {
      console.error("Error updating bookmark:", error);
      toast.error("Failed to update saved jobs");
    }
  };

  const handleApply = async () => {
    if (!currentUser) {
      toast.error("Please login to apply for jobs");
      return;
    }

    if (userProfile?.role !== "jobseeker") {
      toast.error("Only job seekers can apply for jobs");
      return;
    }

    if (profileCompletion < 80) {
      toast.warning(
        "Please complete your profile (minimum 80%) before applying"
      );
      navigate("/profile");
      return;
    }

    // Add application logic here
    try {
      const applicationRef = doc(
        db,
        "applications",
        `${currentUser.uid}_${id}`
      );
      await setDoc(applicationRef, {
        userId: currentUser.uid,
        jobId: id,
        status: "pending",
        appliedAt: new Date().toISOString(),
        jobTitle: title,
        company: hiringOrganization?.name,
      });
      toast.success("Application submitted successfully!");
    } catch (error) {
      console.error("Error applying for job:", error);
      toast.error("Failed to submit application");
    }
  };

  const formatLocation = () => {
    if (!applicantLocationRequirements) return "Remote";
    const { city, region, country } = applicantLocationRequirements;
    return [city, region, country].filter(Boolean).join(", ");
  };

  const formatSalary = () => {
    if (!baseSalary) return "";
    const { currency, minValue, maxValue } = baseSalary;
    if (!minValue && !maxValue) return "";

    const formatValue = (value) => {
      const num = parseInt(value);
      return num >= 1000 ? `${Math.round(num / 1000)}K` : num;
    };

    if (minValue && maxValue) {
      return `${currency} ${formatValue(minValue)}-${formatValue(maxValue)}`;
    }
    return `${currency} ${formatValue(minValue || maxValue)}`;
  };

  const truncateDescription = (text) => {
    if (!text) return "";
    const words = text.split(" ").slice(0, 20); // Get first 20 words
    return words.join(" ") + (text.split(" ").length > 20 ? "..." : "");
  };

  const parseSkills = () => {
    if (!skills) return [];
    return skills
      .split("\n")
      .map((skill) => skill.replace("•", "").trim())
      .filter(Boolean)
      .slice(0, 3); // Show only first 3 skills
  };

  const handleCardClick = () => {
    navigate(`/jobs/${id}`);
  };

  const getCompanyInitial = () => {
    return hiringOrganization?.name
      ? hiringOrganization.name.charAt(0).toUpperCase()
      : "C";
  };

  const handleImageError = () => {
    setImageError(true);
  };

  const shouldShowInitial = !hiringOrganization?.logo || imageError;


  return (
    <Card className="modern-job-card">
      <div className="job-card-header">
        <div className="job-header-left">
          <div className="company-logo">
            {!shouldShowInitial ? (
              <img
                src={hiringOrganization.logo}
                alt={hiringOrganization.name}
                onError={handleImageError}
              />
            ) : (
              <div
                className="company-initial"
                style={{
                  background: `hsl(${Math.random() * 360}, 70%, 90%)`,
                  color: `hsl(${Math.random() * 360}, 70%, 30%)`,
                }}
              >
                {getCompanyInitial()}
              </div>
            )}
          </div>
          <div className="job-title-section">
            <div className="title-row">
              <h3>{title}</h3>
              {moment(datePosted).isAfter(moment().subtract(7, "days")) && (
                <span className="new-badge">New</span>
              )}
              {jobLocationType && (
                <span className="job-type-badge">{jobLocationType}</span>
              )}
            </div>
            <div className="company-name">
              <span>{hiringOrganization?.name}</span>
            </div>
          </div>
        </div>
        <div className="job-header-right">
          <div className="salary">{formatSalary()}</div>
          {/* <div className="action-buttons">
            <button
              className="icon-button share-button"
              onClick={(e) => {
                e.stopPropagation();
                handleShare();
              }}
              title="Share job"
            >
              <MdShare size={20} />
            </button>
            <button
              className={`icon-button bookmark-button ${isBookmarked ? "active" : ""}`}
              onClick={(e) => {
                e.stopPropagation();
                handleBookmark();
              }}
              title={isBookmarked ? "Remove from saved jobs" : "Save job"}
            >
              {isBookmarked ? (
                <HiBookmark size={20} className="filled" style={{color: "#ef4444"}} />
              ) : (
                <HiOutlineBookmark size={20} style={{color: "#6b7280"}} />
              )}
            </button>
            <button className="view-job-btn" onClick={handleCardClick}>
              View job
              <HiArrowRight size={18} />
            </button>
          </div> */}
        </div>
      </div>

      <div className="job-card-body">
        <div className="job-meta-info">
          <div className="meta-item">
            <IoLocationOutline />
            <span>{formatLocation()}</span>
          </div>
          <div className="meta-item">
            <BsClock />
            <span>{employmentType?.replace("_", " ") || "Full Time"}</span>
          </div>
          <div className="meta-item">
            <MdAccessTime />
            <span>40 hrs/week</span>
          </div>
        </div>

        <div className="skills-section">
          {parseSkills().length > 0 && (
            <div className="skills-match">
              <span className="match-label">You match!</span>
              {parseSkills().map((skill, index) => (
                <span key={index} className="skill-tag">
                  {skill}
                </span>
              ))}
            </div>
          )}
        </div>

        <div className="job-description">
          <h4>What you'll be working on</h4>
          <p>{truncateDescription(description)}</p>
        </div>
      </div>
      <div className="card-actions mt-3">
        {/* <button className="primary" variant="primary">
          View Details & Apply
        </button> */}
        <Button
          type="button"
          variant="primary"
          className="btn-primary"
          onClick={() => navigate(`/jobs/${id}`)}
        >
          View Details & Apply
        </Button>
        {/* {userProfile?.role === 'jobseeker' && (
          <button
            className="secondary-button ms-2"
            onClick={handleApply}
            title={profileCompletion < 80 ? "Complete your profile to apply" : "kj"}
          >
            Apply Now
            {profileCompletion < 80 && <span className="ms-1">({profileCompletion}%)</span>}
          </button>
        )} */}
        <div className="action-buttons">
          <button
            className="icon-button share-button"
            onClick={(e) => {
              e.stopPropagation();
              handleShare();
            }}
            title="Share job"
          >
            <MdShare size={20} />
          </button>
          <button
            className={`icon-button bookmark-button ${
              isBookmarked ? "active" : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
              handleBookmark();
            }}
            title={isBookmarked ? "Remove from saved jobs" : "Save job"}
          >
            {isBookmarked ? (
              <HiBookmark
                size={20}
                className="filled"
                style={{ color: "#ef4444" }}
              />
            ) : (
              <HiOutlineBookmark size={20} style={{ color: "#6b7280" }} />
            )}
          </button>
        </div>
      </div>
    </Card>
  );
};

export default JobItem;

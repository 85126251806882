import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaPinterest,
  FaLinkedin,
} from "react-icons/fa";
import "../styles/footer.css";

const Footer = () => {
  return (
    <footer className="footer bg-light">
      <Container fluid style={{ padding: "5%" }}>
        <Row>
          <Col md={3}>
            <h5 className="font-weight-bold">AppointTalent</h5>
            <p>99 10th Drive Sunnyside, NY 11104</p>
            <p>email@findy.com</p>
            <p>315-793-8183</p>
            <div className="social-icons">
              <a href="#">
                <FaFacebook />
              </a>
              <a href="#">
                <FaInstagram />
              </a>
              <a href="#">
                <FaTwitter />
              </a>
              <a href="#">
                <FaPinterest />
              </a>
              <a href="#">
                <FaLinkedin />
              </a>
            </div>
          </Col>

          <Col md={2}>
            <h5 className="font-weight-bold">Job Categories</h5>
            <ul className="list-unstyled">
              <li>
                <a href="/search?q=technology">Technology</a>
              </li>
              <li>
                <a href="/search?q=finance">Finance</a>
              </li>
              <li>
                <a href="/search?q=education">Education</a>
              </li>
              <li>
                <a href="/search?q=marketing">Marketing</a>
              </li>
              <li>
                <a href="/search?q=creative arts">Creative Arts</a>
              </li>
              <li>
                <a href="/search?q=engineering">Engineering</a>
              </li>
            </ul>
          </Col>

        

          <Col md={2}>
            <h5 className="font-weight-bold">Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/jobs">Job Listings</Link>
              </li>
              {/* <li>
                <Link to="/scholorships">Scholarships</Link>
              </li> */}
              <li>
                <a href="#">Career Resources</a>
              </li>
              <li>
                <a href="#">Popular Searches</a>
              </li>
              {/* <li><a href="#">Marketing</a></li> */}
              {/* <li><a href="#">Accounting / Finance</a></li> */}
            </ul>
          </Col>

        

          <Col md={2}>
            <h5 className="font-weight-bold">Company</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </Col>

          <Col md={2}>
            <h5 className="font-weight-bold">Customer Support</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/contact">Help Center</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-of-service">Terms of Service</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <p
        className="text-center"
        style={{ backgroundColor: "#1d2445", padding: "1%", color: "white" }}
      >
        &copy; 2024 powered by CodeNetic
      </p>
    </footer>
  );
};

export default Footer;

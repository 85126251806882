import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import AccordionComponent from "../../components/Accordian";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import "../../styles/privacypolicy.css";

const Faq = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const faqs = [
    {
      title: "How do I create an account on AppointTalent?",
      des: "To create an account, click on the 'Register' button at the top right corner and fill in the required details. Once submitted, you'll receive a confirmation email to activate your account.",
    },
    {
      title: "How can I apply for jobs?",
      des: "Browse through the job listings and click on a job title to view details. If interested, click 'Apply Now' and follow the instructions to submit your application.",
    },
    {
      title: "What if I forget my password?",
      des: "Click on 'Forgot Password' on the login page and enter your registered email address. You'll receive an email with instructions to reset your password.",
    },
    {
      title: "How do I update my profile information?",
      des: "Navigate to your profile page by clicking on your profile icon. Here, you can edit your personal information, upload a new resume, and update your skills.",
    },
    {
      title: "Can I save jobs to apply later?",
      des: "Yes, you can save jobs by clicking the 'Save Job' button on the job listing. You can view your saved jobs in the 'Saved Jobs' section of your profile.",
    },
    {
      title: "How do I post a job on AppointTalent?",
      des: "To post a job, navigate to the 'Post Job' section, fill in the job details, and submit your listing. Your job post will be reviewed and published shortly.",
    },
    {
      title: "How can I view applicants for my job posting?",
      des: "You can view applicants by navigating to your job dashboard. Click on the job title to see a list of applicants and their profiles.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>FAQ | AppointTalent - Frequently Asked Questions</title>
        <meta name="description" content="Find answers to frequently asked questions about AppointTalent. Learn more about our platform and services." />
        <meta name="keywords" content="FAQ, questions, answers, appointtalent, platform, services" />
      </Helmet>
      <Container className="faq-section" style={{ marginTop: 250, padding: '20px', backgroundColor: '#ffffff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        {faqs.map((val, i) => (
          <AccordionComponent data={val} key={i} index={i} />
        ))}
      </Container>
      <br />
      <br />
      <Footer />
    </>
  );
};

export default Faq;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../../context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import {
  MdWorkOutline,
  MdLocationOn,
  MdAttachMoney,
  MdBusinessCenter,
} from "react-icons/md";
import { BsCheck2Circle } from "react-icons/bs";
import Footer from "../../components/Footer";
import "../../styles/postjob.css";

const steps = [
  {
    title: "Basic Information",
    icon: <MdWorkOutline />,
    description: "Job title and description",
  },
  {
    title: "Location & Type",
    icon: <MdLocationOn />,
    description: "Work location and job type",
  },
  {
    title: "Compensation",
    icon: <MdAttachMoney />,
    description: "Salary and benefits",
  },
  {
    title: "Requirements",
    icon: <MdBusinessCenter />,
    description: "Experience and skills",
  },
];

const EditJob = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    responsibilities: "",
    qualifications: "",
    skills: "",
    experienceRequirements: "",
    educationRequirements: "",
    employmentType: "",
    jobBenefits: "",
    applicantLocationRequirements: {
      city: "",
      region: "",
      country: "",
    },
    baseSalary: {
      currency: "",
      minValue: "",
      maxValue: "",
      unitText: "",
    },
    hiringOrganization: {
      name: "",
      sameAs: "",
    },
    validThrough: "",
  });

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const jobRef = doc(db, "jobs", id);
        const jobDoc = await getDoc(jobRef);

        if (jobDoc.exists()) {
          const data = jobDoc.data();
          if (data.employerId !== user?.uid) {
            toast.error("You don't have permission to edit this job");
            navigate("/profile");
            return;
          }
          setFormData(data);
        } else {
          toast.error("Job not found");
          navigate("/profile");
        }
      } catch (error) {
        console.error("Error fetching job details:", error);
        toast.error("Failed to load job details");
      } finally {
        setLoading(false);
      }
    };

    if (user?.uid) {
      fetchJobDetails();
    }
  }, [id, user, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      setFormData((prev) => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const validateStep = (step) => {
    switch (step) {
      case 0:
        return (
          formData.title &&
          formData.description &&
          formData.responsibilities &&
          formData.qualifications
        );
      case 1:
        return (
          formData.applicantLocationRequirements.city &&
          formData.applicantLocationRequirements.region &&
          formData.employmentType
        );
      case 2:
        return (
          formData.baseSalary.currency &&
          formData.baseSalary.minValue &&
          formData.baseSalary.maxValue &&
          formData.baseSalary.unitText
        );
      case 3:
        return (
          formData.experienceRequirements &&
          formData.educationRequirements &&
          formData.skills
        );
      default:
        return true;
    }
  };

  const handleNext = () => {
    if (validateStep(activeStep)) {
      setActiveStep((prev) => prev + 1);
      navigate(`/edit-job/${id}?step=${activeStep + 1}`);
    } else {
      toast.error("Please fill in all required fields");
    }
  };

  const handlePrevious = () => {
    setActiveStep((prev) => prev - 1);
    navigate(`/edit-job/${id}?step=${activeStep - 1}`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateStep(activeStep)) {
      toast.error("Please fill in all required fields");
      return;
    }

    try {
      setLoading(true);
      const jobRef = doc(db, "jobs", id);
      await updateDoc(jobRef, {
        ...formData,
        updatedAt: new Date(),
      });
      toast.success("Job updated successfully");
      navigate("/profile");
    } catch (error) {
      console.error("Error updating job:", error);
      toast.error("Failed to update job");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f4f4f6",
        }}
      >
        <div className="loading-spinner" style={{position:"relative"}}></div>
      </div>
    );
  }

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <div className="step-content">
            <h3>Basic Information</h3>
            <Form.Group className="mb-3">
              <Form.Label>Job Title*</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Job Description*</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Responsibilities*</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="responsibilities"
                value={formData.responsibilities}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Qualifications*</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="qualifications"
                value={formData.qualifications}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
          </div>
        );

      case 1:
        return (
          <div className="step-content">
            <h3>Location & Type</h3>
            <Form.Group className="mb-3">
              <Form.Label>City*</Form.Label>
              <Form.Control
                type="text"
                name="applicantLocationRequirements.city"
                value={formData.applicantLocationRequirements.city}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>State/Region*</Form.Label>
              <Form.Control
                type="text"
                name="applicantLocationRequirements.region"
                value={formData.applicantLocationRequirements.region}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Country*</Form.Label>
              <Form.Control
                type="text"
                name="applicantLocationRequirements.country"
                value={formData.applicantLocationRequirements.country}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Employment Type*</Form.Label>
              <Form.Select
                name="employmentType"
                value={formData.employmentType}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Type</option>
                <option value="FULL_TIME">Full Time</option>
                <option value="PART_TIME">Part Time</option>
                <option value="CONTRACT">Contract</option>
                <option value="TEMPORARY">Temporary</option>
                <option value="INTERN">Intern</option>
                <option value="VOLUNTEER">Volunteer</option>
              </Form.Select>
            </Form.Group>
          </div>
        );

      case 2:
        return (
          <div className="step-content">
            <h3>Compensation</h3>
            <Form.Group className="mb-3">
              <Form.Label>Currency*</Form.Label>
              <Form.Control
                type="text"
                name="baseSalary.currency"
                value={formData.baseSalary.currency}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Minimum Salary*</Form.Label>
              <Form.Control
                type="number"
                name="baseSalary.minValue"
                value={formData.baseSalary.minValue}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Maximum Salary*</Form.Label>
              <Form.Control
                type="number"
                name="baseSalary.maxValue"
                value={formData.baseSalary.maxValue}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Unit (e.g., HOUR, MONTH, YEAR)*</Form.Label>
              <Form.Select
                name="baseSalary.unitText"
                value={formData.baseSalary.unitText}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Unit</option>
                <option value="HOUR">Hour</option>
                <option value="DAY">Day</option>
                <option value="WEEK">Week</option>
                <option value="MONTH">Month</option>
                <option value="YEAR">Year</option>
              </Form.Select>
            </Form.Group>
          </div>
        );

      case 3:
        return (
          <div className="step-content">
            <h3>Requirements</h3>
            <Form.Group className="mb-3">
              <Form.Label>Experience Requirements*</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="experienceRequirements"
                value={formData.experienceRequirements}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" style={{ width: "100%" }}>
              <Form.Label>Education Requirements*</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="educationRequirements"
                value={formData.educationRequirements}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Required Skills*</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="skills"
                value={formData.skills}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Benefits (Optional)</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="jobBenefits"
                value={formData.jobBenefits}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Company Name*</Form.Label>
              <Form.Control
                type="text"
                name="hiringOrganization.name"
                value={formData.hiringOrganization.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Company Website (Optional)</Form.Label>
              <Form.Control
                type="url"
                name="hiringOrganization.sameAs"
                value={formData.hiringOrganization.sameAs}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Valid Through*</Form.Label>
              <Form.Control
                type="date"
                name="validThrough"
                value={formData.validThrough}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: 100 }}>
      <Container className="post-job-container">
        <Row className="justify-content-center">
          <Col lg={8} md={10} sm={12}>
            <h1 className="page-title">Edit Job Posting</h1>

            <div className="stepper">
              {steps.map((step, index) => (
                <div
                  key={step.title}
                  className={`step ${index === activeStep ? "active" : ""} ${
                    index < activeStep ? "completed" : ""
                  }`}
                  onClick={() => index < activeStep && setActiveStep(index)}
                >
                  <div className="step-icon">
                    {index < activeStep ? <BsCheck2Circle /> : step.icon}
                  </div>
                  <div className="step-title">{step.title}</div>
                  {index < steps.length - 1 && <div className="step-line" />}
                </div>
              ))}
            </div>

            <div className="form-section">
              <Form onSubmit={handleSubmit} className="job-posting-form">
                {renderStep()}

                <div className="form-actions">
                  {activeStep > 0 && (
                    <Button
                      variant="outline-secondary"
                      onClick={handlePrevious}
                      type="button"
                      disabled={loading}
                      className="action-button"
                    >
                      Previous
                    </Button>
                  )}

                  {activeStep < steps.length - 1 ? (
                    <Button
                      variant="primary"
                      onClick={handleNext}
                      type="button"
                      disabled={loading}
                      className="action-button"
                    >
                      Save & Next
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      disabled={loading}
                      className="action-button"
                      style={{ backgroundColor: "#1d2445" }}
                    >
                      {loading ? (
                        <>
                          <span className="loading-spinner"></span>
                          <span>Updating Job...</span>
                        </>
                      ) : (
                        "Update Job"
                      )}
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default EditJob;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { db } from "../../firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { trackEvent } from "../../utils/firebase";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import {
  MdWorkOutline,
  MdLocationOn,
  MdAttachMoney,
  MdBusinessCenter,
} from "react-icons/md";
import { BsCheck2Circle } from "react-icons/bs";
import Footer from "../../components/Footer";
import "../../styles/postjob.css";
import { Helmet } from "react-helmet";

const steps = [
  {
    title: "Basic Information",
    icon: <MdWorkOutline />,
    description: "Job title and description",
  },
  {
    title: "Location & Type",
    icon: <MdLocationOn />,
    description: "Work location and job type",
  },
  {
    title: "Compensation",
    icon: <MdAttachMoney />,
    description: "Salary and benefits",
  },
  {
    title: "Requirements",
    icon: <MdBusinessCenter />,
    description: "Experience and skills",
  },
];

const PostJob = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [currentStep, setCurrentStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [jobData, setJobData] = useState();

  useEffect(() => {
    trackEvent("page_view", { page_name: "post_job" });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setJobData((prev) => {
      const newData = { ...prev };
      if (name.includes(".")) {
        const [parent, child] = name.split(".");
        newData[parent] = {
          ...newData[parent],
          [child]: value,
        };
      } else {
        newData[name] = value;
      }
      return newData;
    });

    // Track when user fills in important fields
    if (
      [
        "title",
        "employmentType",
        "jobLocationType",
        "baseSalary.minValue",
      ].includes(name) &&
      value.length > 0
    ) {
      trackEvent("job_post_field_complete", {
        field: name,
      });
    }
  };

  const validateStep = () => {
    switch (currentStep) {
      case 0:
        if (!jobData.title || !jobData.description) {
          toast.error("Please fill in all required fields");
          return false;
        }
        break;
      case 1:
        if (!jobData.employmentType || !jobData.jobLocationType) {
          toast.error("Please select employment type and location type");
          return false;
        }
        break;
      case 2:
        if (!jobData.baseSalary.minValue) {
          toast.error("Please specify minimum salary");
          return false;
        }
        break;
      case 3:
        if (!jobData.experienceRequirements || !jobData.skills) {
          toast.error("Please specify experience requirements and skills");
          return false;
        }
        break;
      default:
        return true;
    }
    return true;
  };

  const handleNext = () => {
    if (validateStep()) {
      if (currentStep === steps.length - 1) {
        // Don't auto-submit on last step
        return;
      }
      setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
    }
  };

  const handlePrevious = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 0));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateStep()) return;

    try {
      setIsSubmitting(true);
      trackEvent("job_post_start", {
        job_title: jobData.title,
        company: jobData.hiringOrganization.name,
        job_type: jobData.employmentType,
      });

      const jobPostingData = {
        ...jobData,
        datePosted: serverTimestamp(),
        validThrough: new Date(jobData.validThrough).toISOString(),
        employerId: user.uid,
        status: "active",
        applicationCount: 0,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      await addDoc(collection(db, "jobs"), jobPostingData);
      trackEvent("job_post_success", {
        job_id: jobPostingData?.id,
        job_title: jobData?.title,
        company: jobData?.hiringOrganization.name,
        job_type: jobData?.employmentType,
      });
      toast.success("Job posted successfully!");
      navigate("/profile");
    } catch (error) {
      trackEvent("job_post_error", {
        job_title: jobData?.title,
        company: jobData.hiringOrganization.name,
        job_type: jobData.employmentType,
        error: error.message,
      });
      console.error("Error posting job:", error);
      toast.error("Failed to post job. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <section className="form-section">
            <Form.Group className="mb-3">
              <Form.Label>Job Title*</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={jobData?.title}
                onChange={handleChange}
                required
                placeholder="e.g., Senior Software Engineer"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Job Description*</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="description"
                value={jobData?.description}
                onChange={handleChange}
                required
                placeholder="Provide a detailed description of the role..."
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Key Responsibilities</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="responsibilities"
                value={jobData?.responsibilities}
                onChange={handleChange}
                placeholder="Day-to-day responsibilities and duties..."
              />
            </Form.Group>
          </section>
        );

      case 1:
        return (
          <section className="form-section">
            <Form.Group className="mb-3">
              <Form.Label>Employment Type*</Form.Label>
              <Form.Select
                name="employmentType"
                value={jobData?.employmentType}
                onChange={handleChange}
                required
              >
                <option value="FULL_TIME">Full Time</option>
                <option value="PART_TIME">Part Time</option>
                <option value="CONTRACTOR">Contractor</option>
                <option value="TEMPORARY">Temporary</option>
                <option value="INTERN">Intern</option>
                <option value="VOLUNTEER">Volunteer</option>
                <option value="PER_DIEM">Per Diem</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Work Type*</Form.Label>
              <Form.Select
                name="jobLocationType"
                value={jobData?.jobLocationType}
                onChange={handleChange}
                required
              >
                <option value="TELECOMMUTE">Remote</option>
                <option value="ON_SITE">On Site</option>
                <option value="HYBRID">Hybrid</option>
              </Form.Select>
            </Form.Group>

            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    name="applicantLocationRequirements.country"
                    value={jobData?.applicantLocationRequirements.country}
                    onChange={handleChange}
                    placeholder="e.g., United States"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>State/Region</Form.Label>
                  <Form.Control
                    type="text"
                    name="applicantLocationRequirements.region"
                    value={jobData?.applicantLocationRequirements.region}
                    onChange={handleChange}
                    placeholder="e.g., California"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    name="applicantLocationRequirements.city"
                    value={jobData?.applicantLocationRequirements.city}
                    onChange={handleChange}
                    placeholder="e.g., San Francisco"
                  />
                </Form.Group>
              </Col>
            </Row>
          </section>
        );

      case 2:
        return (
          <section className="form-section">
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Minimum Salary*</Form.Label>
                  <Form.Control
                    type="number"
                    name="baseSalary.minValue"
                    value={jobData?.baseSalary.minValue}
                    onChange={handleChange}
                    required
                    placeholder="e.g., 50000"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Maximum Salary</Form.Label>
                  <Form.Control
                    type="number"
                    name="baseSalary.maxValue"
                    value={jobData?.baseSalary.maxValue}
                    onChange={handleChange}
                    placeholder="e.g., 80000"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Currency</Form.Label>
                  <Form.Select
                    name="baseSalary.currency"
                    value={jobData?.baseSalary.currency}
                    onChange={handleChange}
                  >
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                    <option value="INR">INR</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Pay Period</Form.Label>
                  <Form.Select
                    name="baseSalary.unitText"
                    value={jobData?.baseSalary.unitText}
                    onChange={handleChange}
                  >
                    <option value="HOUR">Per Hour</option>
                    <option value="DAY">Per Day</option>
                    <option value="WEEK">Per Week</option>
                    <option value="MONTH">Per Month</option>
                    <option value="YEAR">Per Year</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label>Job Benefits</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="jobBenefits"
                value={jobData?.jobBenefits}
                onChange={handleChange}
                placeholder="Health insurance, 401k, etc..."
              />
            </Form.Group>
          </section>
        );

      case 3:
        return (
          <section className="form-section">
            <Form.Group className="mb-3">
              <Form.Label>Experience Requirements*</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="experienceRequirements"
                value={jobData?.experienceRequirements}
                onChange={handleChange}
                required
                placeholder="Required years and type of experience..."
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Education Requirements</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="educationRequirements"
                value={jobData?.educationRequirements}
                onChange={handleChange}
                placeholder="Required education level and field..."
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Skills Required*</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="skills"
                value={jobData?.skills}
                onChange={handleChange}
                required
                placeholder="Required technical and soft skills..."
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Application Deadline*</Form.Label>
              <Form.Control
                type="date"
                name="validThrough"
                value={jobData?.validThrough}
                onChange={handleChange}
                required
                min={new Date().toISOString().split("T")[0]}
              />
            </Form.Group>
          </section>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Helmet>
        <title>Post a Job | AppointTalent - Connect with Top Talent</title>
        <meta
          name="description"
          content="Post job listings on AppointTalent and connect with top talent. Streamline your hiring process and find the right candidates."
        />
        <meta
          name="keywords"
          content="post job, hiring, employment, job listings, recruit, talent, appointtalent"
        />
      </Helmet>
      <Container className="post-job-container">
        <Row className="justify-content-center">
          <Col lg={8} md={10} sm={12}>
            <h1 className="page-title">Post a New Job</h1>

            <div className="stepper">
              {steps.map((step, index) => (
                <div
                  key={step.title}
                  className={`step ${index === currentStep ? "active" : ""} ${
                    index < currentStep ? "completed" : ""
                  }`}
                  onClick={() => index < currentStep && setCurrentStep(index)}
                >
                  <div className="step-icon">
                    {index < currentStep ? <BsCheck2Circle /> : step.icon}
                  </div>
                  <div className="step-title">{step.title}</div>
                  {index < steps.length - 1 && <div className="step-line" />}
                </div>
              ))}
            </div>

            <Form onSubmit={handleSubmit} className="job-posting-form">
              {renderStepContent()}

              <div className="form-actions">
                {currentStep > 0 && (
                  <Button
                    variant="outline-secondary"
                    onClick={handlePrevious}
                    type="button"
                    disabled={isSubmitting}
                    className="action-button"
                  >
                    Previous
                  </Button>
                )}

                {currentStep < steps.length - 1 ? (
                  <Button
                    variant="primary"
                    onClick={handleNext}
                    type="button"
                    disabled={isSubmitting}
                    className="action-button"
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    className="action-button"
                    style={{ backgroundColor: "#1d2445" }}
                  >
                    {isSubmitting ? (
                      <>
                        <span className="loading-spinner"></span>
                        <span>Posting Job...</span>
                      </>
                    ) : (
                      "Post Job"
                    )}
                  </Button>
                )}
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default PostJob;

import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FiMail, FiPhone, FiUser, FiInfo } from "react-icons/fi";
import "../styles/profile-details.css";

const ProfileField = ({ icon: Icon, label, value }) => (
  <div className="profile-field">
    <div className="field-icon">
      <Icon size={20} />
    </div>
    <div className="field-content">
      <label>{label}</label>
      <p>{value}</p>
    </div>
  </div>
);

const ProfileDetails = ({ user }) => {
  // These would come from your user profile data
  const profileData = {
    firstName: user?.displayName?.split(" ")[0] || "Not set",
    lastName: user?.displayName?.split(" ")[1] || "",
    email: user?.email || "Not set",
    phone: user?.phoneNumber || "Not set",
    description: user?.description || "No description provided",
  };

  return (
    <div className="profile-details">
      <Row className="g-4">
        <Col lg={6} md={6} sm={12}>
          <ProfileField
            icon={FiUser}
            label="First Name"
            value={profileData.firstName}
          />
        </Col>
        <Col lg={6} md={6} sm={12}>
          <ProfileField
            icon={FiUser}
            label="Last Name"
            value={profileData.lastName}
          />
        </Col>
        <Col lg={6} md={6} sm={12}>
          <ProfileField icon={FiMail} label="Email" value={profileData.email} />
        </Col>
        <Col lg={6} md={6} sm={12}>
          <ProfileField
            icon={FiPhone}
            label="Phone"
            value={profileData.phone}
          />
        </Col>
        <Col lg={12} md={12} sm={12}>
          <ProfileField
            icon={FiInfo}
            label="About Me"
            value={profileData.description}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ProfileDetails;

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { LuSearch } from "react-icons/lu";
import { MdLocationOn, MdWork } from "react-icons/md";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import AdsComponent from "../../components/AdsComponent";
import "./styles.css";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { trackEvent } from "../../utils/firebase";

const popularSearches = [
  "Software Companies",
  "IT Services",
  "Startups",
  "Product Companies",
  "E-commerce",
  "Healthcare",
  "Finance",
  "Education"
];

const CompaniesPage = () => {
  const [companies, setCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedTag, setSelectedTag] = useState("All");
  const navigate = useNavigate();

  useEffect(() => {
    trackEvent('page_view', { page_name: 'companies' });
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      const usersRef = collection(db, "users");
      const q = query(usersRef);
      const querySnapshot = await getDocs(q);
      
      const employerData = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        console.log(data)
        if (data.role === "employer") {
          employerData.push({
            id: doc.id,
            name: data.companyName || data.displayName,
            logo: data.photoURL || "https://placehold.co/100x100",
            industry: data.industry || "Technology",
            location: data.location || "Not specified",
            openPositions: data.jobCount || 0,
            rating: data.rating || 4.0,
            description: data.description || "",
            website: data.website || "",
            size: data.companySize || "",
          });
        }
      });
      
      setCompanies(employerData);
      trackEvent('companies_loaded', { 
        count: employerData.length 
      });
      setLoading(false);
    } catch (error) {
      trackEvent('companies_load_error', { 
        error: error.message 
      });
      console.error("Error fetching companies:", error);
      setLoading(false);
    }
  };

  const filteredCompanies = companies.filter((company) => {
    const matchesSearch = company.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         company.industry.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         company.location.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesTag = selectedTag === "All" || company.industry.includes(selectedTag);
    
    return matchesSearch && matchesTag;
  });

  const handleCompanyClick = (companyId, companyName) => {
    trackEvent('company_profile_view', { 
      company_id: companyId,
      company_name: companyName
    });
    navigate(`/companies/${companyId}`);
  };

  const handleFilter = (filter) => {
    trackEvent('companies_filter_change', { filter });
    setSelectedTag(filter);
  };

  return (
    <div className="companies-page">
      <Helmet>
        <title>Companies | AppointTalent - Explore Top Employers</title>
        <meta name="description" content="Discover top companies and employers on AppointTalent. Explore job opportunities and company profiles." />
        <meta name="keywords" content="companies, employers, job opportunities, appointtalent, company profiles" />
      </Helmet>
      <div className="companies-hero">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} className="text-center">
              <h1>Discover Great Places to Work</h1>
              <p>Explore top companies hiring now and find your dream workplace</p>
              <div className="search-container">
                <LuSearch className="search-icon" />
                <input
                  type="text"
                  placeholder="Search companies by name or location..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="mt-5">
        <Row>
          <Col md={12} className="mb-4">
            <div className="popular-searches">
              <button
                className={`search-tag ${selectedTag === "All" ? "active" : ""}`}
                onClick={() => handleFilter("All")}
              >
                All
              </button>
              {popularSearches.map((tag, index) => (
                <button
                  key={index}
                  className={`search-tag ${selectedTag === tag ? "active" : ""}`}
                  onClick={() => handleFilter(tag)}
                >
                  {tag}
                </button>
              ))}
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <h2 className="section-title">Featured Companies</h2>
            {loading ? (
              <div className="text-center py-5">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <Row>
                {filteredCompanies.map((company) => (
                  <Col md={6} lg={4} key={company.id} className="mb-4">
                    <Card className="company-card">
                      <Card.Body>
                        <div className="company-logo">
                          <img src={company.logo} alt={company.name} />
                        </div>
                        <h5 className="company-name">{company.name}</h5>
                        <div className="company-rating">
                          {[...Array(5)].map((_, i) => (
                            <span
                              key={i}
                              className={i < company.rating ? "star filled" : "star"}
                            >
                              ★
                            </span>
                          ))}
                          <span className="rating-number">({company.rating})</span>
                        </div>
                        <div className="company-info">
                          <p>
                            <MdWork /> {company.industry}
                          </p>
                          <p>
                            <MdLocationOn /> {company.location}
                          </p>
                        </div>
                        <div className="open-positions">
                          {company.openPositions} open positions
                        </div>
                        <button onClick={() => handleCompanyClick(company.id, company.name)}>View Profile</button>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CompaniesPage;

import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import JobItem from "../../components/JobItem";
import Loading from "../../components/Loading";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Filter from "../../components/Filter";
import "../../styles/home.css";
import { getjobs } from "../../apis";
import { useData } from '../../context/DataContext';
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { trackEvent } from "../../utils/firebase";

const Search = (props) => {
  const url = new URL(window.location.href);
  const q = url.searchParams.get("q");
  const location = useLocation();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalResults, setTotalResults] = useState(0);
  const { jobs, setjobs } = useData();

  useEffect(() => {
    trackEvent('page_view', { page_name: 'search' });
    fecthJobs();
    window.scrollTo(0, 0);
    // Scroll to the top of the page when the component mounts
  }, []);

  const fecthJobs = async () => {
    try {
      trackEvent('search_start', { 
        query: q,
        filters: {} 
      });
      const data = await getjobs();
      console.log("========:", data);
      // const filteredData = data.filter(
      //   (job, i) => job.job_employment_type.toLowerCase() === q.toLowerCase()
      // );
      setjobs([...data]); // Assuming data.data contains the jobs array
      setLoading(false);
      trackEvent('search_complete', { 
        query: q,
        results_count: data.length,
        filters: {} 
      });
    } catch (error) {
      setLoading(false);
      trackEvent('search_error', { 
        query: q,
        error: error.message,
        filters: {} 
      });
      console.log("Error fetching jobs:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Latest Job Openings | Find Your Dream Job on AppointTalent
        </title>
        <meta
          name="description"
          content="Explore the latest job openings across various industries. Apply for your dream job on AppointTalent today."
        />
        <meta
          name="keywords"
          content="job openings, latest jobs, career opportunities, job search, employment opportunities, find a job, job listings, apply for jobs, job vacancies, remote jobs, part-time jobs, full-time jobs, work from home jobs, internships"
        />
      </Helmet>

      {loading ? (
        <div className="_loading_view">
          <Loading />
        </div>
      ) : (
        <>
          <Filter search={q} />
          <Container className="_main_section">
            <Row className="justify-content-center mt-10">
              <Col lg="8" md="10" sm="12" xs="12">
                <InfiniteScroll
                  dataLength={articles?.length}
                  hasMore={articles?.length !== totalResults}
                  loader={<Loading />}
                >
                  {jobs && jobs.length !== 0 ? (
                    jobs.map((element, index) => {
                      return <JobItem data={element} key={index} />;
                    })
                  ) : (
                    <div className="_job_not_found">Job Not Found</div>
                  )}
                </InfiniteScroll>
              </Col>
            </Row>
          </Container>
        </>
      )}
      <br />
      <br />
      <Footer />
    </>
  );
};

export default Search;

Search.defaultProps = {
  country: "in",
  pageSize: 16,
  category: "general",
};

Search.propTypes = {
  country: PropTypes.string,
  pageSize: PropTypes.number,
  category: PropTypes.string,
};

import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { LuMenu } from "react-icons/lu";
import { IoClose } from "react-icons/io5";
import { Popover } from "react-tiny-popover";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { HiOutlineMail } from "react-icons/hi";
import * as EmailValidator from "email-validator";
import { ToastContainer, toast, Bounce } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import "react-toastify/dist/ReactToastify.css";
import { addData } from "../apis";
import "./../styles/navbar.css";
import { FaUserCircle } from "react-icons/fa";
import { NavDropdown } from "react-bootstrap";
import { FaUser } from "react-icons/fa";
import { MdWorkOutline } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import Logo from "./Logo";

const UserAvatar = ({ user }) => {
  if (user.photoURL) {
    return (
      <img
        src={user.photoURL}
        alt="Profile"
        className="profile-image"
        style={{
          width: "32px",
          height: "32px",
          borderRadius: "50%",
          objectFit: "cover",
        }}
      />
    );
  }

  // If no image, show first letter of email or display name in a circle
  const displayName = user.displayName || user.email || "U";
  const initial = displayName.charAt(0).toUpperCase();

  return (
    <div
      style={{
        width: "32px",
        height: "32px",
        borderRadius: "50%",
        backgroundColor: "#1d2445",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "16px",
        fontWeight: "bold",
      }}
    >
      {initial}
    </div>
  );
};

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  let { pathname } = location;
  const [isVisible, setIsVisible] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const handlePostJobClick = () => {
    if (!user) {
      // Save current path for redirect after login
      sessionStorage.setItem("redirectUrl", "/post-job");
      navigate("/login");
      return;
    }
    navigate("/post-job");
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      toast.success("Successfully logged out");
      navigate("/");
    } catch (error) {
      toast.error("Error logging out");
    }
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  function MyVerticallyCenteredModal(props) {
    const [email, setEmail] = useState("");

    const subscribeHandle = async () => {
      if (email === "") {
        toast.error("Email is required", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } else if (!EmailValidator.validate(email)) {
        toast.error("Email is invalid", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } else {
        try {
          await addData("email", { email });
          setEmail("");
          props.onHide();
          toast.success("Subscribed successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        } catch (err) {
          toast.error(err.message | "something went wrong", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      }
    };
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <section className="subscribe" id="subscribe">
            <h2 className="_subscribe_title">
              Stay Updated with Latest Job Opportunities!
            </h2>
            <p>
              Subscribe to our newsletter and get instant notifications about
              new job openings, career tips, and industry insights.
            </p>
            <p>
              Join thousands of successful job seekers who found their dream
              careers with us.
            </p>
            <Row className="justify-content-center mt-10">
              <Col lg="5" md="5" sm="12" xs="12">
                <div
                  className="input_container"
                  style={{
                    backgroundColor: "white",
                    borderRadius: 10,
                    paddingLeft: 20,
                    height: 60,
                    margin: 2,
                    width: "100%",
                  }}
                >
                  <HiOutlineMail />

                  <input
                    placeholder="Enter your email"
                    className="_search_input"
                    style={{
                      backgroundColor: "white",
                      borderRadius: 10,
                    }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </Col>
              {/* <Col lg="2" md="2" sm="12" xs="12">
                <button
                  onClick={subscribeHandle}
                  className="_subscribe_btn_home"
                  style={{ width: "100%" }}
                >
                  Subscribe
                </button>
              </Col> */}

              <Col lg="2" md="2" sm="12" xs="12">
                <button
                  onClick={subscribeHandle}
                  className="_subscribe_btn_home"
                  style={{ width: "100%" }}
                >
                  Post Job
                </button>
              </Col>
            </Row>
          </section>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <div className="navbar">
        <Link to="/">
          <Logo />
          {/* <img src={require("../images/logo.png")} alt="Logo" className="logo" /> */}
        </Link>
        <div className="navbar_container">
          <Link
            to="/"
            className={pathname === "/" ? "active_nav_link" : "nav_link"}
          >
            Home
          </Link>
          {/* <Link
            to="/jobs"
            className={pathname === "/jobs" ? "active_nav_link" : "nav_link"}
          >
            Find Jobs
          </Link> */}
          <Link
            to="/companies"
            className={
              pathname === "/companies" ? "active_nav_link" : "nav_link"
            }
          >
            Companies
          </Link>
          <Link
            to="/career-advice"
            className={
              pathname === "/career-advice" ? "active_nav_link" : "nav_link"
            }
          >
            Career Advice
          </Link>
          <Link
            to="/salary-guide"
            className={
              pathname === "/salary-guide" ? "active_nav_link" : "nav_link"
            }
          >
            Salary Guide
          </Link>
          <Link
            to="/interview-tips"
            className={
              pathname === "/interview-tips" ? "active_nav_link" : "nav_link"
            }
          >
            Interview Tips
          </Link>
          <Link
            to="/about-us"
            className={
              pathname === "/about-us" ? "active_nav_link" : "nav_link"
            }
          >
            About Us
          </Link>
          <Link
            to="/contact"
            className={pathname === "/contact" ? "active_nav_link" : "nav_link"}
          >
            Contact
          </Link>

          {user ? (
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                id="dropdown-basic"
                className="nav-link profile-dropdown"
              >
                <UserAvatar user={user} />
              </Dropdown.Toggle>

              <Dropdown.Menu align="end" className="profile-dropdown-menu">
                <div className="px-3 py-2 mb-2">
                  <div className="user-info">
                    <UserAvatar user={user} />
                    <div className="ms-2">
                      <div className="fw-bold">
                        {user.displayName || "User"}
                      </div>
                      <small className="text-muted">{user.email}</small>
                    </div>
                  </div>
                </div>
                <Dropdown.Divider />
                <Dropdown.Item
                  as={Link}
                  to="/profile"
                  className="dropdown-item"
                >
                  <i className="fas fa-user me-2"></i> My Profile
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to="/profile"
                  state={{ activeTab: "applications" }}
                  className="dropdown-item"
                >
                  <i className="fas fa-briefcase me-2"></i> My Applications
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to="/profile"
                  state={{ activeTab: "saved" }}
                  className="dropdown-item"
                >
                  <i className="fas fa-bookmark me-2"></i> Saved Jobs
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={handleLogout}
                  className="dropdown-item text-danger"
                >
                  <i className="fas fa-sign-out-alt me-2"></i> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <button className="_subscribe_btn" onClick={handlePostJobClick}>
              Post a job
            </button>
          )}
        </div>

        <div className="menu_btn" onClick={toggleVisibility}>
          <LuMenu color="#171717" size={30} />
        </div>

        {/* Mobile menu */}
        <div className={`navbar_container2 ${isVisible ? "visible" : ""}`}>
          <IoClose
            color="#171717"
            size={30}
            onClick={toggleVisibility}
            style={{
              alignSelf: "flex-end",
              display: "flex",
              marginTop: 10,
              marginRight: 10,
            }}
          />
          <Link
            to="/"
            className={pathname === "/" ? "active_nav_link" : "nav_link"}
            onClick={toggleVisibility}
          >
            Home
          </Link>
          <Link
            to="/jobs"
            className={pathname === "/jobs" ? "active_nav_link" : "nav_link"}
            onClick={toggleVisibility}
          >
            Find Jobs
          </Link>
          <Link
            to="/companies"
            className={
              pathname === "/companies" ? "active_nav_link" : "nav_link"
            }
            onClick={toggleVisibility}
          >
            Companies
          </Link>
          <Link
            to="/career-advice"
            className={
              pathname === "/career-advice" ? "active_nav_link" : "nav_link"
            }
            onClick={toggleVisibility}
          >
            Career Advice
          </Link>
          <Link
            to="/salary-guide"
            className={
              pathname === "/salary-guide" ? "active_nav_link" : "nav_link"
            }
            onClick={toggleVisibility}
          >
            Salary Guide
          </Link>
          <Link
            to="/interview-tips"
            className={
              pathname === "/interview-tips" ? "active_nav_link" : "nav_link"
            }
            onClick={toggleVisibility}
          >
            Interview Tips
          </Link>
          <Link
            to="/about-us"
            className={
              pathname === "/about-us" ? "active_nav_link" : "nav_link"
            }
            onClick={toggleVisibility}
          >
            About Us
          </Link>
          <Link
            to="/contact"
            className={pathname === "/contact" ? "active_nav_link" : "nav_link"}
            onClick={toggleVisibility}
          >
            Contact
          </Link>
          {user ? (
            <>
              <Link
                to="/profile"
                className="nav_link"
                onClick={toggleVisibility}
              >
                Profile
              </Link>
              <Link
                to="/post-job"
                className="nav_link"
                onClick={toggleVisibility}
              >
                Post a Job
              </Link>
              <button
                className="_subscribe_btn"
                onClick={() => {
                  handleLogout();
                  toggleVisibility();
                }}
              >
                Logout
              </button>
            </>
          ) : (
            <button
              className="_subscribe_btn"
              onClick={() => {
                handlePostJobClick();
                toggleVisibility();
              }}
            >
              Post a job
            </button>
          )}
        </div>
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <ToastContainer />
    </>
  );
};

export default Navbar;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { trackEvent } from "../../utils/firebase";
import { Container, Row, Col, Card } from "react-bootstrap";
import { LuSearch } from "react-icons/lu";
import { FaBookOpen, FaClock, FaUserTie } from "react-icons/fa";
import AdsComponent from "../../components/AdsComponent";
import Footer from "../../components/Footer";
import "./styles.css";

const popularTopics = [
  "Resume Writing",
  "Interview Tips",
  "Career Growth",
  "Job Search",
  "Workplace Skills",
  "Remote Work",
  "Networking",
  "Leadership"
];

const articles = [
  {
    title: "10 Essential Tips for Writing a Winning Resume",
    category: "Resume Writing",
    readTime: "8 min read",
    author: "Sarah Johnson",
    image: "https://images.unsplash.com/photo-1586281380349-632531db7ed4?ixlib=rb-4.0.3",
    excerpt:
      "Learn how to create a resume that stands out and gets you noticed by top employers...",
  },
  {
    title: "Mastering the Art of Job Interview",
    category: "Interview Tips",
    readTime: "12 min read",
    author: "Michael Brown",
    image: "https://images.unsplash.com/photo-1565688534245-05d6b5be184a?ixlib=rb-4.0.3",
    excerpt:
      "Prepare for your next job interview with these proven strategies and techniques...",
  },
  {
    title: "Building Your Personal Brand on LinkedIn",
    category: "Networking",
    readTime: "10 min read",
    author: "Emily Chen",
    image: "https://images.unsplash.com/photo-1611926653458-09294b3142bf?ixlib=rb-4.0.3",
    excerpt:
      "Discover how to optimize your LinkedIn profile and build a strong professional network...",
  },
  {
    title: "Navigating Career Changes Successfully",
    category: "Career Growth",
    readTime: "15 min read",
    author: "David Wilson",
    image: "https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-4.0.3",
    excerpt:
      "Learn effective strategies for making successful career transitions and finding your path...",
  },
  {
    title: "Essential Skills for Remote Work Success",
    category: "Remote Work",
    readTime: "9 min read",
    author: "Lisa Anderson",
    image: "https://images.unsplash.com/photo-1593642532842-98d0fd5ebc1a?ixlib=rb-4.0.3",
    excerpt:
      "Master the key skills needed to thrive in a remote work environment...",
  },
  {
    title: "Developing Leadership Skills Early in Your Career",
    category: "Leadership",
    readTime: "11 min read",
    author: "James Martinez",
    image: "https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-4.0.3",
    excerpt:
      "Start building essential leadership skills that will accelerate your career growth...",
  }
];

const CareerAdvicePage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTag, setSelectedTag] = useState("All");
  const navigate = useNavigate();

  useEffect(() => {
    trackEvent('page_view', { page_name: 'career_advice' });
  }, []);

  const filteredArticles = articles.filter((article) => {
    const matchesSearch = 
      article.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      article.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
      article.excerpt.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesTag = selectedTag === "All" || article.category === selectedTag;
    
    return matchesSearch && matchesTag;
  });

  const handleArticleClick = (article) => {
    trackEvent('career_article_view', { 
      article_id: article.title,
      article_title: article.title
    });
    navigate(`/career-advice/${article.title}`);
  };

  const handleCategoryFilter = (category) => {
    trackEvent('career_advice_filter_change', { 
      category 
    });
    setSelectedTag(category);
  };

  const handleSearch = (searchTerm) => {
    trackEvent('career_advice_search', { 
      search_term: searchTerm 
    });
    setSearchTerm(searchTerm);
  };

  return (
    <div className="career-advice-page">
      <div className="career-hero">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} className="text-center">
              <h1>Career Advice & Resources</h1>
              <p>Expert tips and guidance to help you succeed in your career journey</p>
              <div className="search-container">
                <LuSearch className="search-icon" />
                <input
                  type="text"
                  placeholder="Search articles by topic..."
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="mt-5">
        <Row>
          <Col md={12} className="mb-4">
            <AdsComponent
              client="ca-pub-3768976581935456"
              slot="3401941690"
              format="auto"
              responsive="true"
            />
          </Col>
        </Row>

        <Row>
          <Col md={12} className="mb-4">
            <div className="popular-searches">
              <button
                className={`search-tag ${selectedTag === "All" ? "active" : ""}`}
                onClick={() => handleCategoryFilter("All")}
              >
                All Topics
              </button>
              {popularTopics.map((tag, index) => (
                <button
                  key={index}
                  className={`search-tag ${selectedTag === tag ? "active" : ""}`}
                  onClick={() => handleCategoryFilter(tag)}
                >
                  {tag}
                </button>
              ))}
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <h2 className="section-title">Featured Articles</h2>
            <Row>
              {filteredArticles.map((article, index) => (
                <Col md={6} lg={4} key={index} className="mb-4">
                  <Card className="article-card">
                    <div className="article-image">
                      <img src={article.image} alt={article.title} />
                      <span className="category-badge">{article.category}</span>
                    </div>
                    <Card.Body>
                      <h5 className="article-title">{article.title}</h5>
                      <p className="article-excerpt">{article.excerpt}</p>
                      <div className="article-meta">
                        <span>
                          <FaUserTie /> {article.author}
                        </span>
                        <span>
                          <FaClock /> {article.readTime}
                        </span>
                      </div>
                      <button className="read-more-btn" onClick={() => handleArticleClick(article)}>Read Article</button>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={12}>
            <AdsComponent
              client="ca-pub-3768976581935456"
              slot="3401941690"
              format="auto"
              responsive="true"
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={12}>
            <div className="newsletter-section">
              <h3>Get Career Tips in Your Inbox</h3>
              <p>Subscribe to our newsletter for weekly career advice and job search tips</p>
              <div className="newsletter-form">
                <input type="email" placeholder="Enter your email" />
                <button className="subscribe-btn">Subscribe</button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default CareerAdvicePage;

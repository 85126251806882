import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

const SavedJobsContext = createContext();

export function useSavedJobs() {
  return useContext(SavedJobsContext);
}

export function SavedJobsProvider({ children }) {
  const [savedJobIds, setSavedJobIds] = useState([]);
  const { currentUser } = auth;

  useEffect(() => {
    if (currentUser) {
      loadSavedJobs();
    } else {
      setSavedJobIds([]);
    }
  }, [currentUser]);

  const loadSavedJobs = async () => {
    try {
      const userDoc = await getDoc(doc(db, "users", currentUser.uid));
      if (userDoc.exists()) {
        setSavedJobIds(userDoc.data()?.savedJobs || []);
      }
    } catch (error) {
      console.error("Error loading saved jobs:", error);
    }
  };

  const addSavedJob = (jobId) => {
    setSavedJobIds(prev => [...prev, jobId]);
  };

  const removeSavedJob = (jobId) => {
    setSavedJobIds(prev => prev.filter(id => id !== jobId));
  };

  const value = {
    savedJobIds,
    addSavedJob,
    removeSavedJob,
    refreshSavedJobs: loadSavedJobs
  };

  return (
    <SavedJobsContext.Provider value={value}>
      {children}
    </SavedJobsContext.Provider>
  );
}

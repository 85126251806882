import React, { useEffect, useState, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import { Card } from "react-bootstrap";
// import aboutUsImage from "../../assets/about-us.jpg";
import "../../styles/about-us.css";

const teamMembers = [
  {
    name: "John Doe",
    position: "CEO",
    image: "https://via.placeholder.com/150",
    bio: "John is the visionary behind AppointTalent, leading the company with his extensive experience in the recruitment industry."
  },
  {
    name: "Jane Smith",
    position: "CTO",
    image: "https://via.placeholder.com/150",
    bio: "Jane is the technical genius who ensures our platform is running smoothly and efficiently."
  },
  {
    name: "Emily Johnson",
    position: "COO",
    image: "https://via.placeholder.com/150",
    bio: "Emily oversees operations, making sure everything is in place for our users and partners."
  }
];

const AboutUs = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>About Us | AppointTalent - Empowering Your Career Journey</title>
        <meta
          name="description"
          content="Discover AppointTalent's mission and vision. Learn how we connect job seekers with opportunities and empower career growth."
        />
        <meta
          name="keywords"
          content="about us, appointtalent, mission, vision, career growth, job seekers"
        />
      </Helmet>
      <div
        className="about-hero"
        // style={{ backgroundImage: `url(${aboutUsImage})` }}
      >
        <div className="interview-hero">
          <br />
          <br />
          <br />
          <Container>
            <Row className="justify-content-center ">
              <Col md={8} className="text-center">
                <h1>About Us</h1>
              </Col>
            </Row>
          </Container>
        </div>
       
      </div>
      <Container className="_main_section">
        <Row className="justify-content-center mt-5">
          <Col xl="10" lg="10" md="12" sm="12" xs="12">
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>Welcome to AppointTalent</Card.Title>
                <Card.Text>
                  Welcome to AppointTalent, your premier destination for
                  discovering job opportunities and scholarships worldwide. At
                  AppointTalent, we understand the significance of finding the
                  right career and educational advancement opportunities that
                  align with your aspirations and potential. Our platform is
                  designed to bridge the gap between talented individuals and
                  the plethora of opportunities awaiting them.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>Our Mission</Card.Title>
                <Card.Text>
                  Our mission is to empower job seekers and students by
                  providing them with a comprehensive and user-friendly platform
                  to search for job vacancies and scholarship opportunities. We
                  aim to simplify the process of finding relevant and exciting
                  opportunities, making it easier for you to achieve your
                  professional and academic goals.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>Our Vision</Card.Title>
                <Card.Text>
                  Our vision is to be the leading platform that connects
                  individuals with their dream careers and educational paths,
                  fostering a world where talent meets opportunity seamlessly.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>Our Team</Card.Title>
                <Row>
                  {teamMembers.map((member, index) => (
                    <Col md={4} key={index} className="text-center">
                      <img src={member.image} alt={member.name} className="img-fluid rounded-circle mb-3" />
                      <h5>{member.name}</h5>
                      <p className="text-muted">{member.position}</p>
                      <p>{member.bio}</p>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default AboutUs;

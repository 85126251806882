// DataContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";
import { useAuth } from "./AuthContext";

export const DataContext = createContext();

export function useData() {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useData must be used within a DataProvider");
  }
  return context;
}

export const DataProvider = ({ children }) => {
  const [jobs, setJobs] = useState([]);
  const [myJobs, setMyJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const { user } = useAuth() || { user: null };

  // Get current user data
  const getCurrentUser = async () => {
    if (!user?.uid) return null;
    try {
      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = { id: userDoc.id, ...userDoc.data() };
        setCurrentUser(userData);
        return userData;
      }
      return null;
    } catch (error) {
      console.error("Error getting user data:", error);
      return null;
    }
  };

  // Get all jobs
  const getAllJobs = async () => {
    setLoading(true);
    try {
      const q = query(collection(db, "jobs"), orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(q);
      const jobsList = [];
      querySnapshot.forEach((doc) => {
        jobsList.push({ id: doc.id, ...doc.data() });
      });
      setJobs(jobsList);
      return jobsList;
    } catch (error) {
      console.error("Error getting jobs:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Get user's posted jobs
  const getUserJobs = async () => {
    if (!user?.uid) return [];

    console.log(user.uid);
    setLoading(true);
    try {
      const q = query(
        collection(db, "jobs"),
        where("employerId", "==", user.uid),
        orderBy("createdAt", "desc")
      );
      const querySnapshot = await getDocs(q);
      const jobsList = [];
      querySnapshot.forEach((doc) => {
        jobsList.push({ id: doc.id, ...doc.data() });
      });
      console.log("=========+>", jobsList);
      setMyJobs(jobsList);
      return jobsList;
    } catch (error) {
      console.error("Error getting user jobs:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Get single job
  const getJob = async (jobId) => {
    setLoading(true);
    try {
      const jobRef = doc(db, "jobs", jobId);
      const jobDoc = await getDoc(jobRef);
      if (jobDoc.exists()) {
        return { id: jobDoc.id, ...jobDoc.data() };
      }
      return null;
    } catch (error) {
      console.error("Error getting job:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Add new job
  const addJob = async (jobData) => {
    if (!user?.uid) throw new Error("User not authenticated");
    setLoading(true);
    try {
      const jobRef = collection(db, "jobs");
      const newJob = {
        ...jobData,
        employerId: user.uid,
        createdAt: new Date(),
        updatedAt: new Date(),
      };
      const docRef = await addDoc(jobRef, newJob);
      await getUserJobs(); // Refresh myJobs after adding
      return { id: docRef.id, ...newJob };
    } catch (error) {
      console.error("Error adding job:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Update job
  const updateJob = async (jobId, jobData) => {
    if (!user?.uid) throw new Error("User not authenticated");
    setLoading(true);
    try {
      const jobRef = doc(db, "jobs", jobId);
      const updatedJob = {
        ...jobData,
        updatedAt: new Date(),
      };
      await updateDoc(jobRef, updatedJob);
      await getUserJobs(); // Refresh myJobs after updating
      return { id: jobId, ...updatedJob };
    } catch (error) {
      console.error("Error updating job:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Delete job
  const deleteJob = async (jobId) => {
    if (!user?.uid) throw new Error("User not authenticated");
    setLoading(true);
    try {
      const jobRef = doc(db, "jobs", jobId);
      await deleteDoc(jobRef);
      await getUserJobs(); // Refresh myJobs after deleting
      return true;
    } catch (error) {
      console.error("Error deleting job:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllJobs();
    if (user) {
      getUserJobs();
      getCurrentUser();
    }
  }, [user]);

  const value = {
    jobs,
    myJobs,
    loading,
    currentUser,
    getAllJobs,
    getUserJobs,
    getCurrentUser,
    getJob,
    addJob,
    updateJob,
    deleteJob,
    setJobs,
    setMyJobs,
    user,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

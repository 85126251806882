import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { PiShoppingBagOpenFill } from "react-icons/pi";
import { LuClock3 } from "react-icons/lu";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";
import Footer from "../../components/Footer";
import Loading from "../../components/Loading";
import { jobdetails } from "../../apis";
import { useAuth } from "../../context/AuthContext";
import "../../styles/details.css";

const Details = (props) => {
  const [data, setdata] = useState();
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!user) {
      // Store the current URL to redirect back after login
      sessionStorage.setItem(
        "redirectUrl",
        location.pathname + location.search
      );
      navigate("/login");
      return;
    }
    fecthJobs();
  }, [user]);

  const fecthJobs = async () => {
    try {
      const url = new URL(window.location.href);
      const id = url.searchParams.get("id");
      const data = await jobdetails(id);
      if (data) {
        setdata({ ...data });
      }
    } catch (error) {
      console.log("Error fetching jobs:", error);
    } finally {
      setLoading(false);
    }
  };
  console.log(data);

  return (
    <div style={{ marginTop: 100 }}>
      <Container>
        {!loading ? (
          <Row className="justify-content-center mt-10">
            <Col lg="8" md="10" sm="12" xs="12">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                  backgroundColor: "#f4f4f6",
                }}
              >
                <div
                  className="loading-spinner"
                  style={{ position: "relative" }}
                ></div>
              </div>
            </Col>
          </Row>
        ) : (
          <Row className="justify-content-center mt-10">
            <Col lg="8" md="10" sm="12" xs="12">
              <Card className="_job_dtails_view">
                <Card.Body>
                  <div className="_card_dtail_body">
                    <img
                      className="post_detail_image"
                      src={data?.employer_logo}
                    />
                    <div className="_bottom_section">
                      <h3 className="_job_details_title">{data?.job_title}</h3>
                      <h4>
                        <a href="#" className="_company_name">
                          {data?.employer_name} . {data?.job_city} .{" "}
                          {data?.job_country}
                        </a>
                      </h4>
                      <div className="_job_details">
                        <div>
                          <span className="_job_location">
                            {data?.job_country}
                          </span>
                        </div>
                        <div></div>
                        <div>
                          <LuClock3 color="#057642" />
                          {data?.job_posted_at_datetime_utc && (
                            <span
                              className="_posting_time"
                              style={{ marginLeft: 5 }}
                            >
                              {moment(data?.job_posted_at_datetime_utc)
                                .fromNow()
                                .replace("a few seconds ago", "Just now")
                                .replace("a year ago", "1 year ago")
                                .replace("a minute ago", "1 minute ago")
                                .replace(/minutes ago/, "minutes ago")
                                .replace("an hour ago", "1 hour ago")
                                .replace(/hours ago/, "hours ago")
                                .replace("a day ago", "1 day ago")
                                .replace(/days ago/, "days ago")}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="job_type_sec">
                        <PiShoppingBagOpenFill color="#666666" size={30} />
                        <div>{data?.job_type && data?.job_type}</div>
                        <div>{data?.job_employment_type}</div>
                      </div>
                    </div>
                  </div>

                  <h4 style={{ marginTop: 20 }}>About the job</h4>
                  {data?.description && (
                    <>
                      <div className="jobDetailsSubHeading">
                        Company Description
                      </div>
                      <div
                        className="jobDetailsSubDes"
                        dangerouslySetInnerHTML={{ __html: data.description }}
                      />
                    </>
                  )}
                  {data?.job_required_skills && (
                    <>
                      <div className="jobDetailsSubHeading">
                        Job required skills
                      </div>
                      <ul className="qualificationList">
                        {data?.job_required_skills.map((_skill, i) => {
                          return <li key={i}>{_skill}</li>;
                        })}
                      </ul>
                    </>
                  )}
                  {data?.job_responsibilities && (
                    <>
                      <div className="jobDetailsSubHeading">
                        Responsibilities
                      </div>
                      <ul className="qualificationList">
                        {data?.job_responsibilities.map((resp, i) => {
                          return (
                            <li className="jobDetailsSubDes" key={i}>
                              {resp}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}

                  {data?.job_qualifications && (
                    <>
                      <div className="jobDetailsSubHeading">Qualifications</div>
                      <ul className="qualificationList">
                        {data?.job_qualifications.map((qualification, i) => {
                          return (
                            <li className="jobDetailsSubDes" key={i}>
                              {qualification}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}

                  {data?.job_required_education && (
                    <>
                      <div className="jobDetailsSubHeading">
                        Job Required Education
                      </div>
                      <ul className="qualificationList">
                        {data?.job_qualifications.map((qualification, i) => {
                          return (
                            <li className="jobDetailsSubDes" key={i}>
                              {qualification}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}

                  <p className="Posted on Feb 12, 2024.">
                    Posted on{" "}
                    {moment(data?.job_posted_at_datetime_utc).format(
                      "MMM DD, yyyy"
                    )}
                  </p>
                </Card.Body>
                <Card.Footer style={{ textAlign: "right" }}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={data?.job_apply_link}
                  >
                    <button className="_apply_btn">
                      Apply{" "}
                      <MdKeyboardDoubleArrowRight style={{ marginLeft: 5 }} />
                    </button>
                  </a>
                </Card.Footer>
              </Card>
              <br />
            </Col>
          </Row>
        )}
      </Container>

      <Footer />
    </div>
  );
};

export default Details;

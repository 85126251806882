import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  ButtonGroup,
  Badge,
  Tabs,
  Tab,
  Accordion,
  Modal,
  Spinner,
} from "react-bootstrap";
import { useAuth } from "../../context/AuthContext";
import { useData } from "../../context/DataContext";
import { db } from "../../firebase";
import JobItem from "../../components/JobItem";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiEdit2, FiUserPlus, FiCheck, FiX } from "react-icons/fi";
import { AiOutlineFileText } from "react-icons/ai";
import { BsCurrencyDollar } from "react-icons/bs";

import {
  MdWorkOutline,
  MdOutlineBookmarkBorder,
  MdBusinessCenter,
} from "react-icons/md";
import {
  BsPersonVcard,
  BsEnvelope,
  BsTelephone,
  BsGlobe,
  BsBriefcase,
  BsDownload,
  BsClock,
  BsPerson,
} from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import ProfileDetails from "../../components/ProfileDetails";
import Footer from "../../components/Footer";
import "../../styles/profile.css";
import { Helmet } from "react-helmet";
import { formatDistanceToNow } from "date-fns";
import { trackEvent } from "../../utils/firebase";

const Profile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [applications, setApplications] = useState([]);
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [postedJobs, setPostedJobs] = useState([]);
  const [jobApplications, setJobApplications] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [savedJobs, setSavedJobs] = useState([]);
  const [loadingSaved, setLoadingSaved] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);

  const {
    myJobs,
    loading: jobsLoading,
    getCurrentUser,
    currentUser,
    jobs,
  } = useData();
  const { user } = useAuth();

  useEffect(() => {
    trackEvent("page_view", { page_name: "profile" });
  }, []);

  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);
  const [activeTab, setActiveTab] = useState(() => {
    // Set default tab based on user role
    return currentUser?.userType === "employer" ? "posted" : "applications";
  });

  useEffect(() => {
    if (user?.uid && currentUser?.userType) {
      fetchApplications();
      if (currentUser.userType === "employer") {
        fetchPostedJobs();
      } else {
        fetchSavedJobs();
      }
    }
  }, [user?.uid, currentUser?.userType]);

  useEffect(() => {
    // Update active tab when user role changes
    if (currentUser) {
      setActiveTab(
        currentUser.userType === "employer" ? "posted" : "applications"
      );
    }
  }, [currentUser?.userType]);

  useEffect(() => {
    // Update selectedJob whenever selectedJobId changes
    if (selectedJobId && postedJobs.length > 0) {
      const job = postedJobs.find((job) => job.id === selectedJobId);
      setSelectedJob(job || null);
    } else {
      setSelectedJob(null);
    }
  }, [selectedJobId, postedJobs]);

  useEffect(() => {
    // Update filtered applications when selectedJobId changes
    if (applications.length > 0) {
      const filtered = selectedJobId
        ? applications.filter((app) => app.job?.id === selectedJobId)
        : applications;

      console.log("Filtering applications:", {
        selectedJobId,
        totalApplications: applications.length,
        filteredCount: filtered.length,
      });

      setFilteredApplications(filtered);
    } else {
      setFilteredApplications([]);
    }
  }, [selectedJobId, applications]);

  const fetchApplications = async () => {
    if (!user?.uid) return;

    setLoading(true);
    try {
      const applicationsRef = collection(db, "applications");
      let q;

      if (currentUser?.userType === "employer") {
        // For employers, first get their posted jobs
        const jobsRef = collection(db, "jobs");
        const jobsQuery = query(jobsRef, where("employerId", "==", user.uid));
        const jobsSnapshot = await getDocs(jobsQuery);

        const jobIds = jobsSnapshot.docs.map((doc) => doc.id);
        console.log("Found employer jobs:", jobIds);

        if (jobIds.length === 0) {
          setApplications([]);
          setFilteredApplications([]);
          return;
        }

        // Now get all applications for these jobs
        q = query(applicationsRef, where("jobId", "in", jobIds));
      } else {
        // For job seekers, get their own applications
        q = query(applicationsRef, where("userId", "==", user.uid));
      }

      const querySnapshot = await getDocs(q);
      console.log("Found applications:", user.uid);

      const fetchedApplications = await Promise.all(
        querySnapshot.docs.map(async (appDoc) => {
          const appData = appDoc.data();
          console.log("Processing application:", appData);

          // Get job details
          const jobDoc = await getDoc(doc(db, "jobs", appData.jobId));
          console.log("Job exists:", jobDoc.exists());

          let jobData = null;
          let applicantData = null;

          if (jobDoc.exists()) {
            jobData = {
              id: appData.jobId,
              ...jobDoc.data(),
              postedDate: jobDoc.data().postedDate?.toDate(),
            };
          }

          if (currentUser?.userType === "employer") {
            // For employers, fetch applicant details
            const applicantDoc = await getDoc(doc(db, "users", appData.userId));
            if (applicantDoc.exists()) {
              applicantData = applicantDoc.data();
              // Format experience data if it exists
              if (
                applicantData?.experience &&
                typeof applicantData.experience === "object"
              ) {
                applicantData.experienceYears =
                  applicantData.experience.years || "Not specified";
                applicantData.currentRole =
                  applicantData.experience.currentRole || "Not specified";
                applicantData.experienceDetails = Array.isArray(
                  applicantData.experience.details
                )
                  ? applicantData.experience.details
                  : [];
              }
            }
          }

          return {
            id: appDoc.id,
            ...appData,
            appliedAt: appData.appliedAt?.toDate(),
            updatedAt: appData.updatedAt?.toDate(),
            job: jobData,
            ...(currentUser?.userType === "employer" && {
              applicant: applicantData,
            }),
          };
        })
      );

      console.log("Processed applications:", fetchedApplications);
      setApplications(fetchedApplications);
      setFilteredApplications(
        selectedJobId
          ? fetchedApplications.filter((app) => app.job?.id === selectedJobId)
          : fetchedApplications
      );
    } catch (error) {
      console.error("Error fetching applications:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSavedJobs = async () => {
    if (!currentUser?.savedJobs || !jobs) return;
    console.log("Current user savedJobs:", currentUser?.savedJobs);
    setLoadingSaved(true);
    try {
      console.log("All jobs:", jobs);

      // Filter jobs that match the saved job IDs from currentUser.savedJobs
      const savedJobsList = jobs.filter((job) =>
        currentUser.savedJobs.includes(job.id)
      );

      console.log("Matched saved jobs:", savedJobsList);
      setSavedJobs(savedJobsList);
    } catch (error) {
      console.error("Error processing saved jobs:", error);
      toast.error("Failed to load saved jobs");
    } finally {
      setLoadingSaved(false);
    }
  };

  useEffect(() => {
    if (currentUser?.savedJobs && jobs) {
      fetchSavedJobs();
    }
  }, [currentUser?.savedJobs, jobs]);

  const fetchPostedJobs = async () => {
    if (user?.uid) {
      try {
        const jobsRef = collection(db, "jobs");
        const q = query(jobsRef, where("employerId", "==", user.uid));
        const querySnapshot = await getDocs(q);
        const jobs = [];
        for (const doc of querySnapshot.docs) {
          const jobData = doc.data();
          // Fetch applications count for this job
          const applicationsRef = collection(db, "applications");
          const applicationsQuery = query(
            applicationsRef,
            where("jobId", "==", doc.id)
          );
          const applicationsSnapshot = await getDocs(applicationsQuery);

          jobs.push({
            id: doc.id,
            ...jobData,
            postedDate: jobData.datePosted?.toDate
              ? jobData.datePosted.toDate()
              : new Date(jobData.datePosted),
            skills: formatSkills(jobData.skills),
            applicantsCount:
              jobData.applicationCount || applicationsSnapshot.size || 0,
          });
        }
        setPostedJobs(jobs);
      } catch (error) {
        console.error("Error fetching posted jobs:", error);
      }
    }
  };

  const formatDate = (date) => {
    if (!date) return "Recently";
    try {
      // If date is a Firestore Timestamp, convert it to Date
      const dateObj = date?.toDate ? date.toDate() : new Date(date);
      return formatDistanceToNow(dateObj, { addSuffix: true });
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Recently";
    }
  };

  const formatSalary = (baseSalary) => {
    if (!baseSalary) return "Not specified";
    const { currency, minValue, maxValue, unitText } = baseSalary;

    if (minValue && maxValue) {
      return `${currency} ${Number(minValue).toLocaleString()} - ${Number(
        maxValue
      ).toLocaleString()} per ${unitText.toLowerCase()}`;
    } else if (minValue) {
      return `${currency} ${Number(
        minValue
      ).toLocaleString()} per ${unitText.toLowerCase()}`;
    } else if (maxValue) {
      return `Up to ${currency} ${Number(
        maxValue
      ).toLocaleString()} per ${unitText.toLowerCase()}`;
    }
    return "Not specified";
  };

  const formatLocation = (requirements) => {
    if (!requirements) return "Location not specified";
    const { city, region, country } = requirements;

    if (city && region && country) {
      return `${city}, ${region}, ${country}`;
    } else if (city && region) {
      return `${city}, ${region}`;
    } else if (region && country) {
      return `${region}, ${country}`;
    } else if (city) {
      return city;
    } else if (region) {
      return region;
    } else if (country) {
      return country;
    }
    return "Location not specified";
  };

  const formatSkills = (skillsStr) => {
    if (!skillsStr) return [];
    return skillsStr
      .split("\n")
      .map((skill) => skill.replace("•", "").trim())
      .filter(Boolean)
      .map((skill) => {
        const [category, skills] = skill.split(":");
        if (skills) {
          return skills.split(",").map((s) => s.trim());
        }
        return [skill];
      })
      .flat();
  };

  const handleUnsaveJob = async (jobId) => {
    if (!currentUser?.id) return;

    try {
      // Update the user document to remove the job from savedJobs array
      const userRef = doc(db, "users", currentUser.id);
      const updatedSavedJobs = currentUser.savedJobs.filter(
        (id) => id !== jobId
      );

      await updateDoc(userRef, {
        savedJobs: updatedSavedJobs,
      });

      // Update local state
      setSavedJobs((prev) => prev.filter((job) => job.id !== jobId));
      toast.success("Job removed from saved jobs");
    } catch (error) {
      console.error("Error removing job from saved jobs:", error);
      toast.error("Failed to remove job from saved jobs");
    }
  };

  const getStatusBadgeVariant = (status) => {
    switch (status) {
      case "pending":
        return "warning";
      case "review":
        return "info";
      case "interview":
        return "primary";
      case "accepted":
        return "success";
      case "rejected":
        return "danger";
      default:
        return "secondary";
    }
  };

  const handleRoleChange = async (newRole) => {
    setIsUpdating(true);
    try {
      const userRef = doc(db, "users", currentUser.id);
      await updateDoc(userRef, {
        userType: newRole,
      });
      await getCurrentUser();
      toast.success("Role updated successfully!");
      setShowRoleModal(false);
    } catch (error) {
      console.error("Error updating role:", error);
      toast.error("Failed to update role");
    } finally {
      setIsUpdating(false);
    }
  };

  const calculateProfileCompletion = () => {
    if (!currentUser || currentUser.userType !== "job-seeker") return 0;

    const requiredFields = [
      "displayName",
      "phone",
      "location",
      "bio",
      "photoURL",
      "skills",
      "education",
      "experience",
      "resume",
    ];

    const completedFields = requiredFields.filter((field) => {
      const value = currentUser[field];
      return (
        value &&
        (Array.isArray(value)
          ? value.length > 0
          : typeof value === "string"
          ? value.trim() !== ""
          : true)
      );
    });

    return Math.round((completedFields.length / requiredFields.length) * 100);
  };

  const handleDeleteJob = async (jobId) => {
    if (!window.confirm("Are you sure you want to delete this job posting?")) {
      return;
    }

    setIsDeleting(true);
    try {
      // Delete the job document
      const jobRef = doc(db, "jobs", jobId);
      await deleteDoc(jobRef);

      // Delete all applications for this job
      const applicationsRef = collection(db, "applications");
      const q = query(applicationsRef, where("jobId", "==", jobId));
      const querySnapshot = await getDocs(q);

      const deletePromises = querySnapshot.docs.map((doc) =>
        deleteDoc(doc.ref)
      );
      await Promise.all(deletePromises);

      // Update the local state
      setPostedJobs((prev) => prev.filter((job) => job.id !== jobId));
      toast.success("Job posting deleted successfully");
    } catch (error) {
      console.error("Error deleting job:", error);
      toast.error("Failed to delete job posting");
    } finally {
      setIsDeleting(false);
    }
  };

  const handleUpdateApplicationStatus = async (applicationId, newStatus) => {
    try {
      const applicationRef = doc(db, "applications", applicationId);
      await updateDoc(applicationRef, {
        status: newStatus,
        updatedAt: new Date(),
      });

      // Update local state
      setApplications((prev) =>
        prev.map((app) =>
          app.id === applicationId ? { ...app, status: newStatus } : app
        )
      );

      toast.success(
        `Application ${
          newStatus === "accepted" ? "accepted" : "rejected"
        } successfully`
      );
    } catch (error) {
      console.error("Error updating application status:", error);
      toast.error("Failed to update application status");
    }
  };

  const handleViewApplicants = (jobId) => {
    console.log("Viewing applicants for job:", jobId);
    setSelectedJobId(jobId);
    setActiveTab("applications");
  };

  const clearJobFilter = () => {
    console.log("Clearing job filter");
    setSelectedJobId(null);
    setSelectedJob(null);
    setFilteredApplications(applications);
  };

  const RoleModal = () => {
    return (
      <Modal
        show={showRoleModal}
        onHide={() => !isUpdating && setShowRoleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Your Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="role-options">
            <button
              className={`role-option ${
                currentUser?.userType === "job-seeker" ? "active" : ""
              }`}
              onClick={() => handleRoleChange("job-seeker")}
              disabled={isUpdating}
            >
              <BsPersonVcard className="role-icon" />
              <span>Job Seeker</span>
            </button>
            <button
              className={`role-option ${
                currentUser?.userType === "employer" ? "active" : ""
              }`}
              onClick={() => handleRoleChange("employer")}
              disabled={isUpdating}
            >
              <MdBusinessCenter className="role-icon" />
              <span>Employer</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const UserAvatar = () => {
    if (currentUser?.photoURL) {
      return (
        <img
          src={currentUser.photoURL}
          alt="Profile"
          className="profile-avatar"
        />
      );
    }
    const displayName =
      currentUser?.displayName || currentUser?.email || "User";
    const initial = displayName?.charAt(0).toUpperCase();
    return <div className="profile-avatar-initial">{initial}</div>;
  };

  const JobSeekerTabs = () => (
    <Tabs
      activeKey={activeTab}
      onSelect={(k) => setActiveTab(k)}
      className="profile-tabs"
    >
      <Tab
        eventKey="applications"
        title={
          <span className="tab-title">
            <BsBriefcase className="tab-icon" />
            My Applications
          </span>
        }
      >
        <div className="tab-content-wrapper">
          {loading ? (
            <div
              className="text-center py-4 container"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="loading-spinner position-relative"></div>
            </div>
          ) : applications.length === 0 ? (
            <div className="text-center py-4">
              <div className="empty-state">
                <BsBriefcase size={48} className="text-muted mb-3" />
                <p className="text-muted">
                  You haven't applied to any jobs yet
                </p>
                <Button
                  variant="primary"
                  onClick={() => navigate("/")}
                  className="mt-2"
                >
                  Browse Jobs
                </Button>
              </div>
            </div>
          ) : (
            <div className="applications-grid">
              {filteredApplications.map((application) => (
                <Card key={application.id} className="application-card mb-3">
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-start mb-3">
                      <div>
                        <h5 className="job-title mb-1">
                          {application.job?.title}
                        </h5>
                        <p className="company-name text-muted mb-0">
                          {application.job?.hiringOrganization?.name}
                        </p>
                      </div>
                      <Badge
                        bg={
                          application.status === "accepted"
                            ? "success"
                            : application.status === "rejected"
                            ? "danger"
                            : "warning"
                        }
                      >
                        {application.status === "accepted"
                          ? "Accepted"
                          : application.status === "rejected"
                          ? "Rejected"
                          : "Pending"}
                      </Badge>
                    </div>

                    <div className="job-meta mb-3">
                      {application.job?.location && (
                        <div className="meta-item">
                          <IoLocationOutline className="icon" />
                          {application.job.location}
                        </div>
                      )}
                      {application.job?.employmentType && (
                        <div className="meta-item">
                          <BsBriefcase className="icon" />
                          {application.job.employmentType}
                        </div>
                      )}
                      {application.appliedAt && (
                        <div className="meta-item">
                          <BsClock className="icon" />
                          Applied {formatDate(application.appliedAt)}
                        </div>
                      )}
                    </div>

                    <div className="application-actions">
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => navigate(`/jobs/${application.job?.id}`)}
                      >
                        View Job
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              ))}
            </div>
          )}
        </div>
      </Tab>

      <Tab
        eventKey="saved"
        title={
          <span className="tab-title">
            <MdOutlineBookmarkBorder className="tab-icon" />
            Saved Jobs
          </span>
        }
      >
        <div className="tab-content-wrapper">
          <div className="saved-jobs-container">
            {loadingSaved ? (
              <div className="text-center py-4">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : savedJobs.length === 0 ? (
              <div className="text-center py-4">
                <div className="empty-state">
                  <MdOutlineBookmarkBorder
                    size={48}
                    className="text-muted mb-3"
                  />
                  <p className="text-muted">No saved jobs yet</p>
                  <Button
                    variant="primary"
                    onClick={() => navigate("/")}
                    className="mt-2"
                  >
                    Browse Jobs
                  </Button>
                </div>
              </div>
            ) : (
              <div className="saved-jobs-grid">
                {savedJobs.map((job) => (
                  <JobItem
                    key={job.id}
                    data={job}
                    onRemove={(jobId) => {
                      setSavedJobs((prev) =>
                        prev.filter((j) => j.id !== jobId)
                      );
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </Tab>
    </Tabs>
  );

  const EmployerTabs = () => {
    const handleTabSelect = (key) => {
      if (key === "applications") {
        setSelectedJobId(null); // Show all applicants by default
      }
      setActiveTab(key);
    };

    return (
      <Tabs
        activeKey={activeTab}
        onSelect={handleTabSelect}
        defaultActiveKey="posted"
        className="profile-tabs"
      >
        <Tab
          eventKey="posted"
          title={
            <span className="tab-title">
              <MdWorkOutline className="tab-icon" />
              Posted Jobs ({postedJobs.length})
            </span>
          }
        >
          <div className="posted-jobs-container">
            {postedJobs.map((job) => (
              <div key={job.id} className="job-card">
                <div className="job-card-header">
                  <div className="job-info">
                    <h4 className="job-title">{job.title}</h4>
                    <p className="company-name">
                      {job.hiringOrganization?.name}
                    </p>
                    <p className="job-id text-muted">Job ID: {job.id}</p>
                    <div className="job-meta">
                      <span className="location">
                        <IoLocationOutline className="icon" />
                        {formatLocation(job.applicantLocationRequirements)} (
                        {job.jobLocationType})
                      </span>
                      <span className="job-type">
                        <BsBriefcase className="icon" />
                        {job.employmentType}
                      </span>
                      <span className="salary">
                        <BsCurrencyDollar className="icon" />
                        {formatSalary(job.baseSalary)}
                      </span>
                      <span className="posted-date">
                        <BsClock className="icon" />
                        {formatDate(job.postedDate)}
                      </span>
                    </div>
                  </div>
                  <div className="job-actions">
                    <div className="applicants-count">
                      <FiUserPlus size={20} className="icon" />
                      <span className="highlight">
                        {job.applicantsCount || 0} Applicants
                      </span>
                    </div>
                    <ButtonGroup>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => navigate(`/jobs/${job.id}`)}
                      >
                        View Details
                      </Button>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => handleDeleteJob(job.id)}
                        disabled={isDeleting}
                      >
                        {isDeleting ? "Deleting..." : "Delete"}
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
                <div className="job-description">
                  <p>{job.description.slice(0, 200)}...</p>
                </div>
                <div className="job-skills">
                  {job.skills && job.skills.length > 0 ? (
                    <>
                      {job.skills.slice(0, 4).map((skill, index) => (
                        <span key={index} className="skill-tag">
                          {skill}
                        </span>
                      ))}
                      {job.skills.length > 4 && (
                        <span className="skill-tag more">
                          +{job.skills.length - 4} more
                        </span>
                      )}
                    </>
                  ) : (
                    <span className="text-muted">No skills listed</span>
                  )}
                </div>
                <div className="job-card-footer">
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => handleViewApplicants(job.id)}
                  >
                    View All Applicants
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </Tab>

        <Tab
          eventKey="applications"
          title={
            <span className="tab-title">
              <FiUserPlus className="tab-icon" />
              Job Applicants
            </span>
          }
        >
          <div className="applications-container">
            {selectedJobId && selectedJob && (
              <div className="filter-header">
                <div className="current-filter">
                  <span>Showing applicants for: </span>
                  <strong>
                    {selectedJob.title} (ID: {selectedJobId})
                  </strong>
                  <Button
                    variant="link"
                    className="clear-filter"
                    onClick={clearJobFilter}
                  >
                    <MdClose /> Clear filter
                  </Button>
                </div>
              </div>
            )}

            {loading ? (
              <div className="text-center py-4">
                <div className="loading-spinner position-relative"></div>
              </div>
            ) : filteredApplications.length === 0 ? (
              <div className="text-center py-4">
                <p className="text-muted">
                  {selectedJobId
                    ? `No applications received for this job posting (ID: ${selectedJobId})`
                    : "You haven't received any job applications yet"}
                </p>
                {!selectedJobId && (
                  <Button
                    variant="primary"
                    onClick={() => navigate("/post-job")}
                    className="mt-2"
                  >
                    Post a New Job
                  </Button>
                )}
              </div>
            ) : (
              <div>
                {!selectedJobId && (
                  <div className="mb-3 text-muted">
                    Showing all applications ({filteredApplications.length})
                  </div>
                )}
                {filteredApplications.map((application, index) => (
                  <Accordion
                    key={application.id}
                    className="application-card mb-3"
                  >
                    <Accordion.Item eventKey={index.toString()}>
                      <Accordion.Header>
                        <div className="d-flex justify-content-between align-items-center w-100 me-3">
                          <div className="applicant-header-info">
                            <div className="d-flex align-items-center">
                              {application?.applicant?.profilePicture ? (
                                <img
                                  src={application.applicant.profilePicture}
                                  alt={application.applicant.name}
                                  className="applicant-avatar me-3"
                                />
                              ) : (
                                <div className="applicant-avatar-placeholder me-3">
                                  {application?.applicant?.name?.charAt(0)}
                                </div>
                              )}
                              <div>
                                <h5 className="applicant-name mb-1">
                                  {application?.applicant?.name}
                                </h5>
                                <div className="d-flex align-items-center">
                                  <span className="job-title">
                                    Applied for:{" "}
                                    <strong>{application.job.title}</strong>
                                  </span>
                                  <span className="mx-2">•</span>
                                  <span className="text-muted small">
                                    {formatDate(application.appliedAt)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="application-status">
                            <Badge
                              bg={
                                application.status === "accepted"
                                  ? "success"
                                  : application.status === "rejected"
                                  ? "danger"
                                  : "warning"
                              }
                            >
                              {application.status === "accepted"
                                ? "Accepted"
                                : application.status === "rejected"
                                ? "Rejected"
                                : "Pending"}
                            </Badge>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="">
                          <Row>
                            <Col md={12}>
                              <div className="info-section">
                                <h6 className="section-title">
                                  Personal Information
                                </h6>
                                <div className="info-content">
                                  {application.applicant?.name && (
                                    <p>
                                      <BsPersonVcard className="icon" />
                                      <span>
                                        <strong>Full Name:</strong>{" "}
                                        {application.applicant.name}
                                      </span>
                                    </p>
                                  )}
                                  {application.applicant?.email && (
                                    <p>
                                      <BsEnvelope className="icon" />
                                      <span>
                                        <strong>Email:</strong>{" "}
                                        <a
                                          href={`mailto:${application.applicant.email}`}
                                        >
                                          {application.applicant.email}
                                        </a>
                                      </span>
                                    </p>
                                  )}
                                  {application.applicant?.phone && (
                                    <p>
                                      <BsTelephone className="icon" />
                                      <span>
                                        <strong>Phone:</strong>{" "}
                                        {application.applicant.phone}
                                      </span>
                                    </p>
                                  )}
                                  {application.applicant?.location && (
                                    <p>
                                      <IoLocationOutline className="icon" />
                                      <span>
                                        <strong>Location:</strong>{" "}
                                        {application.applicant.location}
                                      </span>
                                    </p>
                                  )}
                                  {application.applicant?.website && (
                                    <p>
                                      <BsGlobe className="icon" />
                                      <span>
                                        <strong>Website:</strong>{" "}
                                        <a
                                          href={application.applicant.website}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {application.applicant.website}
                                        </a>
                                      </span>
                                    </p>
                                  )}
                                </div>
                              </div>

                              {application.applicant?.summary && (
                                <div className="info-section">
                                  <h6 className="section-title">
                                    Professional Summary
                                  </h6>
                                  <div className="info-content">
                                    <p className="summary-text">
                                      {application.applicant.summary}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </Col>

                            <Col md={12}>
                              {(application.applicant?.experienceYears ||
                                application.applicant?.currentRole ||
                                application.applicant?.experienceDetails
                                  ?.length > 0) && (
                                <div className="info-section">
                                  <h6 className="section-title">Experience</h6>
                                  <div className="info-content">
                                    {application.applicant?.experienceYears && (
                                      <p>
                                        <BsBriefcase className="icon" />
                                        <span>
                                          <strong>Years of Experience:</strong>{" "}
                                          {
                                            application.applicant
                                              .experienceYears
                                          }
                                        </span>
                                      </p>
                                    )}
                                    {application.applicant?.currentRole && (
                                      <p>
                                        <BsPerson className="icon" />
                                        <span>
                                          <strong>Current Role:</strong>{" "}
                                          {application.applicant.currentRole}
                                        </span>
                                      </p>
                                    )}
                                    {application.applicant?.experienceDetails
                                      ?.length > 0 && (
                                      <div className="experience-details mt-2">
                                        {application.applicant.experienceDetails.map(
                                          (exp, index) => (
                                            <div
                                              key={index}
                                              className="experience-item mb-2"
                                            >
                                              <p className="mb-1">
                                                <strong>{exp.title}</strong> at{" "}
                                                {exp.company}
                                              </p>
                                              <p className="text-muted mb-1">
                                                {exp.startDate} -{" "}
                                                {exp.current
                                                  ? "Present"
                                                  : exp.endDate}
                                              </p>
                                              {exp.description && (
                                                <p className="mb-1">
                                                  {exp.description}
                                                </p>
                                              )}
                                              {exp.keyAchievements?.length >
                                                0 && (
                                                <ul className="achievements-list">
                                                  {exp.keyAchievements.map(
                                                    (achievement, idx) => (
                                                      <li key={idx}>
                                                        {achievement}
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </Col>
                          </Row>

                          <div className="info-section">
                            <h6 className="section-title">
                              Application Details
                            </h6>
                            <div className="info-content">
                              {application.appliedAt && (
                                <p>
                                  <BsClock className="icon" />
                                  <span>
                                    <strong>Applied:</strong>{" "}
                                    {formatDate(application.appliedAt)}
                                  </span>
                                </p>
                              )}
                              {application.job?.title && (
                                <p>
                                  <BsBriefcase className="icon" />
                                  <span>
                                    <strong>Position:</strong>{" "}
                                    {application.job.title}
                                  </span>
                                </p>
                              )}
                              {application.job?.location && (
                                <p>
                                  <IoLocationOutline className="icon" />
                                  <span>
                                    <strong>Job Location:</strong>{" "}
                                    {formatLocation(application.job.location)}
                                    {application.job.jobLocationType &&
                                      ` (${application.job.jobLocationType})`}
                                  </span>
                                </p>
                              )}
                              {application.job?.employmentType && (
                                <p>
                                  <BsClock className="icon" />
                                  <span>
                                    <strong>Employment Type:</strong>{" "}
                                    {application.job.employmentType}
                                  </span>
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="application-actions">
                            <div className="left-actions">
                              {application.applicant?.resume && (
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  href={application.applicant.resume}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <BsDownload className="icon me-1" /> Download
                                  Resume
                                </Button>
                              )}
                              {application.applicant?.email && (
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  onClick={() =>
                                    (window.location.href = `mailto:${application.applicant.email}`)
                                  }
                                >
                                  <BsEnvelope className="icon me-1" /> Contact
                                </Button>
                              )}
                            </div>

                            <div className="status-actions">
                              {application.status === "accepted" ? (
                                <Button variant="success" size="sm" disabled>
                                  <FiCheck className="icon me-1" /> Accepted
                                </Button>
                              ) : application.status === "rejected" ? (
                                <Button variant="danger" size="sm" disabled>
                                  <FiX className="icon me-1" /> Rejected
                                </Button>
                              ) : (
                                <ButtonGroup>
                                  <Button
                                    variant="outline-success"
                                    size="sm"
                                    onClick={() =>
                                      handleUpdateApplicationStatus(
                                        application.id,
                                        "accepted"
                                      )
                                    }
                                  >
                                    <FiCheck className="icon me-1" /> Accept
                                  </Button>
                                  <Button
                                    variant="outline-danger"
                                    size="sm"
                                    onClick={() =>
                                      handleUpdateApplicationStatus(
                                        application.id,
                                        "rejected"
                                      )
                                    }
                                  >
                                    <FiX className="icon me-1" /> Reject
                                  </Button>
                                </ButtonGroup>
                              )}
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))}
              </div>
            )}
          </div>
        </Tab>
        <Tab
          eventKey="saved"
          title={
            <span className="tab-title">
              <MdOutlineBookmarkBorder className="tab-icon" />
              Saved Jobs
            </span>
          }
        >
          <div className="tab-content-wrapper">
            <div className="saved-jobs-container">
              {loadingSaved ? (
                <div className="text-center py-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : savedJobs.length === 0 ? (
                <div className="text-center py-4">
                  <div className="empty-state">
                    <MdOutlineBookmarkBorder
                      size={48}
                      className="text-muted mb-3"
                    />
                    <p className="text-muted">No saved jobs yet</p>
                    <Button
                      variant="primary"
                      onClick={() => navigate("/")}
                      className="mt-2"
                    >
                      Browse Jobs
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="saved-jobs-grid">
                  {savedJobs.map((job) => (
                    <JobItem
                      key={job.id}
                      data={job}
                      onRemove={(jobId) => {
                        setSavedJobs((prev) =>
                          prev.filter((j) => j.id !== jobId)
                        );
                      }}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </Tab>
      </Tabs>
    );
  };

  const renderSavedJobs = () => (
    <div className="saved-jobs-grid">
      {savedJobs.map((job) => (
        <JobItem
          key={job.id}
          data={job}
          onRemove={(jobId) => {
            setSavedJobs((prev) => prev.filter((j) => j.id !== jobId));
          }}
        />
      ))}
    </div>
  );

  if (!currentUser) {
    return (
      <div
        className="text-center"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 150,
        }}
      >
        <div className="loading-spinner position-relative"></div>
      </div>
    );
  }

  const completionPercentage = calculateProfileCompletion();
  return (
    <div className="profile-page">
      <Helmet>
        <title>Profile - JobFinder</title>
      </Helmet>
      <Container>
        <br />
        <br />
        <br />
        <div className="profile-header">
          <div className="profile-header-content">
            <div className="profile-image-section">
              <UserAvatar />
              <button
                className="edit-profile-button"
                onClick={() => {
                  trackEvent("profile_edit_start");
                  navigate("/edit-profile");
                }}
              >
                <FiEdit2 size={16} />
                Edit Profile
              </button>
            </div>
            <div className="profile-info">
              <div className="name-completion-wrapper d-flex justify-content-between align-items-center">
                <h2>{currentUser?.displayName || "User"}</h2>
                {currentUser?.userType === "job-seeker" && (
                  <div className="profile-completion">
                    <span className="completion-label">Profile Completion</span>
                    <div className="progress-bar">
                      <div
                        className="progress-bar-inner"
                        style={{ width: `${completionPercentage}%` }}
                      />
                    </div>
                    <span className="completion-percentage">
                      {completionPercentage}%
                    </span>
                  </div>
                )}
              </div>
              <p className="text-start">{currentUser?.email}</p>
              <div className="profile-role">
                <div className="role-badge-wrapper">
                  <span className={`role-badge ${currentUser?.userType}`}>
                    {currentUser?.userType === "job-seeker" ? (
                      <>
                        <BsPersonVcard className="role-icon" />
                        <span>Job Seeker</span>
                      </>
                    ) : (
                      <>
                        <MdBusinessCenter className="role-icon" />
                        <span>Employer</span>
                      </>
                    )}
                  </span>
                  <button
                    className="role-edit-button"
                    onClick={() => setShowRoleModal(true)}
                    title="Change Role"
                  >
                    <FiEdit2 size={14} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {currentUser && (
          <>
            {currentUser?.userType === "job-seeker" ? (
              <JobSeekerTabs />
            ) : (
              <EmployerTabs />
            )}
            <RoleModal />
          </>
        )}
      </Container>
      <Footer />
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default Profile;

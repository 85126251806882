import React from "react";
import { Link } from "react-router-dom";
import { FaBriefcase } from "react-icons/fa";
import "./styles.css";

const Logo = ({ variant = "default" }) => {
  const logoClasses = `logo ${variant === "light" ? "logo-light" : ""}`;

  return (
    <Link to="/" className={logoClasses}>
      <div className="logo-container">
        <div className="logo-icon">
          <FaBriefcase className="briefcase-icon" />
          <div className="logo-icon-circle"></div>
        </div>
        <div className="logo-text">
          <div className="logo-text-wrapper">
            <span className="appoint">Appoint</span>
            <span className="talent">Talent</span>
          </div>
          <span className="logo-tagline">Find Your Dream Career</span>
        </div>
      </div>
    </Link>
  );
};

export default Logo;

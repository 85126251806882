import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import { AuthProvider } from './context/AuthContext';
import { DataProvider } from './context/DataContext';
import { SavedJobsProvider } from './context/SavedJobsContext';
// import './styles/custom-theme.css';  // Import custom theme
import PrivateRoute from './components/PrivateRoute';
import Navbar from './components/Navbar';
import Home from './pages/home';
import Login from './pages/login';
import Register from './pages/register';
// import ForgotPassword from './pages/forgot-password';
import Profile from './pages/profile';
import EditProfile from './pages/edit-profile';
import PostJob from './pages/post-job';
import Jobs from './pages/Jobs';
import JobDetails from './pages/job-details';
import EditJob from './pages/edit-job';
import NotFound from './pages/notFound';
import Details from './pages/details';
import Contact from './pages/contact';
import PrivacyPolicy from './pages/privacy-policy';
import TermsAndConditions from './pages/terms-and-conditions';
import Faq from './pages/faq';
import Scholorships from './pages/Scholorships';
import AboutUs from './pages/about-us';
import Search from './pages/Search';
import Applications from './pages/applications';
import Companies from './pages/companies';
import CareerAdvice from './pages/career-advice';
import SalaryGuide from './pages/salary-guide';
import InterviewTips from './pages/interview-tips';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";
import "./index.css";

function App() {
  const [progress, setProgress] = useState(0);
  const pageSize = 9;
  const apiKey = "a19b01428emshc8ebc3d1d7714c5p19523djsn73665243edaa";

  return (
    <Router>
      <AuthProvider>
        <SavedJobsProvider>
          <DataProvider>
            <LoadingBar color="#f11946" progress={progress} height={3} />
            <Navbar />
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <Home
                    setProgress={setProgress}
                    apiKey={apiKey}
                    key="general"
                    pageSize={pageSize}
                    country="in"
                    category="general"
                  />
                }
              />

              <Route
                exact
                path="/details"
                element={
                  <Details
                    setProgress={setProgress}
                    apiKey={apiKey}
                    key="general"
                    pageSize={pageSize}
                    country="in"
                    category="general"
                  />
                }
              />

              <Route
                exact
                path="/contact"
                element={
                  <Contact
                    setProgress={setProgress}
                    apiKey={apiKey}
                    key="general"
                    pageSize={pageSize}
                    country="in"
                    category="general"
                  />
                }
              />

              <Route
                exact
                path="/privacy-policy"
                element={
                  <PrivacyPolicy
                    setProgress={setProgress}
                    apiKey={apiKey}
                    key="general"
                    pageSize={pageSize}
                    country="in"
                    category="general"
                  />
                }
              />

              <Route
                exact
                path="/jobs"
                element={
                  <Jobs
                    setProgress={setProgress}
                    apiKey={apiKey}
                    key="general"
                    pageSize={pageSize}
                    country="in"
                    category="general"
                  />
                }
              />

              <Route
                exact
                path="/jobs/:id"
                element={
                  <JobDetails
                    setProgress={setProgress}
                    apiKey={apiKey}
                    key="general"
                    pageSize={pageSize}
                    country="in"
                    category="general"
                  />
                }
              />

              <Route
                exact
                path="/search"
                element={
                  <Search
                    setProgress={setProgress}
                    apiKey={apiKey}
                    key="general"
                    pageSize={pageSize}
                    country="in"
                    category="general"
                  />
                }
              />

              <Route
                exact
                path="/terms-and-conditions"
                element={
                  <TermsAndConditions
                    setProgress={setProgress}
                    apiKey={apiKey}
                    key="general"
                    pageSize={pageSize}
                    country="in"
                    category="general"
                  />
                }
              />

              <Route
                exact
                path="/faq"
                element={
                  <Faq
                    setProgress={setProgress}
                    apiKey={apiKey}
                    key="general"
                    pageSize={pageSize}
                    country="in"
                    category="general"
                  />
                }
              />

              <Route
                exact
                path="/scholorships"
                element={
                  <Scholorships
                    setProgress={setProgress}
                    apiKey={apiKey}
                    key="general"
                    pageSize={pageSize}
                    country="in"
                    category="general"
                  />
                }
              />

              <Route
                exact
                path="/about-us"
                element={
                  <AboutUs
                    setProgress={setProgress}
                    apiKey={apiKey}
                    key="general"
                    pageSize={pageSize}
                    country="in"
                    category="general"
                  />
                }
              />

              <Route
                exact
                path="/login"
                element={
                  <Login
                    setProgress={setProgress}
                    apiKey={apiKey}
                    key="general"
                    pageSize={pageSize}
                    country="in"
                    category="general"
                  />
                }
              />

              <Route
                exact
                path="/register"
                element={
                  <Register
                    setProgress={setProgress}
                    apiKey={apiKey}
                    key="general"
                    pageSize={pageSize}
                    country="in"
                    category="general"
                  />
                }
              />

              <Route
                exact
                path="/profile"
                element={
                  <PrivateRoute>
                    <Profile
                      setProgress={setProgress}
                      apiKey={apiKey}
                      key="general"
                      pageSize={pageSize}
                      country="in"
                      category="general"
                    />
                  </PrivateRoute>
                }
              />

              <Route
                exact
                path="/edit-profile"
                element={
                  <PrivateRoute>
                    <EditProfile
                      setProgress={setProgress}
                      apiKey={apiKey}
                      key="general"
                      pageSize={pageSize}
                      country="in"
                      category="general"
                    />
                  </PrivateRoute>
                }
              />

              <Route
                exact
                path="/post-job"
                element={
                  <PrivateRoute>
                    <PostJob
                      setProgress={setProgress}
                      apiKey={apiKey}
                      key="general"
                      pageSize={pageSize}
                      country="in"
                      category="general"
                    />
                  </PrivateRoute>
                }
              />

              <Route
                exact
                path="/edit-job/:id"
                element={
                  <PrivateRoute>
                    <EditJob
                      setProgress={setProgress}
                      apiKey={apiKey}
                      key="general"
                      pageSize={pageSize}
                      country="in"
                      category="general"
                    />
                  </PrivateRoute>
                }
              />

              <Route
                exact
                path="/applications"
                element={<Applications />}
              />

              <Route path="/companies" element={<Companies />} />
              <Route path="/career-advice" element={<CareerAdvice />} />
              <Route path="/salary-guide" element={<SalaryGuide />} />
              <Route path="/interview-tips" element={<InterviewTips />} />

              <Route path="*" element={<NotFound />} />
            </Routes>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </DataProvider>
        </SavedJobsProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
